import * as React from "react";
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from "@mui/x-charts/Gauge";

// Define constant data for the gauge
const GAUGE_DATA = {
  value: 121, // Example value
  maxValue: 200, // Maximum value
  color: "green", // Example color
};

// Function to render the gauge pointer
function GaugePointer({ value }) {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };

  // Change circle color if value exceeds 80% of maxValue
  const percentage = (value / GAUGE_DATA.maxValue) * 100;
  const circleColor = percentage > 80 ? "#E50000" : "#6F9EF4";

  return (
    <g>
      <circle cx={cx} cy={cy} r={70} fill={circleColor} opacity={0.25} />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke={circleColor}
        opacity={0.5}
        strokeWidth={5}
      />
      {/* Add text in the center */}
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        alignmentBaseline="central"
        fontSize="14"
        fontFamily="mySecondFont"
        fill={circleColor}
        dy="-1.2em" // Adjust vertical alignment
      >
        Present supply
      </text>
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        alignmentBaseline="central"
        fontSize="18"
        fontFamily="myFirstFont"
        fill={circleColor}
        dy=".3em" // Adjust vertical alignment
      >
        {value}kWh
      </text>
    </g>
  );
}

// Component that uses the gauge data
function GaugeMeter({ value, color, maxValue }) {
  // Calculate percentage value
  const percentage = (value / maxValue) * 100;

  return (
    <GaugeContainer
      //   width={240}
      height={240}
      startAngle={-145}
      endAngle={145}
      value={percentage}
    >
      <GaugeReferenceArc />
      <GaugeValueArc color="#000" />
      <GaugePointer value={value} />
    </GaugeContainer>
  );
}

// Main component to render the gauge
export default function CompositionExample() {
  return (
    <GaugeMeter
      value={GAUGE_DATA.value}
      color={GAUGE_DATA.color}
      maxValue={GAUGE_DATA.maxValue}
    />
  );
}
