import { Box, Typography } from "@mui/material";
import React from "react";
import { formatTimestamp } from "../../../utils/FormatDateTime";

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        // justifyContent: icon ? "space-between" : "start",
        alignItems: "center",
        gap: icon ? "8px" : "",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {value}
      </Typography>
      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

const SessionDetailBox3 = ({ data }) => {
  const startTime = data?.sessionStart
    ? formatTimestamp(data.sessionStart)
    : { formattedTime: "-", formattedDate: "-" };
  const endTime = data?.sessionStop
    ? formatTimestamp(data.sessionStop)
    : { formattedTime: "-", formattedDate: "-" };

  return (
    <Box
      sx={{
        padding: "24px 16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox label="Entered Amount (₹)" value={(data?.enteredAmount)} />
          </Box>

          <Box sx={{ width: "50%" }}>
            <InfoBox label="Billed Amount (₹)" value={(data?.chargedAmount)} />
          </Box>
        </Box>
        <InfoBox label="Refund (₹)" value={(data?.refundAmount)?.toFixed(2)} />
        <InfoBox label="Charge Duration " value={data?.sessionDuration} />
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InfoBox label="Start time" value={`${startTime.formattedTime} `} />
            <Typography
              textAlign={"start"}
              color={"#666"}
              fontFamily={"myThirdFont"}
            >
              {startTime.formattedDate}
            </Typography>
          </Box>

          <Box sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
            <InfoBox label="End time" value={`${endTime.formattedTime}`} />
            <Typography
              textAlign={"start"}
              color={"#666"}
              fontFamily={"myThirdFont"}
            >
              {endTime.formattedDate}
            </Typography>
          </Box>
        </Box>
        <InfoBox
          label="Status"
          value={data?.isActive === false ? "Completed" : "Active"}
        />
      </Box>
    </Box>
  );
};

export default SessionDetailBox3;
