import { Box, Button, Grid } from "@mui/material";
import React from "react";

const LeftColumnBox = ({ onRegister }) => {
  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
      <h2>Register Box</h2>
      <Button onClick={onRegister}>Register</Button>
        {/* <Grid item md={12} xs={12}>
          <Box>
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "center",
                marginBottom: "24px",
              }}
            >
              <Select
                value={selectedValue}
                onChange={handleChange}
                displayEmpty
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "left",
                  //   padding: "8px 16px",
                  width: "232px",
                  height: "40px",
                  color: "#1C1C1C",
                  fontFamily: "myThirdFont",
                }}
              >
                <MenuItem value="" disabled>
                  Organization
                </MenuItem>
                <MenuItem value="option1">Option 1</MenuItem>
                <MenuItem value="option2">Option 2</MenuItem>
                <MenuItem value="option3">Option 3</MenuItem>
              </Select>
            </Box>
          </Box>
        </Grid> */}

        <Grid item md={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            {/* Row 1 */}
            <Box>Formffffffffffffffffffffffffffffffffffffffffffffffff</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeftColumnBox;
