import { Box, Grid } from "@mui/material";
import React from "react";
import RegisterInfoBox from "./Overview/RegisterInfoBox";

const OverviewBox = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <RegisterInfoBox />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewBox;
