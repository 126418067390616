import { Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  let breadcrumbPath = "";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",

      }}
    >
      {pathnames.map((name, index) => {
        breadcrumbPath += `/${name}`;
        const isLast = index === pathnames.length - 1;

        return (
          <Box
            key={breadcrumbPath}
            sx={{
              display: "flex",
              alignItems: "center",
              // Adds spacing between breadcrumbs if needed
              "&:not(:last-of-type)": {
                marginRight: "4px",
              },
            }}
          >
            <Typography
              sx={{
                color: "#292D33",
                fontFamily: "myThirdFont",
                fontSize: "14px",
                lineHeight: "20px", // 142.857%
                textDecoration: isLast ? "none" : "underline",
                textTransform: "uppercase",
                display: "inline",
              }}
            >
              {isLast ? (
                `> ${name}`
              ) : (
                <Link
                  to={breadcrumbPath}
                  style={{
                    color: "#292D33",
                    textDecoration: "none",
                  }}
                >
                  {name}
                </Link>
              )}
            </Typography>
            {/* Add separator if not the last breadcrumb */}
            {!isLast && (
              <Typography
                sx={{
                  color: "#292D33",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "20px",
                  display: "inline",
                  marginX: "4px",
                }}
              ></Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default Breadcrumbs;
