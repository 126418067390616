import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const OverviewBottomBox = () => {
  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={12} xs={12}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "center",
                marginBottom: "24px",
              }}
            >
              Overview Bottom Box
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewBottomBox;
