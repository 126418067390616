import { Box, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { resendOTP, verifyOTP } from "../../../redux/action/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(""); // State for OTP input
  const [timer, setTimer] = useState(30);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  // Dummy function to simulate OTP verification
  const handleVerifyOtp = () => {
    if (otp.length === 4 && state?.phone) {
      const otpNumber = parseInt(otp, 10); // Convert OTP to number
      if (isNaN(otpNumber)) {
        console.error("Invalid OTP format");
        return;
      }

      // Call verifyOTP API
      dispatch(verifyOTP({ phoneNumber: state.phone, otp: otpNumber }))
        .unwrap()
        .then(() => {
          navigate("/create_new_password");
        })
        .catch((err) => {
          console.error(
            "Failed to verify OTP:",
            err.message || "Verification failed"
          );
          toast.error("Invalid OTP. Please try again."); // Show error message
        });
    } else {
      console.error(
        "Please enter a valid OTP and ensure phone number is available"
      );
      toast.error("Please enter a valid OTP."); // Show error message
    }
  };

  // Function to restart timer and resend OTP
  const restartTimer = () => {
    setTimer(30); // Reset the timer
    dispatch(resendOTP(state?.phone))
      .unwrap()
      .then(() => {
        toast.success("OTP resent to your phone!");
      })
      .catch((err) => {
        console.error(
          "Failed to resend OTP:",
          err.message || "Error resending OTP"
        );
        toast.error("Failed to resend OTP. Please try again."); // Show error message
      });
  };

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timer]);

  const handleBack = () => {
    navigate("/reset_password");
  };

  const phoneNumber = state?.phone; // Extract the phone number from the state
  const lastFourDigits = phoneNumber ? phoneNumber.slice(-4) : "xxxx";

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url("/images/auth-bg-final.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "#FFFFFF",
            padding: "32px",
            borderRadius: "8px",
            width: "480px",
            height: "586px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box sx={{ width: "100%" }}>
              <img src="/images/1C-login-logo.png" alt="logo" />
            </Box>

            <Box>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "16px",
                  fontStyle: "normal",
                  lineHeight: "24px",
                  letterSpacing: "1.28px",
                  textTransform: "uppercase",
                  mb: "16px",
                }}
              >
                Two step verification
              </Typography>

              <Typography
                sx={{
                  color: "#0C0C0C",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "32px",
                  mb: "12px",
                }}
              >
                Verify Mobile Number
              </Typography>

              <Typography
                sx={{
                  color: "#1C1C1C",
                  fontFamily: "myThirdFont",
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  mb: "4px",
                }}
              >
                Enter OTP sent to {`xxxxxx${lastFourDigits}`}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span>&nbsp;&nbsp;&nbsp; </span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    width: "64px",
                    height: "64px",
                    color: "#7A7A7A",
                    borderRadius: "8px",
                    border: "1px solid #2F2F2F",
                    marginTop: "8px",
                    fontSize: "clamp(1rem, 0.9741rem + 0.1258vw, 1.125rem)",
                  }}
                />
              </Box>

              <Typography
                fontSize={"14px"}
                color={"#2F2F2F"}
                fontFamily={"myThirdFont"}
                mt={"16px"}
                textAlign={"center"}
                lineHeight={"24px"}
              >
                Didn’t receive OTP?{" "}
                {timer === 0 ? (
                  <span
                    onClick={restartTimer}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#e50000",
                      fontFamily: "mySecondFont",
                    }}
                  >
                    Resend OTP
                  </span>
                ) : (
                  <span
                    style={{
                      color: "#2F2F2F",
                      fontFamily: "mySecondFont",
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                  >
                    Resend OTP in 00:{timer}s
                  </span>
                )}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <Box>
                <Button
                  onClick={handleVerifyOtp}
                  sx={{
                    width: "100%",
                    background: "#000000",
                    padding: "12px 24px",
                    color: "#FCFCFC",
                    fontFamily: "mySecondFont",
                    justifyContent: "center",
                    alignItems: "center",
                    letterSpacing: "1.28px",
                    "&:hover": {
                      background: "#2F2F2F",
                    },
                  }}
                >
                  Verify
                </Button>
              </Box>

              <Box>
                <Button
                  onClick={handleBack}
                  sx={{
                    width: "100%",
                    background: "#FFF",
                    padding: "12px 24px",
                    color: "#222222",
                    fontFamily: "mySecondFont",
                    justifyContent: "center",
                    alignItems: "center",
                    letterSpacing: "1.28px",
                    borderRadius: "8px",
                    border: "1px solid #CBCBCB",
                    "&:hover": {
                      background: "#EEEEEE",
                    },
                  }}
                >
                  BACK
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VerifyEmailPage;
