import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTransition from "../custom/CustomTransition";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch } from "react-redux";
import {
  startChargingPoint,
  stopChargingConnector,
} from "../../redux/action/chargingPointSlice/chargingPointSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { tr } from "date-fns/locale";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "16px 12px 32px 32px",
};

const startReason = [
  "Charger onboarding",
  "Hardware Testing",
  "Software Testing",
  "Charger Not Charging",
  "Emergency Testing ",
];

const StartChargingModal = ({
  open,
  handleClose,
  data,
  onChargingStarted,
  onChargingStoped,
  isCharging,
}) => {
  console.log(data);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  console.log(profile);
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Update startChargingValue when selectedValue changes
  const [startChargingValue, setStartChargingValue] = useState({
    deviceId: `${data?.connectorId}${data?.deviceId}`,
    from: "WEB_CHARGING",
    userId: profile?._id,
    startReason: selectedValue,
  });

  useEffect(() => {
    setStartChargingValue((prev) => ({
      ...prev,
      startReason: selectedValue,
    }));
  }, [selectedValue]);

  const handleChargingAction = async () => {
    try {
      if (isCharging) {
        // Stop charging
        const stopChargingData = {
          deviceId: data?.deviceId,
          userId: profile?._id,
          stopReason: selectedValue,
          requestFrom: "CMS",
        };

        const response = await dispatch(
          stopChargingConnector(stopChargingData)
        ).unwrap();
        if (response.sucess) {
          toast.success(response.message || "Charging stopped successfully.");
          onChargingStoped(); // Update state to reflect that charging has stopped
          handleClose(); // Close the modal
        } else {
          toast.error(response.message || "Failed to stop charging.");
        }
      } else {
        // Start charging
        const response = await dispatch(
          startChargingPoint(startChargingValue)
        ).unwrap();
        if (response.success) {
          toast.success(response.message || "Charging started successfully.");
          onChargingStarted(); // Update state to reflect that charging has started
          handleClose(); // Close the modal
        } else {
          toast.error(response.message || "Failed to start charging.");
        }
      }
    } catch (error) {
      toast.error(
        `Failed to ${isCharging ? "stop" : "start"} charging. Please try again.`
      );
      console.error("Charging error:", error);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        TransitionComponent={CustomTransition}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              className="material-symbols-outlined"
            >
              cancel
            </span>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ maxWidth: "77px", width: "100%" }}
              src="/images/chargerp.png"
              alt=""
            />
          </Box>
          <Box sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <MainHeading
                text={isCharging ? "Stop Charging" : "Start Charging"}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  alignItems: "start",
                }}
              >
                <Typography
                  color={"#2f2f2f"}
                  fontSize={"14px"}
                  fontFamily={"myThirdFont"}
                  textAlign={"left"}
                >
                  Reason to {isCharging ? "Stop" : "start"} charging
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "center",
                    marginBottom: "24px",
                  }}
                >
                  <Select
                    value={selectedValue}
                    onChange={handleChange}
                    displayEmpty
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "left",
                      //   padding: "8px 16px",
                      width: "352px",
                      height: "48px",
                      color: "#1C1C1C",
                      fontFamily: "myThirdFont",
                    }}
                  >
                    <MenuItem value="" disabled>
                      All Networks
                    </MenuItem>
                    {startReason?.map((item) => {
                      return (
                        <MenuItem
                          sx={{ fontFamily: "mySecondFont" }}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: "16px", display: "flex", gap: "8px", width: "100%" }}>
            <Button
              sx={{
                borderRadius: "4px",
                border: "1px solid #292D33",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40%",
                color: "#292D33",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleChargingAction}
              sx={{
                borderRadius: "4px",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#E50000",
                width: "60%",
                color: "#fff",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
                ":hover": {
                  background: "#E50000",
                },
              }}
            >
              {isCharging ? "Stop Charging" : "Start Charging"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default StartChargingModal;
