import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "./TableFilters";
import CustomTable from "../custom/CustomTable";

const TableBox = ({
  columns,
  rows = [],
  isRowClickable,
  loading,
  totalCount,
  pageLimit,
}) => {
  console.log(pageLimit);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  useEffect(() => {
    if (rows.length > 0) {
      setFilteredSessions(rows);
    }
  }, [rows]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredSessions(filteredData);
  };

  const myFilters = [
    {
      value: "deviceId",
      title: "Device ID",
    },
    {
      value: "station",
      title: "Session ID",
    },
  ];

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <TableFilters
        data={rows}
        onFilterChange={handleFilterChange}
        filters={myFilters}
      />
      <CustomTable
        columns={columns}
        rows={filteredSessions}
        isRowClickable={isRowClickable}
        clickableColumns={["_id"]}
        columnClickPaths={{
          _id: "/charging_sessions",
        }}
        columnKeyMappings={{
          _id: "_id", // Use 'sessionID' key for 'sessionID' column
        }}
        modalHeading={"Charging Session"}
        loading={loading}
        sortColumn={sortColumn}
        totalCount={totalCount}
        limit={pageLimit}
      />
    </Box>
  );
};

export default TableBox;
