import { Box, Grid } from "@mui/material";
import React from "react";
import TopGraphBox from "../dashboard/TopGraphBox";
import LoadBalance from "./Overview/LoadBalance";
import DirectSession from "./Overview/DirectSession";
import DeviceStatus from "./Overview/DeviceStatus";
import RatingBox from "./Overview/RatingBox";
import ValueCardBox from "./Overview/ValueCardBox";
import { useSelector } from "react-redux";

const OverviewBox = ({
  data,
  selectedTime,
  startTime,
  endTime,
  liveStationData,
  previousDashboardData,
}) => {

  const user = useSelector((state) => state?.profile?.profile);


  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}

      { user?.role === "CPO_Admin" ?       <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <TopGraphBox
              //Current data
              data={data}
              //Previous data
              previousDashboardData={previousDashboardData}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
          </Box>
        </Grid>

      </Grid> :       <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <TopGraphBox
              //Current data
              data={data}
              //Previous data
              previousDashboardData={previousDashboardData}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
          </Box>
        </Grid>
        <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <LoadBalance />
          </Box>
        </Grid>
      </Grid>}


      {/* mid - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <DirectSession
              // data={data}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
              //Current data
              data={data}
              //Previous data
              previousDashboardData={previousDashboardData}
            />
          </Box>
        </Grid>
        <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <DeviceStatus data={liveStationData} />
          </Box>
        </Grid>
      </Grid>

      {/* below - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <ValueCardBox //Current data
              data={data}
              //Previous data
              previousDashboardData={previousDashboardData}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: "flex", mt: "16px" }}>
            <RatingBox data={data} />
          </Box>
        </Grid>
        <Grid item md={4.5} sx={{ display: "flex", flexDirection: "column" }}>
          {/* <TopPerformingSessionEnergy
            stationStats={stationStats?.stationsReport || []}
          /> */}
        </Grid>
        <Grid item md={4.5} sx={{ display: "flex", flexDirection: "column" }}>
          {/* <TopPerformingSessionEnergy
            stationStats={stationStats?.stationsReport || []}
          /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewBox;
