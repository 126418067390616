import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
// import TableBox from "./TableBox";
import OverviewBox from "./OverviewBox";
import TimeFilter from "../Filters/TimeFilter";
import DetailsBox from "./DetailsBox";
import SessionsBox from "./SessionsBox";
import {
  chargingPointSessionColumns,
  chargingPointSessionData,
} from "../../utils/DummyData";
import StatusChip from "../Chips/StatusChip";
import ConnectorsBox from "./ConnectorsBox";
import LogsBox from "./LogsBox";
import ConfigurationBox from "./ConfigurationBox";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteChargingPoint,
  fetchChargingPointConnectors,
  fetchChargingPointQr,
  fetchChargingPointStatistics,
  fetchChargingPointStatus,
  fetchOneChargingPoint,
  fetchOneChargingPointLogs,
  fetchOneSessionData,
} from "../../redux/action/chargingPointSlice/chargingPointSlice";
import { fetchDashboardData } from "../../redux/action/dashboard/dashboardSlice";
import { toast } from "react-toastify";
import DeleteModal from "../Modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { toPng } from "html-to-image";
import Breadcrumbs from "../breadcrumbs/BreadCrumbs";
import useScrollToTop from "../../Hooks/useScrollToTop";

// for tabs icons
const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [chargingPointId, setChargingPointId] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null);
  const elementRef = useRef(null);
  const [qrLoading, setQrLoading] = useState(false);
  const navigate = useNavigate();
  useScrollToTop(navigate);

  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  console.log("zzzzzzchargingPoint", prevStartTime, prevEndTime);

  const {
    chargingPointDetail,
    connectors,
    sessionDetail,
    chargingPointStatus,
    logs,
    qrCode,
    loading,
    error,
  } = useSelector((state) => state.chargingPoints);

  const { chargingPointStatsData } = useSelector(
    (state) => state.chargingPoints
  );

  const [currentChargingPointStatsData, setCurrentChargingPointStatsData] =
    useState(null);

  // Redux hooks
  const dispatch = useDispatch();

  useEffect(() => {
    // Extract organization ID from URL
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setChargingPointId(id);
    }
  }, []);

  useEffect(() => {
    if (chargingPointId) {
      dispatch(fetchOneChargingPoint(chargingPointId));
      dispatch(fetchChargingPointConnectors(chargingPointId));
      dispatch(fetchOneSessionData(chargingPointId));
      dispatch(fetchChargingPointStatus(chargingPointId));
      // Set up polling
      const intervalId = setInterval(() => {
        dispatch(fetchChargingPointStatus(chargingPointId));
      }, 30000); // 30 seconds

      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [dispatch, chargingPointId]);

  // useEffect(() => {
  //   if (chargingPointId && durationType && startTime && endTime) {
  //     console.log("Calling API ookkk", chargingPointId);
  //     dispatch(
  //       fetchChargingPointStatistics({
  //         chargingPointId,
  //         durationType,
  //         startTime,
  //         endTime,
  //       })
  //     );
  //   }
  // }, [dispatch, chargingPointId, durationType, startTime, endTime]);

  //ssssssssss
  // Fetch data for current time
  useEffect(() => {
    const fetchData = async () => {
      if (chargingPointId && durationType && startTime && endTime) {
        try {
          // Fetch overview data
          const overviewCpDummyData = await dispatch(
            fetchChargingPointStatistics({
              chargingPointId,
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();

          console.log("Current Time Overview Data:", overviewCpDummyData);
          setCurrentChargingPointStatsData(overviewCpDummyData);
        } catch (error) {
          console.error("Error fetching current time data: ", error);
        }
      }
    };

    fetchData();
  }, [chargingPointId, durationType, startTime, endTime, dispatch]);

  useEffect(() => {
    if (
      chargingPointId &&
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentChargingPointStatsData !== null
    ) {
      console.log("Previous Time API Call123:", {
        chargingPointId,
        durationType,
        startTime: prevStartTime,
        endTime: prevEndTime,
      });

      dispatch(
        fetchChargingPointStatistics({
          chargingPointId,
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [
    chargingPointId,
    durationType,
    prevStartTime,
    prevEndTime,
    dispatch,
    currentChargingPointStatsData,
  ]);

  //sssssssssss

  console.log("check", chargingPointStatus);

  const handleDelete = async () => {
    if (chargingPointId) {
      try {
        await dispatch(deleteChargingPoint(chargingPointId)).unwrap(); // Ensure this returns a promise
        toast.success("Charging Point deleted successfully!"); // Show success toast
        navigate("/charging_points"); // Navigate back to the previous page
      } catch (error) {
        toast.error("Failed to delete the Charging Point."); // Show error toast
      } finally {
        setOpenDeleteModal(false); // Close the modal
      }
    }
  };

  const handleOpenDeleteModal = (e) => {
    e?.stopPropagation();
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = (e) => {
    e?.stopPropagation();
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (qrCode) {
      setQrCodeData(qrCode);
    }
  }, [qrCode]);

  const GetQrCodeAndDownload = () => {
    setQrLoading(true);
    let chargingPointId = chargingPointDetail?.device_id;
    if (chargingPointId) {
      console.log(chargingPointId);
      dispatch(fetchChargingPointQr(chargingPointId)).then((result) => {
        if (fetchChargingPointQr.fulfilled.match(result)) {
          const qrCodes = result.payload.allQrCodes;
          setQrCodeData(qrCodes);
          // if (qrCodes.length === 1) {
          //   downloadQRCode(qrCodes[0]);
          // } else if (qrCodes.length > 1) {
          //   downloadZipWithQRCodes(qrCodes);
          // } else {
          //   toast.error("QR code not available.");
          // }
        } else {
          console.error("Failed to fetch QR code:", result.payload);
        }
      });
    }
  };

  // const downloadQRCode = (base64String, fileName = "qrcode.png") => {
  //   const link = document.createElement("a");
  //   link.href = base64String;
  //   link.download = fileName;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  // const downloadZipWithQRCodes = (qrCodes) => {
  //   const zip = new JSZip();

  //   qrCodes.forEach((qrCode, index) => {
  //     zip.file(`qrcode${index + 1}.png`, qrCode.split(",")[1], {
  //       base64: true,
  //     });
  //   });

  //   zip.generateAsync({ type: "blob" }).then((content) => {
  //     saveAs(content, "qrcodes.zip");
  //   });
  // };
  console.log(
    "oooorrrrr",
    chargingPointStatsData,
    currentChargingPointStatsData
  );
  const tabsData = [
    {
      label: "Overview",
      content: (
        <Box>
          <OverviewBox
            // data={chargingPointStatsData}

            //Current Time Data
            data={currentChargingPointStatsData}
            //Previous Time Data
            previousDashboardData={chargingPointStatsData}
            selectedTime={durationType}
            startTime={startTime}
            endTime={endTime}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      label: "Details",
      content: (
        <Box>
          <DetailsBox data={chargingPointDetail} />
        </Box>
      ),
      icon: <CustomIcon icon={"info"} />,
    },
    {
      label: "Connectors",
      content: (
        <Box>
          <ConnectorsBox data={connectors} chargingPointId={chargingPointId} />
        </Box>
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      label: "Logs",
      content: (
        <Box>
          <LogsBox getOneData={chargingPointDetail} />
        </Box>
      ),
      icon: <CustomIcon icon={"data_object"} />,
    },
    {
      label: "Sessions",
      content: (
        <Box>
          <SessionsBox
            columns={chargingPointSessionColumns}
            rows={sessionDetail}
            isRowClickable={true}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"battery_charging_50"} />,
    },
    {
      label: "Configuration",
      content: (
        <Box>
          <ConfigurationBox getOneData={chargingPointDetail} />
        </Box>
      ),
      icon: <CustomIcon icon={"settings"} />,
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleEditBtn = () => {
    navigate("/add_new_charging_point", {
      state: { CPData: chargingPointDetail?._id, edit: true },
    });
  };

  useEffect(() => {
    if (elementRef.current) {
      setTimeout(() => {
        htmlToImageConvert(qrCodeData);
      }, 2000);
    }
  }, [qrCodeData]);

  const htmlToImageConvert = async (qrCodes) => {
    const zip = new JSZip();

    console.log("okkkkkk", elementRef.current);

    for (const qrCode of qrCodes) {
      // Update the content of the elementRef with the current QR code data
      if (elementRef.current) {
        // elementRef.current.querySelector(".qrCodeImg1").src =
        //   "/images/qrCode.png";
        // elementRef.current.querySelector(".qrCodeImg2").src = qrCode?.qrCodeImg;
        // elementRef.current.querySelector(".deviceName").textContent =
        //   qrCode?.deviceId;

        elementRef.current.querySelector(".qrCodeImg").src = qrCode?.qrCodeImg;
        elementRef.current.querySelector(".qrCodeTitle").textContent =
          qrCode?.deviceId;

        // Generate the image
        elementRef.current.style.display = "block"; // Temporarily make the element visible
        try {
          const dataUrl = await toPng(elementRef.current, { cacheBust: false });
          // elementRef.current.style.display = "none"; // Hide the element again

          // Add the image to the ZIP file with the correct name
          const imgData = dataUrl.split(",")[1]; // Remove data:image/png;base64,
          const fileName = `${qrCode?.deviceId}.png`; // Correct file name based on deviceId
          zip.file(fileName, imgData, { base64: true });
        } catch (err) {
          elementRef.current.style.display = "none"; // Hide the element if there's an error
          console.log(err);
        }
      }
    }
    setQrLoading(false);

    // Generate the ZIP file and save it
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${chargingPointDetail?.name || "qrCodes"}.zip`);
    });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Breadcrumbs />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {/* <Box
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "32px",
                backgroundImage: "url('/images/stationImg.png')",
                backgroundSize: "cover",
              }}
            ></Box> */}
            <MainHeading text={chargingPointDetail?.name} />
            <StatusChip
              text={
                chargingPointStatus === "Available"
                  ? "Online"
                  : chargingPointStatus
              }
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              onClick={GetQrCodeAndDownload}
              sx={{
                padding: "8px 16px",
                borderRadius: "4px",
                border: "1px solid #c0c0c0",
                width: "100%",
                color: "#222222",
                fontFamily: "myThirdFont",
                textWrap: "nowrap",
                fontSize: "14px",
              }}
            >
              <span
                style={{
                  paddingRight: "8px",
                  fontSize: "24px",
                  fontWeight: "200",
                }}
                className="material-symbols-outlined"
              >
                qr_code_scanner
              </span>
              {qrLoading && qrCodeData?.length > 0
                ? "Downloading..."
                : "Download QR Code"}
            </Button>
            <Button
              onClick={handleEditBtn}
              sx={{
                padding: "8px 16px",
                borderRadius: "4px",
                border: "1px solid #E50000",
                maxWidth: "175px",
                width: "100%",
                color: "#E50000",
                fontWeight: "600",
                letterSpacing: "1.12px",
              }}
            >
              <span
                style={{
                  paddingRight: "8px",
                  fontSize: "24px",
                  fontWeight: "200",
                }}
                className="material-symbols-outlined"
              >
                edit
              </span>
              EDIT DETAILS
            </Button>
            <Button
              onClick={(e) => handleOpenDeleteModal(e)}
              sx={{
                padding: "8px",
                border: "1px solid #c0c0c0",
                borderRadius: "4px",
                minWidth: "unset",
              }}
            >
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "200",
                  color: "#790000",
                }}
                className="material-symbols-outlined"
              >
                delete
              </span>
            </Button>
          </Box>
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <TimeFilter /> */}
            <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
      <DeleteModal
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        heading={"Charging Points"}
        data={chargingPointDetail}
        handleDelete={handleDelete}
      />

      {qrCodeData?.length > 0 && qrLoading === true
        ? qrCodeData?.map((qrCode) => (
            <>
              <Box sx={{ width: "740px", display: "none" }} ref={elementRef}>
                <Box
                  sx={{
                    width: "100%",
                    minWidth: "740px",
                    backgroundColor: "#E50000",
                    padding: "80px",
                  }}
                  download="w3logo"
                >
                  <Box
                    sx={{
                      backgroundColor: "#FFF",
                      borderRadius: "15px",
                      padding: "48px 76px",
                    }}
                  >
                    <img
                      src="/images/qrCodeImgs/logo.svg"
                      alt="Img not found"
                    />
                    <hr />
                    <Typography
                      sx={{
                        color: "#1C1C1C",
                        textAlign: "center",
                        fontFamily: "myFirstFont",
                        fontSize: "32px",
                        fontStyle: "normal",
                        paddingTop: "32px",
                        paddingBottom: "16px",
                        lineHeight: "40px",
                      }}
                    >
                      Scan and Pay using any app
                    </Typography>
                    {/* <Box
                    sx={{
                      padding: "14px 23px",
                      width: "100%",
                      height: "274px",
                      backgroundColor: "#D9D9D9",
                    }}
                  ></Box> */}

                    <Box
                      sx={{
                        width: "100%",
                        // height: "274px",
                        // backgroundColor: "#D9D9D9",
                      }}
                    >
                      <img
                        className="qrCodeImg"
                        src={qrCode.qrCodeImg}
                        width="100%"
                        alt=""
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#1C1C1C",
                          textAlign: "center",
                          fontFamily: "myFirstFont",
                          fontSize: "36px",
                          fontStyle: "normal",
                          lineHeight: "48px",
                        }}
                        className="qrCodeTitle"
                      >
                        {qrCode.deviceId}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1C1C1C",
                          textAlign: "center",
                          fontFamily: "myThirdFont",
                          fontSize: "32px",
                          fontStyle: "normal",
                          lineHeight: "40px",
                        }}
                      >
                        Scan and Pay using any app
                      </Typography>
                    </Box>
                    <hr />
                    <Box sx={{ width: "100%", paddingTop: "24px" }}>
                      <img
                        src="/images/qrCodeImgs/paymentLogos.svg"
                        width="100%"
                        alt=""
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ))
        : null}
    </React.Fragment>
  );
};

export default TopBar;
