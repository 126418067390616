import { Box, Typography } from "@mui/material";

export const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);

  // Format date as "dd/MM/yyyy"
  const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-GB", dateOptions).format(
    date
  );

  // Format time as "HH:mm a"
  const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
  const formattedTime = new Intl.DateTimeFormat("en-GB", timeOptions).format(
    date
  );

  return { date: formattedDate, time: formattedTime };
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours % 12}:${minutes < 10 ? "0" : ""}${minutes} ${
    hours >= 12 ? "PM" : "AM"
  }`;
  const formattedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  return { formattedTime, formattedDate };
};

export const FormatTimestamp = ({ timestamp }) => {
  if (timestamp === 0) {
    return (
      <Typography
        color="#333"
        fontSize="16px"
        fontFamily="mySecondFont"
        textAlign={"center"}
      >
        -
      </Typography>
    );
  }
  const date = new Date(timestamp);

  // Define options for date and time formatting
  const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
  const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };

  // Format the date and time
  const formattedDate = new Intl.DateTimeFormat("en-GB", dateOptions).format(
    date
  );
  const formattedTime = new Intl.DateTimeFormat("en-GB", timeOptions).format(
    date
  );

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        color={"#2F2F2F"}
        fontSize={"14px"}
        fontFamily={"mySecondFont"}
        textAlign={"start"}
      >
        {formattedDate}
      </Typography>
      <Typography
        color={"#666"}
        fontSize={"14px"}
        fontFamily={"myThirdFont"}
        textAlign={"start"}
      >
        {formattedTime}
      </Typography>
    </Box>
  );
};

// utils/formatTime.js
export const formatMillisecondsToHHMMSS = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
  const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
  const secs = String(seconds % 60).padStart(2, "0");
  return `${hours}:${minutes}:${secs}`;
};
