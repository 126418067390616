import React from "react";
import DoughnutChart from "../Graphs/DoughnutChart";
import { Box, Divider } from "@mui/material";
import Heading from "../../utils/Heading";
import {
  appStatsData,
  valueCardData,
  walletRechargeData,
} from "../../utils/DummyData";
import ValueCardSmall from "./ValueCardSmall";
import StatusChip from "../Chips/StatusChip";

const AppStats = ({
  customers,
  data,
  walletData,
  previousWalletData,
  previousDashboardData,
  selectedTime,
}) => {
  console.log("wallet444444", walletData);
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span class="material-symbols-outlined">ad_units</span>
            <Heading text={"App Stats"} />
          </Box>
          <Box>
            <StatusChip text={"Live"} chipStatus={"Live"} />
          </Box>
        </Box>

        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <ValueCardSmall
          data={data}
          previousDashboardData={previousDashboardData}
          topGraphBox="true"
          title={"Wallet Balance"}
          selectedTime={selectedTime}
        />
      </Box>
      <Box>
        <DoughnutChart
          customers={customers}
          data={appStatsData}
          centerData=""
          donut_type="dashboard"
        />
      </Box>
    </Box>
  );
};

export default AppStats;
