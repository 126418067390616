import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import TimeFilter from "../Filters/TimeFilter";
import OverviewBox from "./OverviewBox";
import DetailsBox from "./DetailsBox";
import TableBox from "./charging_points/TableBox";
import {
  companyMangementChargingColumns,
  companyMangementChargingData,
} from "../../utils/DummyData";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrg,
  getCMChargingStation,
  getCmStatsOverviewData,
  getOneOrg,
  getOverviewData,
} from "../../redux/action/companyManagement/companyManagementSlice";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Modals/DeleteModal";
import { toast } from "react-toastify";
import Breadcrumbs from "../breadcrumbs/BreadCrumbs";
import useScrollToTop from "../../Hooks/useScrollToTop";

// for tabs icons
const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopBar = () => {
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [orgId, setOrgId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const navigate = useNavigate();
  useScrollToTop(navigate);

  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  const [currentCmStatsOverviewData, setCurrentCmStatsOverviewData] =
    useState(null);

  // Redux hooks
  const dispatch = useDispatch();
  const {
    org,
    overviewData,
    chargingStations,
    cmStatsOverviewData,
    loading,
    error,
  } = useSelector((state) => state.company);

  console.log("eeeeee", cmStatsOverviewData);

  useEffect(() => {
    // Extract organization ID from URL
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    setOrgId(id);
  }, []);

  useEffect(() => {
    if (orgId) {
      dispatch(getOneOrg(orgId));
      dispatch(getOverviewData(orgId));
      dispatch(getCMChargingStation(orgId));
    }
  }, [dispatch, orgId]);

  const handleOpenDeleteModal = (e) => {
    e?.stopPropagation();
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = (e) => {
    e?.stopPropagation();
    setOpenDeleteModal(false);
  };

  const handleDelete = async () => {
    if (orgId) {
      try {
        await dispatch(deleteOrg(orgId)).unwrap();
        toast.success("Company deleted successfully!");
        navigate("/companies_management");
      } catch (error) {
        toast.error("Failed to delete the company.");
      } finally {
        setOpenDeleteModal(false);
      }
    }
  };

  // UseEffect for Time Filter to get the data
  // useEffect(() => {
  //   if (orgId && durationType && startTime && endTime) {
  //     dispatch(
  //       getCmStatsOverviewData({
  //         durationType,
  //         startTime,
  //         endTime,
  //         orgId,
  //       })
  //     );
  //   }
  // }, [dispatch, durationType, startTime, endTime]);

  // UseEffect for Time Filter to get the data
  useEffect(() => {
    const fetchData = async () => {
      if (orgId && durationType && startTime && endTime) {
        try {
          // Fetch overview data
          const overviewCMDummyData = await dispatch(
            getCmStatsOverviewData({
              orgId,
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();

          console.log("Current Time Overview Data:", overviewCMDummyData);
          setCurrentCmStatsOverviewData(overviewCMDummyData);
        } catch (error) {
          console.error("Error fetching current time data: ", error);
        }
      }
    };

    fetchData();
  }, [orgId, durationType, startTime, endTime, dispatch]);

  useEffect(() => {
    if (
      orgId &&
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentCmStatsOverviewData !== null
    ) {
      console.log("Previous Time API Call123:", {
        orgId,
        durationType,
        startTime: prevStartTime,
        endTime: prevEndTime,
      });

      dispatch(
        getCmStatsOverviewData({
          orgId,
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [
    orgId,
    durationType,
    prevStartTime,
    prevEndTime,
    dispatch,
    currentCmStatsOverviewData,
  ]);

  //sssssssssss

  console.log("iiiiiiiiiii", cmStatsOverviewData, currentCmStatsOverviewData);

  const tabsData = [
    {
      label: "Overview",
      content: (
        <Box>
          <OverviewBox
            data={overviewData}
            loading={loading}
            //CM Stats for graph
            // cmStatsData={cmStatsOverviewData}

            selectedTime={durationType}
            startTime={startTime}
            endTime={endTime}
            //Current Time Data
            cmStatsData={currentCmStatsOverviewData}
            //Previous Time Data
            previousDashboardData={cmStatsOverviewData}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      label: "Details",
      content: (
        <Box>
          <DetailsBox data={org} loading={loading} />
        </Box>
      ),
      icon: <CustomIcon icon={"info"} />,
    },
    {
      label: "Charging Stations",
      content: (
        <Box>
          <TableBox
            columns={companyMangementChargingColumns}
            rows={chargingStations}
            isRowClickable={true}
            orgCsData={org}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleChangePage = () => {
    navigate("/add_new_company", {
      state: {
        orgData: org?._id, // Pass the organization data as state
        edit: true,
      },
    });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Breadcrumbs />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Box
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "32px",
                overflow: "hidden", // Hide any overflow to keep the image contained
              }}
            >
              <img
                src={org?.companyLogo?.url}
                alt="Company Logo"
                style={{
                  width: "100%", // Make image take up full width of the container
                  height: "100%", // Make image take up full height of the container
                  objectFit: "cover", // Ensure the image covers the container while maintaining aspect ratio
                }}
              />
            </Box>
            <MainHeading text={org?.name} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              onClick={handleChangePage}
              sx={{
                padding: "8px 16px",
                borderRadius: "4px",
                border: "1px solid #E50000",
                maxWidth: "175px",
                width: "100%",
                color: "#E50000",
                fontWeight: "600",
                letterSpacing: "1.12px",
              }}
            >
              <span
                style={{
                  paddingRight: "8px",
                  fontSize: "24px",
                  fontWeight: "200",
                }}
                className="material-symbols-outlined"
              >
                edit
              </span>
              EDIT DETAILS
            </Button>
            <Button
              onClick={(e) => handleOpenDeleteModal(e)}
              sx={{
                padding: "8px",
                border: "1px solid #c0c0c0",
                borderRadius: "4px",
                minWidth: "unset",
              }}
            >
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "200",
                  color: "#790000",
                }}
                className="material-symbols-outlined"
              >
                delete
              </span>
            </Button>
          </Box>
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
      <DeleteModal
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        heading={"Company"}
        data={org}
        handleDelete={handleDelete}
      />
    </React.Fragment>
  );
};

export default TopBar;
