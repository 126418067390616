import React from "react";
import TopHeader from "../../components/add_new_company/TopHeader";
import AddNewCompanyForm from "../../components/add_new_company/AddNewCompanyForm";
import { useLocation } from "react-router-dom";

const AddNewCompany = () => {
  const location = useLocation();
  const {edit} = location.state || [];
  console.log("state123", edit) 
  return (
    <>
      {/* Top - Bar */}
      <TopHeader headingCheck={edit}/>

      {/* Add new company form */}
      <AddNewCompanyForm />
    </>
  );
};

export default AddNewCompany;
