// import { Box, Grid, Typography } from "@mui/material";
// import React from "react";

// const AcDcBox = ({ chargingPoints , previousChargingPoints}) => {
//   console.log("current, previous", chargingPoints, previousChargingPoints);
//   // Helper function to format value with + sign for positive values
//   const formatValue = (value) => {
//     if (value.startsWith("-")) {
//       return value;
//     }
//     return `+${value}`;
//   };

//   // Helper function to find the fastest and slowest device
//   function findFastestAndSlowest(devices, type) {
//     return devices
//       ?.filter((device) => device.deviceType === type)
//       ?.reduce(
//         (result, device) => {
//           const energyConsumed = parseFloat(device.energyConsumed);

//           if (
//             !result.fastest ||
//             energyConsumed > parseFloat(result.fastest.energyConsumed)
//           ) {
//             result.fastest = device;
//           }

//           if (
//             !result.slowest ||
//             energyConsumed < parseFloat(result.slowest.energyConsumed)
//           ) {
//             result.slowest = device;
//           }

//           return result;
//         },
//         { fastest: null, slowest: null }
//       );
//   }

//   // Get the fastest and slowest AC chargingPoints
//   const { fastest: fastestAC, slowest: slowestAC } = findFastestAndSlowest(
//     chargingPoints,
//     "AC"
//   );
//   // Get the fastest and slowest DC chargingPoints
//   const { fastest: fastestDC, slowest: slowestDC } = findFastestAndSlowest(
//     chargingPoints,
//     "DC"
//   );

//   console.log("Fastest AC Device:", fastestAC);
//   console.log("Slowest AC Device:", slowestAC);
//   console.log("Fastest DC Device:", fastestDC);
//   console.log("Slowest DC Device:", slowestDC);

//   const AcDcCardData = [
//     {
//       id: 1,
//       title: "DC fast",
//       icon: "pace",
//       // profit: "54.5%",
//       // energy: "1.7kWh",
//       // value: "12,050",
//       profit: fastestDC?.chargingAmount || 0,
//       energy: fastestDC?.chargingAmount || 0,
//       value: fastestDC?.chargingAmount || 0,
//     },
//     {
//       id: 2,
//       title: "DC slow",
//       icon: "groups",
//       profit: slowestDC?.chargingAmount || 0,
//       energy: slowestDC?.chargingAmount || 0,
//       value: slowestDC?.chargingAmount || 0,
//     },
//     {
//       id: 3,
//       title: "AC Fast",
//       icon: "currency_rupee_circle",
//       profit: fastestAC?.chargingAmount || 0,
//       energy: fastestAC?.chargingAmount || 0,
//       value: fastestAC?.chargingAmount || 0,
//     },
//     {
//       id: 4,
//       title: "AC slow",
//       icon: "bolt",
//       profit: slowestAC?.chargingAmount || 0,
//       energy: slowestAC?.chargingAmount || 0,
//       value: slowestAC?.chargingAmount || 0,
//     },
//   ];

//   return (
//     <Box sx={{ padding: "24px", background: "#fff", borderRadius: "8px" }}>
//       <Grid container spacing={2}>
//         {AcDcCardData.map((item) => {
//           // Determine the color based on the profit value
//           const profitColor = item.profit < 0 ? "#790000" : "#027900";
//           const profitBackground =
//             item.profit < 0 ? "rgba(121, 0, 0, 0.10)" : "rgba(2, 121, 0, 0.10)";

//           return (
//             <Grid item md={6} key={item.id}>
//               <Box
//                 sx={{
//                   border: "1px solid #ddd",
//                   borderRadius: "6px",
//                   display: "flex",
//                   flexDirection: "column",
//                   padding: "12px",
//                   gap: "10px",
//                 }}
//               >
//                 <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
//                   <Box
//                     sx={{
//                       background: "rgba(41, 45, 51, 0.08)",
//                       width: "24px",
//                       height: "24px",
//                       borderRadius: "24px",
//                       padding: "3px",
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                     }}
//                   >
//                     <span
//                       style={{ color: "#292D33", fontSize: "18px" }}
//                       className="material-symbols-outlined"
//                     >
//                       {item.icon}
//                     </span>
//                   </Box>
//                   <Typography
//                     fontSize={"18px"}
//                     fontFamily={"mySecondFont"}
//                     color={"#1c1c1c"}
//                   >
//                     {item.title}
//                   </Typography>
//                 </Box>
//                 <Box
//                   sx={{
//                     padding: "2px 8px",
//                     background: profitBackground,
//                     borderRadius: "50px",
//                     display: "flex",
//                     justifyContent: "center",
//                     maxWidth: "90px",
//                     width: "100%",
//                   }}
//                 >
//                   <Typography
//                     fontFamily={"mySecondFont"}
//                     color={profitColor}
//                     sx={{ display: "flex", alignItems: "center" }}
//                   >
//                     {item.profit}
//                     <span
//                       style={{
//                         fontSize: "16px",
//                         marginLeft: "4px",
//                         fontWeight: "600",
//                       }}
//                       className="material-symbols-outlined"
//                     >
//                       {item.profit < 0 ? "trending_down" : "trending_up"}
//                     </span>
//                   </Typography>
//                 </Box>
//                 <Box
//                   sx={{ display: "flex", gap: "4px", flexDirection: "column" }}
//                 >
//                   <Box
//                     sx={{ display: "flex", gap: "4px", alignItems: "center" }}
//                   >
//                     <span
//                       style={{ color: "#666666", fontWeight: "200" }}
//                       className="material-symbols-outlined"
//                     >
//                       bolt
//                     </span>
//                     <Typography
//                       color={"#2F2F2F"}
//                       fontFamily={"myThirdFont"}
//                       fontSize={"14px"}
//                     >
//                       {item.energy}
//                     </Typography>
//                   </Box>
//                   <Box
//                     sx={{ display: "flex", gap: "4px", alignItems: "center" }}
//                   >
//                     <span
//                       style={{ color: "#666666", fontWeight: "200" }}
//                       className="material-symbols-outlined"
//                     >
//                       currency_rupee_circle
//                     </span>
//                     <Typography
//                       color={"#2F2F2F"}
//                       fontFamily={"myThirdFont"}
//                       fontSize={"14px"}
//                     >
//                       ₹{item.value}
//                     </Typography>
//                   </Box>
//                 </Box>
//               </Box>
//             </Grid>
//           );
//         })}
//       </Grid>
//     </Box>
//   );
// };

// export default AcDcBox;

import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const AcDcBox = ({ chargingPoints, previousChargingPoints }) => {
  // Helper function to calculate percentage change
  const calculatePercentageChange = (current, previous, fallbackValue = 0) => {
    // Convert values to numbers to avoid issues with type coercion
    const currentValue = Number(current);
    const previousValue = Number(previous);

    // Check if previous data is missing or zero
    if (isNaN(previousValue) || previousValue === 0) {
      if (isNaN(currentValue) || currentValue === 0) {
        return "0.00%"; // Both are zero
      }
      return "N/A"; // Previous data is missing or zero but current data is available
    }

    // Calculate percentage change
    const percentageChange =
      ((currentValue - previousValue) / previousValue) * 100;

    // Return formatted result
    return percentageChange.toFixed(2) + "%";
  };

  // Calculate percentage changes
  const percentageChangeDCFast = calculatePercentageChange(
    chargingPoints?.fastDCData?.energyConsumed || 0,
    previousChargingPoints?.fastDCData?.energyConsumed || 0
  );
  const percentageChangeDCSlow = calculatePercentageChange(
    chargingPoints?.slowDCData?.energyConsumed || 0,
    previousChargingPoints?.slowDCData?.energyConsumed || 0
  );
  const percentageChangeACFast = calculatePercentageChange(
    chargingPoints?.fastACData?.energyConsumed || 0,
    previousChargingPoints?.fastACData?.energyConsumed || 0
  );
  const percentageChangeACSlow = calculatePercentageChange(
    chargingPoints?.slowACData?.energyConsumed || 0,
    previousChargingPoints?.slowACData?.energyConsumed || 0
  );

  // Data for cards including percentage change
  const AcDcCardData = [
    {
      id: 1,
      title: "DC Fast",
      icon: "pace",
      profit: chargingPoints?.fastDCData?.billedAmount,
      energy: chargingPoints?.fastDCData?.energyConsumed || 0,
      percentageChange: percentageChangeDCFast,
    },
    {
      id: 2,
      title: "DC Slow",
      icon: "groups",
      profit: chargingPoints?.slowDCData?.billedAmount,
      energy: chargingPoints?.slowDCData?.energyConsumed || 0,
      percentageChange: percentageChangeDCSlow,
    },
    {
      id: 3,
      title: "AC Fast",
      icon: "currency_rupee_circle",
      profit: chargingPoints?.fastACData?.billedAmount,
      energy: chargingPoints?.fastACData?.energyConsumed || 0,
      percentageChange: percentageChangeACFast,
    },
    {
      id: 4,
      title: "AC Slow",
      icon: "bolt",
      profit: chargingPoints?.slowACData?.billedAmount,
      energy: chargingPoints?.slowACData?.energyConsumed || 0,
      percentageChange: percentageChangeACSlow,
    },
  ];

  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        {AcDcCardData.map((item) => {
          // Determine the color based on the profit value
          const profitColor = item.profit < 0 ? "#790000" : "#027900";
          const profitBackground =
            item.profit < 0 ? "rgba(121, 0, 0, 0.10)" : "rgba(2, 121, 0, 0.10)";

          // Determine the color based on percentage change
          const percentageColor =
            item.percentageChange.startsWith("-") ? "#790000" : "#027900";
          const percentageBackground =
            item.percentageChange.startsWith("-")
              ? "rgba(121, 0, 0, 0.10)"
              : "rgba(2, 121, 0, 0.10)";

          return (
            <Grid item md={6} key={item.id}>
              <Box
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px",
                  gap: "10px",
                }}
              >
                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <Box
                    sx={{
                      background: "rgba(41, 45, 51, 0.08)",
                      width: "24px",
                      height: "24px",
                      borderRadius: "24px",
                      padding: "3px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{ color: "#292D33", fontSize: "18px" }}
                      className="material-symbols-outlined"
                    >
                      {item.icon}
                    </span>
                  </Box>
                  <Typography
                    fontSize={"18px"}
                    fontFamily={"mySecondFont"}
                    color={"#1c1c1c"}
                  >
                    {item.title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: "2px 8px",
                    background: percentageBackground,
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "90px",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontFamily={"mySecondFont"}
                    color={percentageColor}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {item.percentageChange}
                    <span
                      style={{
                        fontSize: "16px",
                        marginLeft: "4px",
                        fontWeight: "600",
                      }}
                      className="material-symbols-outlined"
                    >
                      {item.percentageChange.startsWith("-") ? "trending_down" : "trending_up"}
                    </span>
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "4px", flexDirection: "column" }}
                >
                  <Box
                    sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                  >
                    <span
                      style={{ color: "#666666", fontWeight: "200" }}
                      className="material-symbols-outlined"
                    >
                      bolt
                    </span>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"myThirdFont"}
                      fontSize={"14px"}
                    >
                      {item.energy}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                  >
                    <span
                      style={{ color: "#666666", fontWeight: "200" }}
                      className="material-symbols-outlined"
                    >
                      currency_rupee_circle
                    </span>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"myThirdFont"}
                      fontSize={"14px"}
                    >
                      ₹{item.profit}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default AcDcBox;

