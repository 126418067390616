// src/redux/slices/dashboardSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints"; // Ensure this import path is correct

// Define an initial state
const initialState = {
  data: null,
  previousData: null,
  customers: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,

  liveDeviceStatus: null, // Add state for live device status
};

// Create an async thunk for fetching data
// export const fetchDashboardData = createAsyncThunk(
//   "dashboard/fetchDashboardData",
//   async ({ durationType, startTime, endTime }, thunkAPI) => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.get(
//         `${endPoints.getDashboardData}/${durationType}/${startTime}/${endTime}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       return response.data;
//     } catch (error) {
//       // Improved error handling
//       return thunkAPI.rejectWithValue(
//         error.response ? error.response.data : error.message
//       );
//     }
//   }
// );
export const fetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async ({ durationType, startTime, endTime }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getDashboardData}?durationType=${durationType}&startTime=${startTime}&endTime=${endTime}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      // Improved error handling
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create an async thunk for fetching all customers
export const fetchAllCustomers = createAsyncThunk(
  "dashboard/fetchAllCustomers",
  async ({ durationType, startTime, endTime }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getAllCustomers}?durationType=${durationType}&startTime=${startTime}&endTime=${endTime}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
// Create an async thunk for fetching live device status data
export const fetchLiveDeviceStatus = createAsyncThunk(
  "dashboard/fetchLiveDeviceStatus",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getLiveDeviceStatus, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create a slice
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.previousData = action.payload;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.status = "failed";
        // Enhanced error handling to check for `action.payload` or `action.error.message`
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchAllCustomers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCustomers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customers = action.payload;
      })
      .addCase(fetchAllCustomers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })

      // Handle fetchLiveDeviceStatus
      .addCase(fetchLiveDeviceStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLiveDeviceStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.liveDeviceStatus = action.payload;
      })
      .addCase(fetchLiveDeviceStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export default dashboardSlice.reducer;
