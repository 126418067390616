import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import LeftColumnBox from "./UnregisteredManageBox/LeftColumnBox";
import RightColumnBox from "./UnregisteredManageBox/RightColumnBox";

const UnregisteredManageBox = ({ onRegister }) => {
  return (
    <Box>


      <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        {/* Left - graph - component */}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <LeftColumnBox onRegister={onRegister}/>
            </Box>
          </Grid>

          <Grid item md={8} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
          <RightColumnBox />
            </Box>
          </Grid>
        </Grid>

       
      
      </Box>
    </Box>
  );
};

export default UnregisteredManageBox;
