import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import OverviewBox from "./OverviewBox";
import TimeFilter from "../Filters/TimeFilter";
import DetailsBox from "./DetailsBox";
import ChargingPointBox from "./ChargingPointBox";
import SessionsBox from "./SessionsBox";
import ReviewBox from "./ReviewBox";
import { saveAs } from "file-saver";
import {
  sessionDetailsSessionsColumns,
  sessionDetailsSessionsData,
  stationDetailsChargingPointColumns,
  stationDetailsReviewColumns,
  stationDetailsReviewData,
} from "../../utils/DummyData";
import StatusChip from "../Chips/StatusChip";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOneStation,
  fetchLiveStationPointStatus,
  fetchOneStationDetail,
  fetchOneStationRating,
  fetchOverViewData,
  fetchQrByStation,
  fetchStationChargingPoint,
  fetchStationReviews,
  fetchStationSessions,
} from "../../redux/action/stations/stationSlice";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Modals/DeleteModal";
import { toast } from "react-toastify";
import JSZip from "jszip";
import { toPng } from "html-to-image";
import { mt } from "date-fns/locale";
import Breadcrumbs from "../breadcrumbs/BreadCrumbs";
import useScrollToTop from "../../Hooks/useScrollToTop";

// for tabs icons
const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [stationId, setStationId] = useState(null);
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [qrCodeData, setQrCodeData] = useState([]);
  const navigate = useNavigate();
  const elementRef = useRef(null);

  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);
  const [qrLoading, setQrLoading] = useState(false);

  const [currentOverviewData, setCurrentOverviewData] = useState(null);

  useScrollToTop(navigate);

  const {
    stations,
    stationDetail,
    stationChargingPoint,
    stationSessions,
    overViewData,
    stationReviews,
    stationQrCode,
    liveStationPointStatus,
    rating,
    loading,
    error,
  } = useSelector((state) => state.stations);

  const dispatch = useDispatch();

  useEffect(() => {
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setStationId(id);
    }
  }, []);

  useEffect(() => {
    if (stationId) {
      dispatch(fetchOneStationDetail(stationId));
      dispatch(fetchStationChargingPoint(stationId));
      dispatch(fetchStationSessions(stationId));
      dispatch(fetchStationReviews(stationId));
      dispatch(fetchLiveStationPointStatus(stationId));

      dispatch(fetchOneStationRating(stationId));
    }
  }, [dispatch, stationId]);

  // useEffect(() => {
  //   if (stationId && durationType && startTime && endTime) {
  //     dispatch(
  //       fetchOverViewData({
  //         stationId,
  //         durationType,
  //         startTime,
  //         endTime,
  //       })
  //     );
  //   }
  // }, [dispatch, stationId, durationType, startTime, endTime]);

  // Fetch data for current time
  useEffect(() => {
    const fetchData = async () => {
      if (stationId && durationType && startTime && endTime) {
        try {
          // Fetch overview data
          const overviewDummyData = await dispatch(
            fetchOverViewData({
              stationId,
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();

          console.log("Current Time Overview Data:", overviewDummyData);
          setCurrentOverviewData(overviewDummyData);
        } catch (error) {
          console.error("Error fetching current time data: ", error);
        }
      }
    };

    fetchData();
  }, [stationId, durationType, startTime, endTime, dispatch]);

  useEffect(() => {
    if (
      stationId &&
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentOverviewData !== null
    ) {
      console.log("Previous Time API Call123:", {
        stationId,
        durationType,
        startTime: prevStartTime,
        endTime: prevEndTime,
      });

      dispatch(
        fetchOverViewData({
          stationId,
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [
    stationId,
    durationType,
    prevStartTime,
    prevEndTime,
    dispatch,
    currentOverviewData,
  ]);

  console.log("oooorrrrr", overViewData, currentOverviewData);

  const handleDelete = async () => {
    if (stationId) {
      try {
        await dispatch(deleteOneStation(stationId)).unwrap();
        toast.success("Station deleted successfully!");
        navigate("/charging_stations");
      } catch (error) {
        toast.error("Failed to delete the station.");
      } finally {
        setOpenDeleteModal(false);
      }
    }
  };

  const handleOpenDeleteModal = (e) => {
    e?.stopPropagation();
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = (e) => {
    e?.stopPropagation();
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (stationQrCode) {
      setQrCodeData(stationQrCode);
    }
  }, [stationQrCode]);

  const GetQrCodeAndDownload = () => {
    setQrLoading(true);
    if (stationId) {
      dispatch(fetchQrByStation(stationId)).then((result) => {
        if (fetchQrByStation.fulfilled.match(result)) {
          const qrCodes = result.payload.allQrCodes;
          setQrCodeData(qrCodes);
        } else {
          console.error("Failed to fetch QR code:", result.payload);
        }
      });
    }
  };

  const downloadQRCode = (base64String, fileName = "qrcode.png") => {
    const link = document.createElement("a");
    link.href = base64String;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadZipWithQRCodes = (qrCodes) => {
    const zip = new JSZip();

    qrCodes.forEach((qrCode, index) => {
      zip.file(`qrcode${index + 1}.png`, qrCode.qrCodeImg.split(",")[1], {
        base64: true,
      });
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "qrcodes.zip");
    });
  };
  console.log("hhhhhhh", stationReviews);
  // const tabsData =
  //   stationDetail?.stationType === "1C" || stationDetail?.stationType === "1c"
  //     ? [
  //         {
  //           label: "Overview",
  //           content: (
  //             <OverviewBox
  //               // data={overViewData}

  //               //Current Time Data
  //               data={currentOverviewData}
  //               //Previous Time Data
  //               previousDashboardData={overViewData}
  //               selectedTime={durationType}
  //               startTime={startTime}
  //               endTime={endTime}
  //               liveStationData={liveStationPointStatus}
  //             />
  //           ),
  //           icon: <CustomIcon icon={"bar_chart_4_bars"} />,
  //         },
  //         {
  //           label: "Details",
  //           content: <DetailsBox data={stationDetail} />,
  //           icon: <CustomIcon icon={"info"} />,
  //         },
  //         {
  //           label: "Charging points",
  //           content: (
  //             <ChargingPointBox
  //               columns={stationDetailsChargingPointColumns}
  //               isRowClickable={true}
  //               data={stationChargingPoint}
  //             />
  //           ),
  //           icon: <CustomIcon icon={"ev_station"} />,
  //         },
  //         {
  //           label: "Sessions",
  //           content: (
  //             <SessionsBox
  //               columns={sessionDetailsSessionsColumns}
  //               rows={stationSessions}
  //               isRowClickable={true}
  //             />
  //           ),
  //           icon: <CustomIcon icon={"battery_charging_50"} />,
  //         },
  //         {
  //           label: "Reviews",
  //           content: (
  //             <ReviewBox
  //               columns={stationDetailsReviewColumns}
  //               rows={stationReviews}
  //               isRowClickable={false}
  //             />
  //           ),
  //           icon: <CustomIcon icon={"reviews"} />,
  //         },
  //       ]
  //     : [
  //         tabsData[1], // Details
  //         tabsData[2], // Charging points
  //       ];

  const tabsData = [
    {
      label: "Overview",
      content: (
        <OverviewBox
          data={currentOverviewData}
          selectedTime={durationType}
          previousDashboardData={overViewData}
          startTime={startTime}
          endTime={endTime}
          liveStationData={liveStationPointStatus}
        />
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      label: "Details",
      content: <DetailsBox data={stationDetail} />,
      icon: <CustomIcon icon={"info"} />,
    },
    {
      label: "Charging points",
      content: (
        <ChargingPointBox
          columns={stationDetailsChargingPointColumns}
          isRowClickable={true}
          data={stationChargingPoint}
          stationDataGetOne={stationDetail}
          loading={loading}
        />
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
    {
      label: "Sessions",
      content: (
        <SessionsBox
          columns={sessionDetailsSessionsColumns}
          rows={stationSessions}
          isRowClickable={true}
          loading={loading}
        />
      ),
      icon: <CustomIcon icon={"battery_charging_50"} />,
    },
    {
      label: "Reviews",
      content: (
        <ReviewBox
          columns={stationDetailsReviewColumns}
          // rows={stationReviews}
          rows={rating}
          isRowClickable={true}
        />
      ),
      icon: <CustomIcon icon={"reviews"} />,
    },
  ];

  const filteredTabsData =
    stationDetail?.stationType === "1c" ||
    stationDetail?.stationType === "1C" ||
    !stationDetail?.stationType
      ? tabsData
      : [
          tabsData[1], // Details
          tabsData[2], // Charging points
        ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  let stationData = stationDetail?._id;

  const handleEditClick = () => {
    navigate("/add_new_charging_station", {
      state: { stationData: stationData, edit: true },
    });
  };

  useEffect(() => {
    if (qrCodeData.length > 0) {
      if (elementRef.current) {
        setTimeout(() => {
          htmlToImageConvert(qrCodeData);
        }, 2000);
      }
    }
  }, [qrCodeData]);

  const htmlToImageConvert = async (qrCodes) => {
    const zip = new JSZip();

    console.log("okkkkkk", elementRef.current);

    for (const qrCode of qrCodes) {
      // Update the content of the elementRef with the current QR code data
      if (elementRef.current) {
        // elementRef.current.querySelector(".qrCodeImg1").src =
        //   "/images/qrCode.png";
        // elementRef.current.querySelector(".qrCodeImg2").src = qrCode?.qrCodeImg;
        // elementRef.current.querySelector(".deviceName").textContent =
        //   qrCode?.deviceId;

        elementRef.current.querySelector(".qrCodeImg").src = qrCode?.qrCodeImg;
        elementRef.current.querySelector(".qrCodeTitle").textContent =
          qrCode?.deviceId;

        // Generate the image
        elementRef.current.style.display = "block"; // Temporarily make the element visible
        try {
          const dataUrl = await toPng(elementRef.current, { cacheBust: false });
          elementRef.current.style.display = "none"; // Hide the element again

          // Add the image to the ZIP file with the correct name
          const imgData = dataUrl.split(",")[1]; // Remove data:image/png;base64,
          const fileName = `${qrCode?.deviceId}.png`; // Correct file name based on deviceId
          zip.file(fileName, imgData, { base64: true });
        } catch (err) {
          elementRef.current.style.display = "none"; // Hide the element if there's an error
          console.log(err);
        }
      }
    }
    setQrLoading(false);

    // Generate the ZIP file and save it
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${stationDetail?.name || "qrCode"}.zip`);
    });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box>
          <Breadcrumbs />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Box
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "32px",
                backgroundImage: "url('/images/stationImg.png')",
                backgroundSize: "cover",
                flexDirection: "column",
              }}
            ></Box>

            <MainHeading text={stationDetail?.name} />
            <StatusChip text={stationDetail?.status} />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              onClick={GetQrCodeAndDownload}
              sx={{
                padding: "8px 16px",
                borderRadius: "4px",
                border: "1px solid #c0c0c0",
                width: "100%",
                color: "#222222",
                fontFamily: "myThirdFont",
                textWrap: "nowrap",
                fontSize: "14px",
              }}
            >
              <span
                style={{
                  paddingRight: "8px",
                  fontSize: "24px",
                  fontWeight: "200",
                }}
                className="material-symbols-outlined"
              >
                qr_code_scanner
              </span>
              {qrLoading && qrCodeData?.length > 0
                ? "Downloading..."
                : "Download QR Code"}
            </Button>
            <Button
              onClick={handleEditClick}
              sx={{
                padding: "8px 16px",
                borderRadius: "4px",
                border: "1px solid #E50000",
                maxWidth: "175px",
                width: "100%",
                color: "#E50000",
                fontWeight: "600",
                letterSpacing: "1.12px",
              }}
            >
              <span
                style={{
                  paddingRight: "8px",
                  fontSize: "24px",
                  fontWeight: "200",
                }}
                className="material-symbols-outlined"
              >
                edit
              </span>
              EDIT DETAILS
            </Button>
            <Button
              onClick={(e) => handleOpenDeleteModal(e)}
              sx={{
                padding: "8px",
                border: "1px solid #c0c0c0",
                borderRadius: "4px",
                minWidth: "unset",
              }}
            >
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "200",
                  color: "#790000",
                }}
                className="material-symbols-outlined"
              >
                delete
              </span>
            </Button>
          </Box>
        </Box>

        <CustomTabs tabs={filteredTabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>

      {/* Ensure that tabsData[selectedTab] exists and has content */}
      <Box>{tabsData[selectedTab]?.content || null}</Box>
      <DeleteModal
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        heading={"Charging Stations"}
        data={stationDetail}
        handleDelete={handleDelete}
      />

      {qrCodeData?.length > 0
        ? qrCodeData?.map((qrCode) => (
            <>
              <Box sx={{ width: "740px", display: "none" }} ref={elementRef}>
                <Box
                  sx={{
                    width: "100%",
                    minWidth: "740px",
                    backgroundColor: "#E50000",
                    padding: "80px",
                  }}
                  download="w3logo"
                >
                  <Box
                    sx={{
                      backgroundColor: "#FFF",
                      borderRadius: "15px",
                      padding: "48px 76px",
                    }}
                  >
                    <img
                      src="/images/qrCodeImgs/logo.svg"
                      alt="Img not found"
                    />
                    <hr />
                    <Typography
                      sx={{
                        color: "#1C1C1C",
                        textAlign: "center",
                        fontFamily: "myFirstFont",
                        fontSize: "32px",
                        fontStyle: "normal",
                        paddingTop: "32px",
                        paddingBottom: "16px",
                        lineHeight: "40px",
                      }}
                    >
                      Scan and Pay using any app
                    </Typography>
                    {/* <Box
                    sx={{
                      padding: "14px 23px",
                      width: "100%",
                      height: "274px",
                      backgroundColor: "#D9D9D9",
                    }}
                  ></Box> */}

                    <Box
                      sx={{
                        width: "100%",
                        // height: "274px",
                        // backgroundColor: "#D9D9D9",
                      }}
                    >
                      <img
                        className="qrCodeImg"
                        src={qrCode.qrCodeImg}
                        width="100%"
                        alt=""
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#1C1C1C",
                          textAlign: "center",
                          fontFamily: "myFirstFont",
                          fontSize: "36px",
                          fontStyle: "normal",
                          lineHeight: "48px",
                        }}
                        className="qrCodeTitle"
                      >
                        {qrCode.deviceId}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1C1C1C",
                          textAlign: "center",
                          fontFamily: "myThirdFont",
                          fontSize: "32px",
                          fontStyle: "normal",
                          lineHeight: "40px",
                        }}
                      >
                        Scan and Pay using any app
                      </Typography>
                    </Box>
                    <hr />
                    <Box sx={{ width: "100%", paddingTop: "24px" }}>
                      <img
                        src="/images/qrCodeImgs/paymentLogos.svg"
                        width="100%"
                        alt=""
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ))
        : null}
    </React.Fragment>
  );
};

export default TopBar;
