import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ValueCard from "../../dashboard/ValueCard";
import { valueCardData } from "../../../utils/DummyData";

const ValueCardBox = ({ data, previousDashboardData }) => {
  const filterValueCardData = valueCardData.filter(
    (card) => card.title === "Total uptime (hrs)"
  );

  return (
    <Box
      sx={{
        background: "#fff",
        width: "100%",
        borderRadius: "8px",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={12} sx={12}>
          {/* value cards */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
              <ValueCard
                data={data}
                previousDashboardData={previousDashboardData}
                title="Total uptime (hrs)"
                topGraphBox="false"
              />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ValueCardBox;
