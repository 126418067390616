import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { deviceStatusData } from "../../utils/DummyData";

const ProgressBar = ({ dashboardProgBar, data, ratingData }) => {
  console.log("rat", ratingData?.ratings);

  //Rating ProgressBar
  const totalRatings = ratingData?.ratings?.totalRatings;
  const totalGreat = ratingData?.ratings?.totalGreat || 0;
  const totalOkay = ratingData?.ratings?.totalOkay || 0;
  const totalBad = ratingData?.ratings?.totalBad || 0;

  // Calculate percentages for each rating
  const percentageGreat = ((totalGreat / totalRatings) * 100).toFixed(2);
  const percentageOkay = ((totalOkay / totalRatings) * 100).toFixed(2);
  const percentageBad = ((totalBad / totalRatings) * 100).toFixed(2);

  const overallRatingStatus = [
    {
      id: 1,
      status: "Great",
      noOfDevices: ratingData?.ratings?.totalGreat,
      percentage: percentageGreat,
    },
    {
      id: 2,
      status: "Ok",
      noOfDevices: ratingData?.ratings?.totalOkay,
      percentage: percentageOkay,
    },
    {
      id: 3,
      status: "Bad",
      noOfDevices: ratingData?.ratings?.totalBad,
      percentage: percentageBad,
    },
  ];

  //For Dashboard -> api data function
  const deviceStatusDashboard = [
    {
      id: 1,
      status: "online",
      noOfDevices: data?.data?.availableCharingPoints,
    },
    {
      id: 2,
      status: "faulted",
      noOfDevices: data?.data?.faultedCharingPoints,
    },
    {
      id: 3,
      status: "offline",
      noOfDevices: data?.data?.offlineCharingPoints,
    },
  ];
  const totalChargingPoints = data?.data?.totalCharingPoints;

  console.log(data);

  return (
    <Box>
      <Grid container spacing={2}>
        {dashboardProgBar === "true" ? (
          <Grid item md={3}>
            <Box sx={{ textAlign: "left" }}>
              <Typography
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
                fontWeight={"400"}
                lineFeight={"16px"}
                color={"#666"}
              >
                Total Devices
              </Typography>
              <Typography
                fontFamily={"Montserrat"}
                fontSize={"24px"}
                fontWeight={"600"}
                lineFeight={"32px"}
                color={"#666"}
              >
                {data?.data?.totalCharingPoints}
              </Typography>
            </Box>
          </Grid>
        ) : null}

        {dashboardProgBar === "true" && (
          <Grid item md={9}>
            <Box sx={{ padding: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "32px",
                  borderRadius: "4px",
                  overflow: "hidden",
                  backgroundColor: "#E0E0E0",
                }}
              >
                {dashboardProgBar === "true"
                  ? //For Dashboard Prog Bar
                    deviceStatusDashboard.map((item) => (
                      <Box
                        key={item.id}
                        sx={{
                          backgroundColor:
                            item.status === "online"
                              ? "#64D95A"
                              : item.status === "faulted"
                              ? "#FF974B"
                              : "#D95A5A",
                          width: `${
                            (item.noOfDevices / totalChargingPoints) * 100
                          }%`,
                        }}
                      />
                    ))
                  : //For Rating Prog Bar ->
                    overallRatingStatus.map((item) => (
                      <Box
                        key={item.id}
                        sx={{
                          backgroundColor:
                            item.status === "Great"
                              ? "#64D95A"
                              : item.status === "Ok"
                              ? "#FF974B"
                              : "#D95A5A",
                          width: `${(item.noOfDevices / totalRatings) * 100}%`,
                        }}
                      />
                    ))}
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Good Bad Ok Ratings */}
      {dashboardProgBar === "false" ? (
        <>
          <Box>
            <Typography
              sx={{
                color: "#2F2F2F",
                fontFamily: "myThirdFont",
                fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
                fontWeight: "400",
                lineHeight: "24px",
                textAlign: "left",
                mt:"16px"
              }}
            >
              Total:
              <span style={{ fontFamily: "mySecondFont" }}>{totalRatings}</span>
            </Typography>
          </Box>

          <Grid item md={12}>
            <Box sx={{ padding: "8px 0" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "16px",
                  borderRadius: "4px",
                  overflow: "hidden",
                  backgroundColor: "#E0E0E0",
                }}
              >
                {dashboardProgBar === "true"
                  ? //For Dashboard Prog Bar
                    deviceStatusDashboard.map((item) => (
                      <Box
                        key={item.id}
                        sx={{
                          backgroundColor:
                            item.status === "online"
                              ? "#64D95A"
                              : item.status === "faulted"
                              ? "#FF974B"
                              : "#D95A5A",
                          width: `${
                            (item.noOfDevices / totalChargingPoints) * 100
                          }%`,
                        }}
                      />
                    ))
                  : //For Rating Prog Bar ->
                    overallRatingStatus.map((item) => (
                      <Box
                        key={item.id}
                        sx={{
                          backgroundColor:
                            item.status === "Great"
                              ? "#64D95A"
                              : item.status === "Ok"
                              ? "#FF974B"
                              : "#D95A5A",
                          width: `${(item.noOfDevices / totalRatings) * 100}%`,
                        }}
                      />
                    ))}
              </Box>
            </Box>
          </Grid>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens and horizontally on larger screens
              alignItems: "center",
              flexDirection:"column",
             alignItems:"left",
              gap: 2,
            }}
          >
            {overallRatingStatus.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  flexDirection: "row", // Align items horizontally
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    backgroundColor:
                      item.status === "Great"
                        ? "#64D95A"
                        : item.status === "Ok"
                        ? "#FF974B"
                        : "#D95A5A",
                    borderRadius: "50%",
                    marginRight: "4px",
                  }}
                ></Box>

                <Typography
                  sx={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
                    fontWeight: "400",
                    lineHeight: "24px",
                    marginRight: "4px",
                  }}
                >
                  {item.status === "Great"
                    ? "Good"
                    : item.status === "Bad"
                    ? "Bad"
                    : item.status === "Ok"
                    ? "Ok"
                    : null}
                </Typography>

                <Typography
                  sx={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  ({item.noOfDevices}/{item.percentage})
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default ProgressBar;
