import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "../../Api/endPoints";
import axios from "axios";
import { getProfile } from "./profileSlice";

// Initial state for authentication
const authInitialState = {
  user: null,
  loading: false,
  error: null,
  otpSent: false,
  otpVerified: false,
  token: null,
  passwordReset: false,
};

// Thunk to handle the login API call
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (credentials, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.login, credentials);
      const data = response.data;
      localStorage.setItem("token", data.userInfo.jwtToken);
      dispatch(getProfile()); // Dispatch getProfile after login
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendOTP = createAsyncThunk(
  "auth/sendOTP",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.sendOTP, {
        phone: phoneNumber,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async ({ phoneNumber, otp }, { rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.verfyOTP, {
        phone: phoneNumber,
        otp: otp,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// New thunk to resend OTP
export const resendOTP = createAsyncThunk(
  "auth/resendOTP",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.resendOTP, {
        phone: phoneNumber,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Updated thunk for forget password
export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async ({ password, userToken }, { rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.forgetPassword, {
        password,
        userToken,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the auth slice
const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(sendOTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOTP.fulfilled, (state) => {
        state.loading = false;
        state.otpSent = true;
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyOTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.otpVerified = true; // Update OTP verification status
        state.token = action.payload;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(resendOTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resendOTP.fulfilled, (state) => {
        state.loading = false;
        state.otpSent = true; // Optionally update state to reflect OTP resent
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.passwordReset = false;
      })
      .addCase(forgetPassword.fulfilled, (state) => {
        state.loading = false;
        state.passwordReset = true; // Update state for successful password reset
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
