import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";
import { useNavigate } from "react-router-dom";

const TableBox = ({ columns, rows, isRowClickable, orgCsData }) => {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState(null);

  console.log("orgcsdata", orgCsData);

  console.log("uuu23", rows);
  const handlePageChange = () => {
    navigate("/add_new_charging_station", {
      state: {
        CsOrgData: {
          id: orgCsData?._id,
          name: orgCsData?.name,
        },
      },
    });
  };

  console.log(rows);
  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters />
        <Button
          onClick={handlePageChange}
          sx={{
            background: "#222",
            padding: "8px 24px",
            width: "142px",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontFamily: "mySecondFont",
            letterSpacing: "1.12px",
            textWrap: "nowrap",
            ":hover": {
              background: "#222",
            },
          }}
        >
          + ADD NEW
        </Button>
      </Box>
      <CustomTable
        columns={columns}
        rows={rows}
        isRowClickable={isRowClickable}
        clickableColumns={["name"]}
        columnClickPaths={{
          name: "/charging_stations",
        }}
        columnKeyMappings={{
          name: "_id", // Use 'sessionID' key for 'sessionID' column
        }}
        actionType={"edit-delete"}
        statsHeading={"true"}
        modalHeading={"Charging Stations"}
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default TableBox;
