import React from "react";
import { useSelector } from "react-redux";
import CPODashboard from "../pages/CPODashboard/CPODashboard";
import { useNavigate } from "react-router-dom";

const RoleBasedRoute = ({ element: Element, ...rest }) => {
  // Fetch profile data from the Redux store
  const profileData = useSelector((state) => state?.profile?.profile);

  // Extract the role from profile data, default to an empty string if undefined
  const role = profileData?.role || "";
  const navigate = useNavigate();

  console.log("Role:", role); // Log the role for debugging

  // Check if the role matches the expected value for rendering CPODashboard
  if (role === "CPO_Admin") {
    return <CPODashboard />;
  }
  if (role === "1C_AccountsManager") {
    navigate("/home");
  }
  // Render the passed element otherwise
  return <Element {...rest} />;
};

export default RoleBasedRoute;
