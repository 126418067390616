import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendOTP } from "../../../redux/action/authSlice";
import { toast } from "react-toastify";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    phone: "",
  });
  const [error, setError] = useState(null);
  const [validUser, setValidUser] = useState(false);
  // Access the loading and error state from Redux
  const {
    loading,
    otpSent,
    error: otpError,
  } = useSelector((state) => state.auth);

  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length <= 10 && /^\d*$/.test(value)) {
      setCredentials({
        ...credentials,
        [e.target.name]: value,
      });
    }
  };

  const handleResetLink = () => {
    if (credentials.phone.length === 10) {
      // Prepend +91 to the phone number before dispatching the action
      const formattedPhone = `+91${credentials.phone}`;
      // Dispatch the sendOTP action with the formatted phone number
      dispatch(sendOTP(formattedPhone))
        .unwrap()
        .then(() => {
          toast.success("OTP sent successfully");
          // Correct the navigate call to pass state properly
          navigate("/verify_email", { state: { phone: formattedPhone } });
        })
        .catch((err) => {
          toast.error(err.message || "Failed to send OTP");
        });
    } else {
      toast.error("Please enter a valid 10-digit phone number.");
    }
  };

  const handleBack = () => {
    navigate("/");
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url("/images/auth-bg-final.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            padding: "32px",
            borderRadius: "8px",
            height: "586px",
            justifyContent: validUser ? "center" : "start",
          }}
        >
          {!validUser ? (
            <Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
              >
                <Box sx={{ width: "100%" }}>
                  <img src="/images/reset-password-logo.png" alt="logo" />
                </Box>

                <Box sx={{ paddingBottom: "8px" }}>
                  <Typography
                    sx={{
                      color: "#0C0C0C",
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "24px",
                      fontWeight: 600,
                      lineHeight: "32px",
                      marginBottom: "8px",
                    }}
                  >
                    Reset your password
                  </Typography>
                  <Typography
                    sx={{
                      color: "#0C0C0C",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      textAlign: "center",
                      width: "100%",
                      margin: "auto",
                      maxWidth: "320px",
                    }}
                  >
                    Forgot your password? Please enter your phone and we will
                    send you a password reset link.
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginBottom: "8px",
                      fontSize: "16px",
                      color: "#2F2F2F",
                      fontFamily: "mySecondFont",
                    }}
                  >
                    Phone
                  </Typography>
                  <input
                    name="phone"
                    value={credentials.phone}
                    onChange={handleChange}
                    type="number"
                    style={{
                      width: "416px",
                      height: "48px",
                      borderRadius: "4px",
                      border: "1px solid #CBCBCB",
                      outline: "none",
                      padding: "0 16px",
                    }}
                  />
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
                >
                  <Box>
          
                    <Button
                      onClick={handleResetLink}
                      sx={{
                        width: "100%",
                        background: "#000000",
                        padding: "12px 24px",
                        color: "#FCFCFC",
                        fontFamily: "mySecondFont",
                        justifyContent: "center",
                        alignItems: "center",
                        letterSpacing: "1.28px",
                        borderRadius: "8px",
                        "&:hover": {
                          background: "#2F2F2F",
                          color: "#EEEEEE",
                        },
                      }}
                      disabled={loading}
                    >
                      SEND RESET LINK
                    </Button>
                    {error && (
                      <Typography color="error">{error.message}</Typography>
                    )}
                  </Box>
                  <Box>
                    <Button
                      sx={{
                        width: "100%",
                        background: "#FFF",
                        padding: "12px 24px",
                        color: "#222222",
                        fontFamily: "mySecondFont",
                        justifyContent: "center",
                        alignItems: "center",
                        letterSpacing: "1.28px",
                        borderRadius: "8px",
                        border: "1px solid #CBCBCB",
                        "&:hover": {
                          background: "#EEEEEE",
                        },
                      }}
                      onClick={handleBack}
                    >
                      BACK
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  justifyContent: "center",
                  width: "416px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <img src="/images/success-logo.png" alt="logo" />
                </Box>

                <Box>
                  <Typography
                    sx={{
                      color: "#0C0C0C",
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "24px",
                      fontWeight: 600,
                      lineHeight: "32px",
                    }}
                  >
                    Password reset link sent!
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "8px",
                      paddingBottom: "4px",
                    }}
                  >
                    <span className="material-symbols-outlined">mail</span>
                  </Box>
                  <Typography
                    sx={{
                      color: "#0C0C0C",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      textAlign: "center",
                      width: "100%",
                      margin: "auto",
                      maxWidth: "320px",
                    }}
                  >
                    We have sent you the password link, please check your email
                  </Typography>
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
                >
                  <Box>
                    <Button
                      sx={{
                        width: "100%",
                        background: "#FFF",
                        padding: "12px 24px",
                        color: "#222222",
                        fontFamily: "mySecondFont",
                        justifyContent: "center",
                        alignItems: "center",
                        letterSpacing: "1.28px",
                        borderRadius: "8px",
                        border: "1px solid #CBCBCB",
                        "&:hover": {
                          background: "#EEEEEE",
                        },
                      }}
                      onClick={() => setValidUser(false)}
                    >
                      BACK TO LOGIN
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResetPasswordPage;
