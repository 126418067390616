import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import OverviewBox from "./OverviewBox";
import AllPaymentBox from "./AllPaymentBox";
import CustomTabs from "../custom/CustomTabs";
import TimeFilter from "../Filters/TimeFilter";
import MainHeading from "../../utils/MainHeading";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../redux/action/dashboard/dashboardSlice";
import {
  fetchOrgStats,
  fetchWalletDetails,
} from "../../redux/action/statistics/statisticsSlice";
import { getAllPayments } from "../../redux/action/payments/paymentSlice";

// for tabs icons
const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopHeader = () => {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(0);
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  const { walletDetails, orgStats, status, error } = useSelector(
    (state) => state.statistics
  );
  const user = useSelector((state) => state?.profile?.profile);
 console.log('yyyyyyyy', user?.role)

  const [currentOrgStats, setCurrentOrgStats] = useState(null);
  const [currentWalletDetails, setCurrentWalletDetails] = useState(null);

  const { payments, laoding } = useSelector((state) => state.payment);

  // UseEffect for Time Filter to get the data
  useEffect(() => {
    const fetchData = async () => {
      if (durationType && startTime && endTime) {
        try {
          const dummyData1 = await dispatch(
            fetchOrgStats({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();
          console.log("dummyData", dummyData1);
          setCurrentOrgStats(dummyData1);

          const dummyData2 = await dispatch(
            fetchWalletDetails({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();

          console.log("dummyData", dummyData2);
          setCurrentWalletDetails(dummyData2);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      }
    };
    fetchData();
  }, [durationType, startTime, endTime, dispatch]);

  // UseEffect for Time Filter to get the data of Previous Time
  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentOrgStats &&
      currentWalletDetails
    ) {
      dispatch(
        fetchOrgStats({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
      dispatch(
        fetchWalletDetails({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [
    durationType,
    prevStartTime,
    prevEndTime,
    currentOrgStats,
    currentWalletDetails,
    dispatch,
  ]);

  console.log("currentOrgStats", orgStats, currentOrgStats);
  console.log("currentWalletDetails", walletDetails, currentWalletDetails);

  useEffect(() => {
    dispatch(getAllPayments());
  }, [dispatch]);

  const tabsData = [
    {
      label: "Overview",
      content: (
        <Box>
          <OverviewBox
            // data={currentWalletDetails}
            // orgData={currentOrgStats}

            //wallte data
            data={currentWalletDetails}
            previousPaymentWalletData={walletDetails}
            //org data
            orgData={currentOrgStats}
            previousPaymentOrgData={orgStats}
            selectedTime={durationType}
            startTime={startTime}
            endTime={endTime}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      label: "all Payments",
      content: (
        <Box>
          <AllPaymentBox payments={payments} userRoleProp={user?.role}/>
        </Box>
      ),
      icon: <CustomIcon icon={"sync_alt"} />,
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <MainHeading text={"Payments"} />
          </Box>
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <TimeFilter /> */}
            <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopHeader;
