import { Box, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { TextCopy } from "../../../utils/TextCopy/TextCopy";

const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: icon ? "8px" : "",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
          textAlign={"start"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const ConnectorBox = ({ connectors }) => (
  <Box
    sx={{ display: "flex", alignItems: "center", gap: "4px", flexDirection:"row" }}
  >
    {connectors?.map((connector) => (
      <Box
        key={connector._id} // Use connector._id for unique key
        sx={{
          padding: "2px 12px",
          background: "rgba(41, 45, 51, 0.08)",
          maxWidth: "85px",
          width: "100%",
          borderRadius: "85px",
        }}
      >
        <Typography fontSize={"14px"} fontFamily={"mySecondFont"}>
          {connector?.connectorType}
        </Typography>
      </Box>
    ))}
  </Box>
);

const SessionDetailBox2 = ({ data }) => {
  const connectors = ["CCS2"];
  return (
    <Box
      sx={{
        padding: "24px 16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <InfoBox
          label="Device ID"
          value={data?.deviceId}
          icon={"content_copy"}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              flexDirection: "row",
            }}
          >
            <Typography
              fontFamily={"myThirdFont"}
              fontSize={"14px"}
              color={"#2F2F2F"}
              textAlign={"start"}
            >
              Connector & Connector ID   
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent:"flex-start",
              gap: "12px",
              flexDirection: "row",
            }}
          >
            <ConnectorBox connectors={data?.connectors} />
            <Typography
              color={"#2F2F2F"}
              fontSize={"16px"}
              fontFamily={"mySecondFont"}
              textAlign={"start"}
            >
              {data?.connectorId}
            </Typography>
          </Box>
          <Box></Box>
        </Box>
        <InfoBox label="Energy consumed (Kwh)" value={data?.energyConsumed} />
        <InfoBox label="Charging Mode" value={data?.from === "DIRECT_PAYMENT" ? "DIRECT PAYMENT" : data?.from === "APP_CHARGING"?"APP CHARGING":  data?.from } />
        <InfoBox label="Customer ID" value={data?.customerId} />
      </Box>
    </Box>
  );
};

export default SessionDetailBox2;
