import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import {
  paymentColumns,
  paymentColumnsForCPO,
  paymentData,
} from "../../utils/DummyData";

const AllPaymentBox = ({ payments, userRoleProp }) => {
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);

  console.log("tytyty", userRoleProp);

  useEffect(() => {
    if (payments.length > 0) {
      setFilteredPayments(payments);
    }
  }, [payments]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredPayments(filteredData);
  };

  const myFilters = [
    {
      value: "paymentId",
      title: "Payment ID",
    },
    {
      value: "mode",
      title: "Payment Mode",
    },
    {
      value: "type",
      title: "Type",
    },
    {
      value: "createdAt",
      title: "Date",
    },
  ];

  return (
    <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          data={payments}
          onFilterChange={handleFilterChange}
          filters={myFilters}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "16px",
          mt: "12px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "8px",
              background: "#027900",
            }}
          ></Box>
          <Typography fontSize={"14px"} fontFamily={"myThirdFont"}>
            Credited
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "8px",
              background: "#790000",
            }}
          ></Box>
          <Typography fontSize={"14px"} fontFamily={"myThirdFont"}>
            Refund
          </Typography>
        </Box>
      </Box>

      {/* Table */}
      <CustomTable
        columns={
          userRoleProp === "CPO_Admin" ? paymentColumnsForCPO : paymentColumns
        }
        rows={filteredPayments}
        actionType={"edit-delete"}
        specialColumns={["paymentId"]}
        isRowClickable={userRoleProp === "CPO_Admin" ? true : false}
        clickableColumns={["sessionID"]}
        columnClickPaths={{
          sessionID: "/charging_sessions",
        }}
        columnKeyMappings={{
          _id: "sessionID", // Use 'sessionID' key for 'sessionID' column
        }}
        // clickableColumns={["paymentId"]}
        modalHeading="Payments"
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default AllPaymentBox;
