import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../utils/Heading";

const TopPerformStationBox = ({ stationStats }) => {
  console.log("wasqwasq", stationStats);
  const topPerformerStation = stationStats
    ?.map((item) => ({
      ...item,
      energyConsumed: parseFloat(item.energyConsumed),
    }))
    .sort((a, b) => b.energyConsumed - a.energyConsumed)
    .slice(0, 3);

  console.log("hehehehhehehehehee", topPerformerStation);
  return (
    <Box
      sx={{
        padding: "24px 24px 28px 24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
        minHeight: "200px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img src="/images/stars.png" alt="" />
        <Heading text={"Top Performing Stations"} />
      </Box>
      <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />

      {/* Top performer Card */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {/* {stationStats?.map((item, index) => {
          return <p>hey</p>;
        })} */}

        {topPerformerStation.length === 0 ? (
          <Typography
            sx={{
              color: "#AFAFAF",
              fontFamily: "myThirdFont",
              fontSize: "14px",
              mt: "18px",
            }}
          >
            No Stations available
          </Typography>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {topPerformerStation.map((item, index) => (
              <Box
                key={index}
                sx={{
                  padding: "12px",
                  borderLeft:
                    index === 1
                      ? "1px solid #BEBEBE"
                      : index === 2
                      ? "1px solid #D3883E"
                      : "1px solid #FFB02E",
                  background:
                    index === 1
                      ? "linear-gradient(0deg, rgba(190, 190, 190, 0.05) 0%, rgba(190, 190, 190, 0.05) 100%), #FFF"
                      : index === 2
                      ? "linear-gradient(0deg, rgba(211, 136, 62, 0.05) 0%, rgba(211, 136, 62, 0.05) 100%), #FFF"
                      : "linear-gradient(0deg, rgba(255, 176, 46, 0.05) 0%, rgba(255, 176, 46, 0.05) 100%), #FFF",
                  boxShadow: "0px 1px 4px 0px rgba(41, 45, 51, 0.25)",
                  borderRadius: "4px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <img
                    width={"20px"}
                    src={
                      index === 1
                        ? "/images/top2.png"
                        : index === 2
                        ? "/images/top3.png"
                        : "/images/top1.png"
                    }
                    alt=""
                  />
                  <Typography
                    color={"#2f2f2f"}
                    fontSize={"16px"}
                    fontFamily={"mySecondFont"}
                  >
                    {item?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "24px" }}
                >
                  <Box
                    sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                  >
                    <span
                      style={{ color: "#666666", fontWeight: "200" }}
                      className="material-symbols-outlined"
                    >
                      bolt
                    </span>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"myThirdFont"}
                      fontSize={"14px"}
                    >
                      {item?.energyConsumed} kWh
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                  >
                    <span
                      style={{ color: "#666666", fontWeight: "200" }}
                      className="material-symbols-outlined"
                    >
                      currency_rupee_circle
                    </span>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"myThirdFont"}
                      fontSize={"14px"}
                    >
                      ₹{item?.chargingAmount}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                  >
                    <span
                      style={{ color: "#666666", fontWeight: "200" }}
                      className="material-symbols-outlined"
                    >
                      ev_station
                    </span>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"myThirdFont"}
                      fontSize={"14px"}
                    >
                      {item?.sessionCount}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TopPerformStationBox;
