import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../custom/CustomTable";
import TableFilters from "../charging-sessions/TableFilters";

const SessionsBox = ({ columns, rows, isRowClickable }) => {
  const [filteredCpSessions, setFilteredCpSessions] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  console.log(rows);

  useEffect(() => {
    if (rows.length > 0) {
      setFilteredCpSessions(rows);
    }
  }, [rows]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredCpSessions(filteredData);
  };

  const myFilters = [
    {
      value: "deviceId",
      title: "Device ID",
    },
    {
      value: "_id",
      title: "Session ID",
    },
    {
      value: "energyConsumed",
      title: "Energy Consumed",
    },
    {
      value: "chargedAmount",
      title: "Amount charged",
    },
    {
      value: "from",
      title: "Mode",
    },

    {
      value: "sessionStart",
      title: "Sort by Start Date",
    },
    {
      value: "sessionStop",
      title: "Sort by Stop Date",
    },
  ];

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          data={rows}
          onFilterChange={handleFilterChange}
          filters={myFilters}
        />
      </Box>

      <CustomTable
        columns={columns}
        rows={filteredCpSessions}
        isRowClickable={isRowClickable}
        clickableColumns={["_id"]}
        columnClickPaths={{
          _id: "/charging_sessions",
        }}
        columnKeyMappings={{
          _id: "_id",
        }}
        modalHeading={"Sessions"}
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default SessionsBox;
