import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";
import {
  formatDateTime,
  formatMillisecondsToHHMMSS,
} from "../../../utils/FormatDateTime";

const InfoBox = ({ label, value, icon }) => {
  const [date, time] = value.split(" (");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
        textTransform={"capitalize"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: icon ? "space-between" : "start",
          alignItems: "start",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
          textAlign={"start"}
        >
          {date}
        </Typography>
        {time && (
          <Typography
            sx={{
              color: "#666",
              fontFamily: "myThirdFont",
              fontSize: "12px",
              lineHeight: "16px",
            }}
            textAlign={"start"}
          >
            {time.slice(0, -1)}
          </Typography>
        )}
        {icon && (
          <span
            style={{
              fontSize: "24px",
              color: "#292D33",
              fontWeight: "200",
              cursor: "pointer",
            }}
            className="material-symbols-outlined"
          >
            {icon}
          </span>
        )}
      </Box>
    </Box>
  );
};

const OtherDetails = ({ data }) => {
  const createdAt = data?.createdAt ? formatDateTime(data.createdAt) : {};
  const updatedAt = data?.updatedAt ? formatDateTime(data.updatedAt) : {};
  const createdBy = data?.createdBy || {}; // Access creator data

  // Format opening and closing times
  const openingTimeFormatted = data?.openingTime
    ? new Date(+data.openingTime).toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // Use AM/PM notation
      })
    : "N/A";

  const closingTimeFormatted = data?.closingTime
    ? new Date(+data.closingTime).toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // Use AM/PM notation
      })
    : "N/A";

  const workingDaysString = data?.workingDays?.join(", ") || "Not specified";

  console.log("open", openingTimeFormatted, closingTimeFormatted);
  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            info
          </span>
          <Heading text={"Other Details"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* Other - Details */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <InfoBox
          label="Timings"
          value={`${openingTimeFormatted} - ${closingTimeFormatted}`}
          // value={`${openingTimeFormatted.date} ${openingTimeFormatted.time} - ${closingTimeFormatted.date} ${closingTimeFormatted.time}`}
        />
        <InfoBox label="Working days" value={workingDaysString} />
        <InfoBox label="Contact number" value={data?.contactNumber} />
        <InfoBox label="Created by" value={`${createdBy?.name || "N/A"} `} />
        <InfoBox
          label="Station Incharge"
          value={`${data?.stationIncharge || "N/A"} `}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <InfoBox label="Created At" value={createdAt.date} />
              <Typography
                fontSize={"12px"}
                fontFamily={"myThirdFont"}
                color={"#666"}
                textAlign={"start"}
              >
                {createdAt.time}
              </Typography>
            </Box>
            <Box sx={{ width: "50%" }}>
              <InfoBox label="Last modified" value={updatedAt.date} />
              <Typography
                fontSize={"12px"}
                fontFamily={"myThirdFont"}
                color={"#666"}
                textAlign={"start"}
              >
                {updatedAt.time}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OtherDetails;
