import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Initial state
const initialState = {
  orgDoc: null,
  org: [],
  orgs: [],
  overviewData: null, // Add this field to the initial state
  loading: false,
  error: null,
  chargingStations: [], // Add this field to the initial state
  cmStatsOverviewData: null, // Added for storing CM stats overview data
};

// Async thunk for creating an organization document
export const createOrgDoc = createAsyncThunk(
  "companyManagement/createOrgDoc",
  async (orgDocData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(endPoints.createOrgDoc, orgDocData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for creating an organization
export const createOrg = createAsyncThunk(
  "companyManagement/createOrg",
  async (orgData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(endPoints.createOrg, orgData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching all organizations
export const getAllOrg = createAsyncThunk(
  "companyManagement/getAllOrg",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAllOrg, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for deleting an organization
export const deleteOrg = createAsyncThunk(
  "companyManagement/deleteOrg",
  async (orgId, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${endPoints.deleteOrg}/${orgId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching a single organization by ID
export const getOneOrg = createAsyncThunk(
  "companyManagement/getOneOrg",
  async (orgId, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endPoints.getOneOrg}/${orgId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getOverviewData = createAsyncThunk(
  "companyManagement/getOverviewData",
  async (orgId, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endPoints.overviewData}/${orgId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Adjust according to the actual response structure
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateOrg = createAsyncThunk(
  "companyManagement/updateOrg",
  async ({ id, payload }, thunkAPI) => {
    try {
      console.log("payloadokk", payload);
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${endPoints.updateOrg}/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//Added for CM - Charging Points
// Async thunk for fetching charging stations by organization ID
export const getCMChargingStation = createAsyncThunk(
  "companyManagement/getCMChargingStation",
  async (orgId, thunkAPI) => {
    // Accept orgId as an argument
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getCMChargingStation}/${orgId}`,
        {
          // Pass the orgId in the API call
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data; // Adjust according to the actual response structure
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
// Async thunk for fetching CM stats overview data
export const getCmStatsOverviewData = createAsyncThunk(
  "companyManagement/getCmStatsOverviewData",
  async ({ orgId, durationType, startTime, endTime }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.cmOverviewData}/${orgId}?durationType=${durationType}&startTime=${startTime}&endTime=${endTime}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Adjust according to the actual response structure
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Slice
const companyManagementSlice = createSlice({
  name: "companyManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrgDoc.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrgDoc.fulfilled, (state, action) => {
        state.loading = false;
        state.orgDoc = action.payload;
      })
      .addCase(createOrgDoc.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createOrg.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrg.fulfilled, (state, action) => {
        state.loading = false;
        state.org = action.payload;
      })
      .addCase(createOrg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllOrg.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllOrg.fulfilled, (state, action) => {
        state.loading = false;
        state.orgs = action.payload;
      })
      .addCase(getAllOrg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteOrg.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteOrg.fulfilled, (state, action) => {
        state.loading = false;
        state.orgs = state.orgs.filter((org) => org._id !== action.meta.arg);
      })
      .addCase(deleteOrg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getOneOrg.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneOrg.fulfilled, (state, action) => {
        state.loading = false;
        state.org = action.payload;
      })
      .addCase(getOneOrg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getOverviewData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOverviewData.fulfilled, (state, action) => {
        state.loading = false;
        state.overviewData = action.payload; // Store overview data
      })
      .addCase(getOverviewData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateOrg.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOrg.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.orgs.findIndex(
          (org) => org._id === action.payload._id
        );
        if (index !== -1) {
          state.orgs[index] = action.payload;
        } else {
          console.warn(`Org with id ${action.payload._id} not found`);
        }
      })
      .addCase(updateOrg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Added for CM - Charging Points
      .addCase(getCMChargingStation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCMChargingStation.fulfilled, (state, action) => {
        console.log("Reducer Payload:", action.payload); // See what is being passed to the reducer
        state.chargingStations = action.payload; // This should match the structure of the data you need
      })
      .addCase(getCMChargingStation.rejected, (state, action) => {
        console.error("Error Fetching Charging Stations:", action.payload); // Log any errors
        state.loading = false;
        state.error = action.payload;
      })
      //CM Overview Stats
      .addCase(getCmStatsOverviewData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCmStatsOverviewData.fulfilled, (state, action) => {
        state.loading = false;
        state.cmStatsOverviewData = action.payload; // Store CM stats overview data
      })
      .addCase(getCmStatsOverviewData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default companyManagementSlice.reducer;
