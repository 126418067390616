// import { Box, Button, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import TableFilters from "../charging-sessions/TableFilters";
// import CustomTable from "../custom/CustomTable";
// import {
//   adminManagementColumns,
//   adminManagementData,
//   TariffManagementColumns,
//   TariffManagementData,
// } from "../../utils/DummyData";
// import DeleteModal from "../Modals/DeleteModal";
// import AddNewUserModal from "../Modals/AddNewUserModal";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchAllUsers } from "../../redux/action/adminManagement/adminSlice";

// const TableBox = () => {
//   const dispatch = useDispatch();
//   const { users, loading, error } = useSelector((state) => state.admin);
//   const [filteredStations, setFilteredStations] = useState([]);

//   // Fetch users on component mount
//   useEffect(() => {
//     dispatch(fetchAllUsers());
//   }, [dispatch]);

//   //Add User Modal
//   const [openNewUserModal, setOpenNewUserModal] = useState(false);

//   const handleOpenAddNewUserModal = (e) => {
//     e.stopPropagation();
//     setOpenNewUserModal(true);
//   };
//   const handleCloseAddNewUserModal = (e) => {
//     e?.stopPropagation();
//     setOpenNewUserModal(false);
//   };

//   //filters
//   useEffect(() => {
//     if (users.length > 0) {
//       setFilteredStations(users);
//     }
//   }, [users]);

//   const handleFilterChange = (filteredData) => {
//     setFilteredStations(filteredData);
//   };

//   const myFilters = [
//     {
//       value: "name",
//       title: "Name",
//     },
//     {
//       value: "org",
//       title: "Organization",
//     },
//     {
//       value: "email",
//       title: "Email Id",
//     },
//     {
//       value: "phoneNumber",
//       title: "Contact Number",
//     },
//     {
//       value: "role",
//       title: "User Role",
//     },
//   ];

//   return (
//     <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff" }}>
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <TableFilters           data={users}
//           onFilterChange={handleFilterChange}
//           filters={myFilters}/>
//         <Button
//           sx={{
//             background: "#222",
//             padding: "8px 24px",
//             width: "142px",
//             borderRadius: "4px",
//             color: "#FCFCFC",
//             fontFamily: "mySecondFont",
//             letterSpacing: "1.12px",
//             ":hover": {
//               background: "#222",
//             },
//           }}
//           onClick={handleOpenAddNewUserModal}
//         >
//           + Add New
//         </Button>
//       </Box>

//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "flex-end",
//           gap: "16px",
//           mt: "12px",
//         }}
//       >
//         <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
//           <Box
//             sx={{
//               width: "8px",
//               height: "8px",
//               borderRadius: "8px",
//               background: "#027900",
//             }}
//           ></Box>
//           <Typography fontSize={"14px"} fontFamily={"myThirdFont"}>
//             Active
//           </Typography>
//         </Box>
//         <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
//           <Box
//             sx={{
//               width: "8px",
//               height: "8px",
//               borderRadius: "8px",
//               background: "#790000",
//             }}
//           ></Box>
//           <Typography fontSize={"14px"} fontFamily={"myThirdFont"}>
//             Deactivated
//           </Typography>
//         </Box>
//       </Box>

//       {/* Table */}
//       <CustomTable
//         columns={adminManagementColumns}
//         rows={filteredStations}
//         actionType={"view-edit-delete"}
//         specialColumns={["name"]}
//         modalHeading={"Admin Management"}
//       />

//       <AddNewUserModal
//         open={openNewUserModal}
//         handleClose={handleCloseAddNewUserModal}
//         heading={"Add New User"}
//       />
//     </Box>
//   );
// };

// export default TableBox;

import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import {
  adminManagementColumns,
  adminManagementData,
  TariffManagementColumns,
  TariffManagementData,
} from "../../utils/DummyData";
import DeleteModal from "../Modals/DeleteModal";
import AddNewUserModal from "../Modals/AddNewUserModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers } from "../../redux/action/adminManagement/adminSlice";

const TableBox = () => {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.admin);
  const [filteredStations, setFilteredStations] = useState([]);
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);

  // Fetch users on component mount
  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  // Add User Modal
  const handleOpenAddNewUserModal = () => {
    setOpenNewUserModal(true);
  };

  const handleCloseAddNewUserModal = () => {
    setOpenNewUserModal(false);
    dispatch(fetchAllUsers()); // Fetch users again to reflect changes
  };

  // Filters
  useEffect(() => {
    if (users.length > 0) {
      setFilteredStations(users);
    }
  }, [users]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredStations(filteredData);
  };

  const myFilters = [
    { value: "name", title: "Name" },
    { value: "org", title: "Organization" },
    { value: "email", title: "Email Id" },
    { value: "phoneNumber", title: "Contact Number" },
    { value: "role", title: "User Role" },
  ];

  return (
    <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          data={users}
          onFilterChange={handleFilterChange}
          filters={myFilters}
        />
        <Button
          sx={{
            background: "#222",
            padding: "8px 24px",
            width: "142px",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontFamily: "mySecondFont",
            letterSpacing: "1.12px",
            ":hover": {
              background: "#222",
            },
          }}
          onClick={handleOpenAddNewUserModal}
        >
          + Add New
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "16px",
          mt: "12px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "8px",
              background: "#027900",
            }}
          ></Box>
          <Typography fontSize={"14px"} fontFamily={"myThirdFont"}>
            Active
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "8px",
              background: "#790000",
            }}
          ></Box>
          <Typography fontSize={"14px"} fontFamily={"myThirdFont"}>
            Deactivated
          </Typography>
        </Box>
      </Box>

      {/* Table */}
      <CustomTable
        columns={adminManagementColumns}
        rows={filteredStations}
        actionType={"view-edit-delete"}
        specialColumns={["name"]}
        modalHeading={"Admin Management"}
        sortColumn={sortColumn}
      />

      {openNewUserModal ? (
        <AddNewUserModal
          open={openNewUserModal}
          handleClose={handleCloseAddNewUserModal}
          heading={"Add New User"}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default TableBox;
