import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
// import TableBox from "./TableBox";
import OverviewBox from "./OverviewBox";
import TimeFilter from "../Filters/TimeFilter";

import StatusChip from "../Chips/StatusChip";
import DetailsBox from "./DetailsBox";
import OcpiChargerBox from "./OcpiChargerBox";
import {
  ocpiDetailsChargerColumns,
  ocpiDetailsChargerRowData,
  sessionDetailsSessionsColumns,
  sessionDetailsSessionsData,
} from "../../utils/DummyData";
import ManageBox from "./ManageBox";
import UnregisteredManageBox from "./UnregisteredManageBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../redux/action/dashboard/dashboardSlice";

// for tabs icons
const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [registered, setRegistered] = useState(false);
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  const dispatch = useDispatch();
  const { data, customers, status, error } = useSelector(
    (state) => state.dashboard
  );

  // UseEffect for Time Filter to get the data
  useEffect(() => {
    if (durationType && startTime && endTime) {
      dispatch(
        fetchDashboardData({
          durationType,
          startTime,
          endTime,
        })
      );
    }
  }, [durationType, startTime, endTime, dispatch]);

  const handleRegister = () => {
    setRegistered(true);
  };

  const tabsData = [
    {
      label: "Overview",
      content: (
        <Box>
          <OverviewBox />
        </Box>
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      label: "Details",
      content: (
        <Box>
          <DetailsBox />
        </Box>
      ),
      icon: <CustomIcon icon={"info"} />,
    },
    {
      label: "Manage",
      content: (
        <Box>
          {registered ? (
            // <DetailsBox />
            <ManageBox />
          ) : (
            <UnregisteredManageBox onRegister={handleRegister} />
            // <p>Hello</p>
            // <RegisterBox onRegister={handleRegister} />
          )}
          {/* <ManageBox /> */}
          {/* <SessionsBox
            columns={sessionDetailsSessionsColumns}
            rows={sessionDetailsSessionsData}
            isRowClickable={true}
          /> */}
        </Box>
      ),
      icon: <CustomIcon icon={"battery_charging_50"} />,
    },
    {
      label: "OCPI Chargers",
      content: (
        <Box>
          <OcpiChargerBox
            columns={ocpiDetailsChargerColumns}
            rows={ocpiDetailsChargerRowData}
            isRowClickable={true}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Box
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "32px",
                backgroundImage: "url('/images/tata-logo.png')",
                backgroundSize: "cover",
              }}
            ></Box>
            <MainHeading text={"Tata Power Corporation"} />
            {/* <StatusChip text={"Available"} /> */}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              sx={{
                padding: "8px 16px",
                borderRadius: "4px",
                border: "1px solid #E50000",
                maxWidth: "175px",
                width: "100%",
                color: "#E50000",
                fontWeight: "600",
                letterSpacing: "1.12px",
              }}
            >
              <span
                style={{
                  paddingRight: "8px",
                  fontSize: "24px",
                  fontWeight: "200",
                }}
                className="material-symbols-outlined"
              >
                edit
              </span>
              EDIT DETAILS
            </Button>
            <Button
              sx={{
                padding: "8px",
                border: "1px solid #c0c0c0",
                borderRadius: "4px",
                minWidth: "unset",
              }}
            >
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "200",
                  color: "#790000",
                }}
                className="material-symbols-outlined"
              >
                delete
              </span>
            </Button>
          </Box>
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;

// import React, { useState } from "react";

// // RegisterBox Component
// const RegisterBox = ({ onRegister }) => {
//   return (
//     <div>
//       <h2>Register Box</h2>
//       <button onClick={onRegister}>Register</button>
//     </div>
//   );
// };

// // DetailsBox Component
// const DetailsBox = () => {
//   return (
//     <div>
//       <h2>Details Box</h2>
//       <p>This is the details view.</p>
//     </div>
//   );
// };

// // Parent Component
// const ParentComponent = () => {
//   const [registered, setRegistered] = useState(false);

//   const handleRegister = () => {
//     setRegistered(true);
//   };

//   return (
//     <div>
//       {registered ? (
//         <DetailsBox />
//       ) : (
//         <RegisterBox onRegister={handleRegister} />
//       )}
//     </div>
//   );
// };

// export default ParentComponent;
