import React, { useEffect } from "react";
import { useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import { Box, Button, Typography } from "@mui/material";
import Heading from "../../utils/Heading";
import DetailsBox from "./DetailsBox";
import LogsBox from "./LogsBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneSession, generateInvoice } from "../../redux/action/sessions/sessionsSlice";
import Breadcrumbs from "../breadcrumbs/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../Hooks/useScrollToTop";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const navigate = useNavigate();
  useScrollToTop(navigate);
  // Redux hooks
  const dispatch = useDispatch();
  const { sessionDetail, sessionLogs, loading, error } = useSelector(
    (state) => state.sessions
  );

  useEffect(() => {
    // Extract organization ID from URL
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    setSessionId(id);
  }, []);

  useEffect(() => {
    // if (sessionId) {
    //   dispatch(fetchOneSession(sessionId));
    // }

    const fetchData = async () => {
      if (sessionId) {
        try {
          // Wait for generateInvoice to complete before calling fetchOneSession
          await dispatch(generateInvoice(sessionId));
          await dispatch(fetchOneSession(sessionId));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
 
    fetchData();
  }, [dispatch, sessionId]);

  const tabsData = [
    {
      label: "Details",
      content: <DetailsBox data={sessionDetail} />,
    },
    {
      label: "Logs",
      content: <LogsBox sessionDetail={sessionDetail} />,
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
            flexDirection: "column",
          }}
        >
          <Box sx={{ marginBottom: "24px" }}>
            <Breadcrumbs />
          </Box>

          <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <Heading text={"Session ID: "} />
            <MainHeading text={sessionDetail?._id} />
          </Box>
          {/* <Button
            sx={{
              padding: "8px 24px",
              borderRadius: "4px",
              border: "1px solid #e50000",
              fontSize: "16px",
              fontFamily: "mySecondFont",
              textTransform: "unset",
              color: "#e50000",
            }}
          >
            <span
              style={{ paddingRight: "8px" }}
              className="material-symbols-outlined"
            >
              print
            </span>{" "}
            Print
          </Button> */}
        </Box>
        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
