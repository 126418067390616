import { Box } from "@mui/material";
import React from "react";
import ValueCard from "./ValueCard";
import ValueCardSmall from "./ValueCardSmall";

const ValueCardBox = ({
  data,
  customers,
  previousDashboardData,
  selectedTime,
  customerData,
  previousCustomerData,
  cardTypeProp,
  walletData, previousWalletData
}) => {
  console.log("33333333", previousCustomerData, customerData);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        flexGrow: 1,
        height:"100%"
      }}
    >
      <ValueCard
        title={"Total uptime (hrs)"}
        data={data}
        previousDashboardData={previousDashboardData}
        selectedTime={selectedTime}
        cardTypeProp={cardTypeProp}
      />
      <ValueCard
        title={"New customers"}
        previousCustomersData={previousCustomerData}
        selectedTime={selectedTime}
        customers={customerData}
        cardTypeProp={cardTypeProp}
      />
      <ValueCard
        // title={"Wallet Recharge (INR)"}
        // previousCustomersData={previousCustomerData}
        // selectedTime={selectedTime}
        // customers={customerData}
        // cardTypeProp={cardTypeProp}

        data={walletData}
        previousDashboardData={previousWalletData}
        title={"Wallet Recharge (INR)"}
        selectedTime={selectedTime}
        cardTypeProp={cardTypeProp}
      />
    </Box>
  );
};

export default ValueCardBox;
