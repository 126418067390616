import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";

const OverviewTopBox = () => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={12} xs={12}>
          <Box>
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "center",
                marginBottom: "24px",
              }}
            >
              <Select
                value={selectedValue}
                onChange={handleChange}
                displayEmpty
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "left",
                  //   padding: "8px 16px",
                  width: "232px",
                  height: "40px",
                  color: "#1C1C1C",
                  fontFamily: "myThirdFont",
                }}
              >
                <MenuItem value="" disabled>
                  All Networks
                </MenuItem>
                <MenuItem value="option1">Option 1</MenuItem>
                <MenuItem value="option2">Option 2</MenuItem>
                <MenuItem value="option3">Option 3</MenuItem>
              </Select>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "center",
                marginBottom: "24px",
              }}
            >Overview Top Box
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewTopBox;
