import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom"; // useNavigate for programmatic navigation

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabs = ({ tabs, onTabChange }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // State for current tab
  const [value, setValue] = React.useState(0); // Default to the first tab

  // Update the state when URL changes
  React.useEffect(() => {
    // Reset to the first tab every time the component mounts
    setValue(0);
  }, [location.pathname]); // Runs when the pathname changes

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${location.pathname}?tab=${newValue}`); // Update the URL when a new tab is selected
    if (onTabChange) {
      onTabChange(event, newValue);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            minHeight: "unset", // Reset min height for tabs
            "& .MuiTabs-indicator": {
              backgroundColor: "#e50000 !important", // Red indicator for selected tab
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              sx={{
                color: "#2F2F2F",
                fontFamily: "mySecondFont",
                fontSize: "16px",
                minHeight: "unset",
                flexDirection: "row", // Always align icon and label in a row
                display: "flex",
                alignItems: "center",
                gap: "8px", // Adjust the gap between the icon and label
                "& .MuiTab-wrapper": {
                  flexDirection: "row", // Force row alignment for both selected and unselected
                },
                "&.Mui-selected": {
                  color: "#2F2F2F", // Keep the same color for the selected tab
                },
              }}
              icon={tab?.icon}
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

CustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
  onTabChange: PropTypes.func,
};

export default CustomTabs;
