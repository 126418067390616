const BASE_URL = "https://backend.1charging.com";
// const BASE_URL = "http://localhost:3000";
//Chirag
// const BASE_URL = "http://10.5.51.161:3000";

export const endPoints = {
  // Auth - Api
  login: BASE_URL + "/auth/login",
  getProfile: BASE_URL + "/users/me",
  sendOTP: BASE_URL + "/auth/send-otp",
  verfyOTP: BASE_URL + "/auth/verify-otp",
  resendOTP: BASE_URL + "/auth/resend-otp",
  forgetPassword: BASE_URL + "/users/forgotPassword",

  // tariff - Api
  createTariff: BASE_URL + "/tariff/create",
  getTariff: BASE_URL + "/tariff/getAll",
  deleteTariff: BASE_URL + "/tariff/deleteOne",
  updateTariff: BASE_URL + "/tariff/updateOne",

  // admin management - api
  adminRoles: BASE_URL + "/roles/getRolesByUsedId",
  adminOrgs: BASE_URL + "/orgs/getAll",
  createUser: BASE_URL + "/users/create",
  updateUser: BASE_URL + "/users/updateOne",

  // company management - api
  createOrg: BASE_URL + "/orgs/create",
  getAllOrg: BASE_URL + "/orgs/getAll",
  getOneOrg: BASE_URL + "/orgs/getOne",
  updateOrg: BASE_URL + "/orgs/updateOne",
  deleteOrg: BASE_URL + "/orgs/deleteOne",
  createOrgDoc: BASE_URL + "/orgDocs/create",
  allUsers: BASE_URL + "/users/getAll",
  oneUser: BASE_URL + "/users/getOne",
  deleteUser: BASE_URL + "/users/deleteOne",
  overviewData: BASE_URL + "/orgs/stats",
  getCMChargingStation: BASE_URL + "/orgs/stations",
  cmOverviewData: BASE_URL + "/orgs/stats",

  // stations api
  getAllStations: BASE_URL + "/stations/getAll",
  getOneStationDetail: BASE_URL + "/stations/getOne",
  deleteOneStation: BASE_URL + "/stations/deleteOne",
  updateOneStation: BASE_URL + "/stations/updateOne",
  getOneStationOverview: BASE_URL + "/stations/stats",
  getStationChargingPoint: BASE_URL + "/stations/chargingPoints",
  createStation: BASE_URL + "/stations/create",
  createStationImg: BASE_URL + "/stationImgs/create",
  deleteOneStationImg: BASE_URL + "/stationImgs/deleteOne",
  updateOneStationImg: BASE_URL + "/stationImgs/updateOne",
  getStationSessions: BASE_URL + "/stations/chargingSessions",
  getOneReviews: BASE_URL + "/reviews/getAll",
  getOneStationOneRating: BASE_URL + "/reviews/getOne",
  getQrByStation: BASE_URL + "/qr-code/getQrCodeByStationId",
  getLiveStationPointStatus: BASE_URL + "/stations/chargingPointsCount",
  getNearbyStations: BASE_URL + "/stations/getNearByStaions",

  // chargingPoint
  createChargingPoint: BASE_URL + "/charging-point/create",
  getChargingPoint: BASE_URL + "/charging-point/getAll",
  deleteChargingPoint: BASE_URL + "/charging-point/deleteOne",
  updateChargingPoint: BASE_URL + "/charging-point/updateOne",
  getOneChargingPoint: BASE_URL + "/charging-point/getOne",
  getChargingPointConnectors: BASE_URL + "/charging-point/connectors",
  // startChargingPoint: BASE_URL + "/api/charging/start",
  connectorUpdate: BASE_URL + "/connectors/updateOne",
  getOneSessionData: BASE_URL + "/charging-point/sessions",
  getOneChargingPointLogs: BASE_URL + "/device-logs/getAllDeviceLogs",
  getChargingPointQr: BASE_URL + "/qr-code/getQrCodeByDeviceId",
  cpOverviewData: BASE_URL + "/charging-point/stats",
  createConnector: BASE_URL + "/connectors/create",
  deleteConnector: BASE_URL + "/connectors/deleteOne",
  getOneStatus: BASE_URL + "/charging-point/getOneStatus",
  createQRCode: BASE_URL + "/qr-code/create",
  getConnectorQr: BASE_URL + "/qr-code",
  reviewDelete: BASE_URL + "/reviews/deleteOne",
  getLiveLogs: BASE_URL + "/device-logs/getAllLiveDeviceLogs",
  // connector Api
  startChargingConnector: BASE_URL + "/charging/start",
  stopChargingConnector: BASE_URL + "/charging/stop",
  // configuration - API
  changeConfiguration: BASE_URL + "/device/change/config",
  changeAvailabilityConfiguration: BASE_URL + "/device/change/availablity",
  configurationReset: BASE_URL + "/device/reset",
  configurationMsgTrigger: BASE_URL + "/device/message/trigger",
  configurationUnlockConnector: BASE_URL + "/device/unlock/connector",
  configurationFirmware: BASE_URL + "/device/update/firmware",
  configurationGetDiagnostic: BASE_URL + "/device/getdiagnostic",
  configurationStopCharging: BASE_URL + "/device/stop/charging",

  // sessions api
  getAllSessions: BASE_URL + "/chargingSessions/getAll",
  getOneSession: BASE_URL + "/chargingSessions/getOne",
  getOneSessionLogs: BASE_URL + "/device-logs/getAllSessionLogs",
  overViewData: BASE_URL + "/stations/stats",
  invoiceGenerate: BASE_URL + "/api/invoice-generation",

  //statistis
  getOrgStats: BASE_URL + "/statistics/orgs",
  getChargerStats: BASE_URL + "/statistics/devices",
  getStationStats: BASE_URL + "/statistics/stations",
  getWalletDetails: BASE_URL + "/statistics/wallet",

  // dashboard -api
  getDashboardData: BASE_URL + "/chargingSessions/stats",
  getAllCustomers: BASE_URL + "/users/getAllCustomers",
  getLiveDeviceStatus: BASE_URL + "/charging-point/getChargingPointCounts",

  // payments - api
  getAllPayments: BASE_URL + "/payments/getAll",

  // finance - manager api
  getAllFinanceStation: BASE_URL + "/stations/finance",
  financeAmountAdd: BASE_URL + "/finance/wallet/add",
  allWalletHistory: BASE_URL + "/finance/wallet",
  getOneStationBalance: BASE_URL + "/finance/wallet/balance",
  getOneStationPaymentHistory: BASE_URL + "/finance/wallet/history",
  getDailySheet: BASE_URL + "/finance/wallet/history/passbook",
};
