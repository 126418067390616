import Dashboard from "../pages/Dashboard/Dashboard";
import ChargingSessions from "../pages/Charging-Sessions/ChargingSessions";
import ChargingStations from "../pages/Charging-Stations/ChargingStations";
import ChargingStationDetails from "../pages/ChargingStationDetails/ChargingStationDetails";
import AddNewChargingStation from "../pages/AddNewChargingStation/AddNewChargingStation";
import ChargingPoints from "../pages/ChargingPoints/ChargingPoints";
import ChargingPointsDetails from "../pages/ChargingPointsDetails/ChargingPointsDetails";
import ChargingSessionsDetails from "../pages/ChargingSessionsDetails/ChargingSessionsDetails";
import ChargingStationsMap from "../pages/ChargingStationsMap/ChargingStationsMap";
import TariffManagement from "../pages/TariffManagement/TariffManagement";
import CompanyManagement from "../pages/CompanyManagement/CompanyManagement";
import AddNewCompany from "../pages/AddNewCompany/AddNewCompany";
import CompanyManagementDetails from "../pages/CompanyManagementDetails/CompanyManagementDetails";
import Payment from "../pages/Payment/Payment";
import AdminManagement from "../pages/AdminManagement/AdminManagement";
import Login from "../pages/Login/Login";
import Statistics from "../pages/Statistics/Statistics";
import CPODashboard from "../pages/CPODashboard/CPODashboard";
import RoleBasedRoute from "./RoleBasedRoute";
import VerifyEmail from "../pages/AuthPages/VerifyEmail/VerifyEmail";
import ResetPassword from "../pages/AuthPages/ResetPassword/ResetPassword";
import CreatePassword from "../pages/AuthPages/CreatePassword/CreatePassword";
import AddNewChargingPoint from "../pages/AddNewChargingPoint/AddNewChargingPoint";
import OcpiManagement from "../pages/OcpiManagement/OcpiManagement";
import OcpiManagementDetails from "../pages/OcpiManagementDetails/OcpiManagementDetails";
import Home from "../pages/FinanceManager/Home/Home";
import PaymentHistory from "../pages/FinanceManager/PaymentHistory/PaymentHistory";
import { Navigate } from "react-router-dom";
import RfidManagement from "../pages/RFID/RfidManagement";

export const allRoutes = [
  {
    path: "/",
    element: <RoleBasedRoute element={Dashboard} />,
    exact: true,
  },
  {
    path: "/cpo_dashboard",
    element: <CPODashboard />,
    exact: true,
  },
  {
    path: "/charging_sessions",
    element: <ChargingSessions />,
    exact: true,
  },
  {
    path: "/charging_sessions/:id",
    element: <ChargingSessionsDetails />,
    exact: true,
  },
  {
    path: "/charging_stations",
    element: <ChargingStations />,
    exact: true,
  },
  {
    path: "/charging_stations/:id",
    element: <ChargingStationDetails />,
    exact: true,
  },
  {
    path: "/add_new_charging_station",
    element: <AddNewChargingStation />,
    exact: true,
  },
  {
    path: "/add_new_charging_point",
    element: <AddNewChargingPoint />,
    exact: true,
  },
  {
    path: "/charging_points",
    element: <ChargingPoints />,
    exact: true,
  },
  {
    path: "/charging_points/:id",
    element: <ChargingPointsDetails />,
    exact: true,
  },
  {
    path: "/rfid_management",
    element: <RfidManagement />,
    exact: true,
  },
  {
    path: "/stations_map",
    element: <ChargingStationsMap />,
    exact: true,
  },
  {
    path: "/tariff_management",
    element: <TariffManagement />,
    exact: true,
  },

  {
    path: "/companies_management",
    element: <CompanyManagement />,
    exact: true,
  },
  {
    path: "/add_new_company",
    element: <AddNewCompany />,
    exact: true,
  },
  {
    path: "/companies_management/:id",
    element: <CompanyManagementDetails />,
    exact: true,
  },
  {
    path: "/payment",
    element: <Payment />,
    exact: true,
  },
  {
    path: "/admin_management",
    element: <AdminManagement />,
    exact: true,
  },
  {
    path: "/login",
    element: <Navigate to="/" />,
  },
  {
    path: "/statistics",
    element: <Statistics />,
    exact: true,
  },
  {
    path: "/verify_email",
    element: <VerifyEmail />,
    exact: true,
  },
  {
    path: "/reset_password",
    element: <ResetPassword />,
    exact: true,
  },
  {
    path: "/create_new_password",
    element: <CreatePassword />,
    exact: true,
  },

  {
    path: "/ocpi_management",
    element: <OcpiManagement />,
    exact: true,
  },
  {
    path: "ocpi_management_details/:id",
    element: <OcpiManagementDetails />,
    exact: true,
  },
  {
    path: "/home",
    element: <Home />,
    exact: true,
  },
  {
    path: "/payment_history",
    element: <PaymentHistory />,
    exact: true,
  },
  //   {
  //     path: "/all_categories",
  //     element: <AllCategories />,
  //   },
  //   {
  //     path: "/manage_tags",
  //     element: <AddTags />,
  //   },
  //   {
  //     path: "/all_users",
  //     element: <AllUsers />,
  //   },
  //   {
  //     path: "/all_reviews",
  //     element: <AllReviews />,
  //   },

  {
    path: "*",
    element: "not found",
  },
];
