import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ChargingPointBox = ({
  columns,
  isRowClickable,
  data,
  stationDataGetOne,
  loading,
}) => {
  const navigate = useNavigate();
  const [filteredChPoints, setFilteredChPoints] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  console.log("datafff", stationDataGetOne);
  const { chargingPointDetail } = useSelector((state) => state.chargingPoints);

  const handlePageChange = () => {
    // navigate("/add_new_charging_point");
    navigate("/add_new_charging_point", {
      state: {
        CpStationData: {
          id: stationDataGetOne?._id,
          name: stationDataGetOne?.name,
        },
      },
    });
  };

  useEffect(() => {
    if (data.length > 0) {
      setFilteredChPoints(data);
    }
  }, [data]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredChPoints(filteredData);
  };

  const myFilters = [
    {
      value: "name",
      title: "Name",
    },
    {
      value: "deviceId",
      title: "Device ID",
    },
    {
      value: "status",
      title: "Status",
    },
    {
      value: "chargingPointType",
      title: "Charger Type",
    },
    {
      value: "createdAt",
      title: "Sort by Date",
    },
  ];
  if (!data) {
    return <Typography>No data available</Typography>;
  }

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          data={data}
          onFilterChange={handleFilterChange}
          filters={myFilters}
        />
        <Button
          sx={{
            background: "#222",
            padding: "8px 24px",
            width: "120px",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontFamily: "'Montserrat', sans-serif",
            textWrap: "nowrap",
            letterSpacing: "1.12px",
            ":hover": {
              background: "#222",
            },
          }}
          onClick={handlePageChange}
        >
          + ADD NEW
        </Button>
      </Box>

      <CustomTable
        columns={columns}
        rows={data}
        isRowClickable={isRowClickable}
        actionType={"copy-edit-delete"}
        rowClickPath={"/charging_stations"}
        clickableColumns={["name"]}
        modalHeading={"Charging Points"}
        loading={loading}
        columnClickPaths={{
          name: "/charging_points",
        }}
        columnKeyMappings={{
          name: "_id", // Use 'sessionID' key for 'sessionID' column
        }}
        statsHeading={"true"}
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default ChargingPointBox;
