import { Box, Divider, Button, ButtonOutline, Typography } from "@mui/material";
import React from "react";
import StatusChip from "../../Chips/StatusChip";
import Heading from "../../../utils/Heading";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import { deviceStatusData } from "../../../utils/DummyData";

const DeviceStatus = ({ data }) => {

  console.log("werwer", data.offlineCharingPoints);
  const deviceStatusData = [
    {
      id: 1,
      status: "online",
      noOfDevices: data?.onlineCharingPoints,
      // totalDeviceNo: "240",
      // activeSessionNo: "24",
      // cardStatus: "live",
      // ratingStatus: "good",
    },
    {
      id: 2,
      status: "faulted",
      noOfDevices: data?.faultedCharingPoints,
      // totalDeviceNo: "240",
      // activeSessionNo: "24",
      // cardStatus: "live",
      // ratingStatus: "ok",
    },
    {
      id: 3,
      status: "offline",
      noOfDevices: data?.offlineCharingPoints,
      // totalDeviceNo: "240",
      // activeSessionNo: "24",
      // cardStatus: "live",
      // ratingStatus: "bad",
    },
  ];

  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        width: "100%",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/deviceStatusLogo.png" alt="" />
            <Heading text={"Device status"} />
          </Box>
          <StatusChip text={"Live"} chipStatus={"live"} />
        </Box>

        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>

      <Box sx={{ margin: "12px 0" }}>
        <Typography
          fontFamily={"myThirdFont"}
          textTransform={"none"}
          fontSize={"16px"}
          fontWeight={"400"}
          lineHeight={"24px"}
          color={"#1C1C1C"}
        >
          Total Devices: {data?.totalCharingPoints}
        </Typography>
      </Box>

      {/*  session Button */}

      <Box>
        {deviceStatusData.map((item) => (
          <Box key={item.id} sx={{ marginBottom: 2 }}>
            <Button
              sx={{
                width: "100%",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
                gap: "12px",
                boxShadow: "0px 2px 4px 0px rgba(41, 45, 51, 0.25)",
                background:
                  item.status === "online"
                    ? "linear-gradient(0deg, rgba(2, 121, 0, 0.05) 0%, rgba(2, 121, 0, 0.05) 100%)"
                    : item.status === "offline"
                    ? " linear-gradient(0deg, rgba(121, 0, 0, 0.05) 0%, rgba(121, 0, 0, 0.05) 100%)"
                    : item.status === "faulted"
                    ? "linear-gradient(0deg, rgba(193, 116, 0, 0.05) 0%, rgba(193, 116, 0, 0.05) 100%)"
                    : null,
                "&:hover": {
                  backgroundImage:
                    "linear-gradient(0deg, rgba(2, 121, 0, 0.1) 0%, rgba(2, 121, 0, 0.1) 100%)",
                },
              }}
            >
              <BoltOutlinedIcon
                fontSize="medium"
                sx={{
                  color:
                    item.status === "online"
                      ? "#027900"
                      : item.status === "offline"
                      ? "#790000"
                      : item.status === "faulted"
                      ? "#C17400"
                      : null,
                }}
              />
              <Typography
                fontFamily={"Montserrat"}
                textTransform={"none"}
                fontSize={"18px"}
                fontWeight={"400"}
                lineHeight={"24px"}
                color={"#1C1C1C"}
                sx={{ marginLeft: "8px" }}
              >
                {item.status.charAt(0).toUpperCase() + item.status.slice(1)}:
              </Typography>
              <Typography
                fontFamily={"Montserrat"}
                textTransform={"none"}
                fontSize={"18px"}
                fontWeight={"600"}
                lineHeight={"24px"}
                color={
                  item.status === "online"
                    ? "#027900"
                    : item.status === "offline"
                    ? "#790000"
                    : item.status === "faulted"
                    ? "#C17400"
                    : null
                }
                sx={{ marginLeft: "4px" }}
              >
                {item.noOfDevices}
              </Typography>
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DeviceStatus;
