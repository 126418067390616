import {
  Box,
  Divider,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  Button,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import Heading from "../../utils/Heading";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import GalleryBox from "./GalleryBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminOrgs } from "../../redux/action/adminManagement/adminSlice";
import {
  createStation,
  fetchOneStationDetail,
  updateOneStation,
} from "../../redux/action/stations/stationSlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import countryList from "react-select-country-list";

const names = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const amenitiesList = [
  "Restaurant",
  "Hotel",
  "Mall",
  "Hospital",
  "Garage",
  "ATM",
  "Cafe",
];

const amenitiesIcons = [
  "restaurant",
  "hotel",
  "local_mall",
  "local_hospital",
  "build",
  "atm",
  "local_cafe",
];

// const InputField = ({
//   label,
//   placeholder,
//   name,
//   value,
//   onChange,
//   type = "text",
// }) => (

//   <Box
//     sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
//   >
//     <Typography
//       textAlign="start"
//       fontSize="14px"
//       color="2f2f2f"
//       fontFamily="myThirdFont"
//       outline="none"
//     >
//       {label}
//     </Typography>
//     <input
//       type={type}
//       name={name}
//       value={value || ""}
//       onChange={onChange}
//       style={{
//         padding: "12px 16px",
//         borderRadius: "4px",
//         border: "1px solid #cbcbcb",
//         color: "rgba(47, 47, 47, 0.50)",
//         fontFamily: "myThirdFont",
//         fontSize: "16px",
//         height: "48px",
//         outline: "none",
//       }}
//       placeholder={placeholder}
//     />
//   </Box>
// );

const InputField = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  type = "text",
}) => {
  // Handle input changes and filter out invalid characters
  const handleChange = (e) => {
    let newValue = e.target.value;

    // If type is "text", filter out numbers and symbols
    if (type === "text" && name === "state") {
      newValue = newValue.replace(/[^A-Za-z\s]/g, ""); // Only allow letters and spaces
    }

    // Call the onChange handler with the filtered value
    onChange({ target: { name, value: newValue } });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }}
    >
      <Typography
        textAlign="start"
        fontSize="14px"
        color="#2f2f2f"
        fontFamily="myThirdFont"
        outline="none"
      >
        {label}
      </Typography>
      <input
        type={type}
        name={name}
        value={value || ""}
        onChange={handleChange}
        style={{
          padding: "12px 16px",
          borderRadius: "4px",
          border: "1px solid #cbcbcb",
          color: "rgba(47, 47, 47, 0.50)",
          fontFamily: "myThirdFont",
          fontSize: "16px",
          height: "48px",
          outline: "none",
        }}
        placeholder={placeholder}
      />
    </Box>
  );
};

const SelectField = ({ label, name, value, handleChange, menuItems, disabled }) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
  >
    <Typography
      textAlign="start"
      fontSize="14px"
      color="2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <FormControl fullWidth>
      <InputLabel sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}>
        {label}
      </InputLabel>
      <Select
        name={name}
        value={value}
        label={label}
        disabled={disabled}
        onChange={handleChange}
        sx={{ height: "48px", fontFamily: "myThirdFont !important" }}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

const TextareaField = ({ label, placeholder, name, value, onChange }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
    <Typography
      textAlign="start"
      fontSize="14px"
      color="2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      style={{
        padding: "12px 16px",
        borderRadius: "4px",
        border: "1px solid #cbcbcb",
        color: "rgba(47, 47, 47, 0.50)",
        fontFamily: "myThirdFont",
        fontSize: "16px",
        height: "100px",
        outline: "none",
        resize: "none",
      }}
      placeholder={placeholder}
    />
  </Box>
);

const Section = ({ icon, title, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <span
          style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
        <Heading text={title} />
      </Box>
      <Divider sx={{ mt: "8px" }} />
    </Box>
    {children}
  </Box>
);

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddChargingStationForm = () => {
  const [organization, setOrganization] = useState("");
  const [status, setStatus] = useState("");
  const [gridPhase, setGridPhase] = useState("");
  const [country, setCountry] = useState("");
  const [openingTime, setOpenTime] = useState(dayjs());
  const [closingTime, setCloseTime] = useState(dayjs());
  const [workingDays, setWorkingDays] = useState([]);
  const [isOpen24Hours, setIsOpen24Hours] = useState(false);
  const [isAllDays, setIsAllDays] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [imgTags, setImgTags] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { orgs } = useSelector((state) => state.admin);
  const { stations } = useSelector((state) => state.stations);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const stationData = state?.stationData;

  const { CsOrgData } = location.state || {};
  const options = useMemo(
    () =>
      countryList()
        .getData()
        .map((option) => ({
          value: option.label, // Set value and label to be the same
          label: option.label,
        })),
    []
  );
  
  console.log("popopo", CsOrgData);

  console.log("jjjjj", orgs);
  console.log("Station Data:", stationData);

  const [formData, setFormData] = useState({
    name: "",
    org: "",
    status: "",
    powerCapacity: "",
    gridPhase: "",
    pinCode: "",
    city: "",
    state: "",
    country: "",
    latitude: 0,
    longitude: 0,
    fullAddress: "",
    openingTime: 0,
    closingTime: 0,
    workingDays: [],
    contactNumber: "",
    amenities: [],
    stationImgs: [],
    stationIncharge: "",
  });

  useEffect(() => {
    dispatch(fetchAdminOrgs());
  }, [dispatch]);

  // const formatTime = (time) => {
  //   return time === "NaN" ? null : dayjs(time);
  // };
  // Function to format time
  const formatTime = (timestamp) => {
    return timestamp ? dayjs(Number(timestamp)).format("HH:mm") : "";
  };

  useEffect(() => {
    if (stationData) {
      // Fetch station details if stationData is provided
      dispatch(fetchOneStationDetail(stationData))
        .unwrap()
        .then((data) => {
          console.log("Fetched station data:", data);
          // Populate form with fetched data
          setFormData({
            name: data.name,
            org: data.org,
            status: data.status,
            powerCapacity: data.powerCapacity,
            gridPhase: data.gridPhase,
            pinCode: data.pinCode,
            city: data.city,
            state: data.state,
            country: data.country,
            latitude: String(data.loc.coordinates[1]), // Convert latitude to string
            longitude: String(data.loc.coordinates[0]), // Convert longitude to string
            fullAddress: data.fullAddress,
            openingTime: formatTime(data.openingTime),
            closingTime: formatTime(data.closingTime),
            contactNumber: data.contactNumber,
            stationIncharge: data.stationIncharge,
            amenities: data.amenities,
            stationImgs: data.stationImgs.map((img) => ({
              id: img._id, // Changed _id to id for consistency
              imageTag: img.imageTag,
              order: img.order,
            })),
          });

          // // Setting times for TimePickers
          // setOpenTime(dayjs(data.openingTime));
          // setCloseTime(dayjs(data.closingTime));

          // Setting times for TimePickers
          setOpenTime(dayjs(Number(data.openingTime)));
          setCloseTime(dayjs(Number(data.closingTime)));

          // Handling working days
          if (data.workingDays.length === 7) {
            setIsAllDays(true);
          } else {
            setIsAllDays(false);
            setWorkingDays(data.workingDays);
          }

          // Setting amenities
          setSelectedAmenities(data.amenities);

          // Setting images
          setImages(data.stationImgs);
        })
        .catch((error) => {
          console.error("Error fetching station details:", error);
        });
    }
  }, [dispatch, stationData]);

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    console.log(event.target);
    // Determine the type of the input field and handle accordingly
    const newValue =
      type === "checkbox" ? checked : type === "number" ? value : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleWorkingDaysChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedDays = typeof value === "string" ? value.split(",") : value;
    setWorkingDays(selectedDays);
    setFormData({
      ...formData,
      workingDays: selectedDays,
    });
  };

  const handleIsAllDaysChange = (event) => {
    setIsAllDays(event.target.checked);
    if (event.target.checked) {
      setWorkingDays(names);
      setFormData({
        ...formData,
        workingDays: names,
      });
    } else {
      setWorkingDays([]);
      setFormData({
        ...formData,
        workingDays: [],
      });
    }
  };

  // const handleWorkingDaysChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   const selectedDays = typeof value === "string" ? value.split(",") : value;
  //   setWorkingDays(selectedDays);

  //   // If "All Days" checkbox is unchecked, update workingDays
  //   if (isAllDays) {
  //     setIsAllDays(false);
  //   }

  //   setFormData({
  //     ...formData,
  //     workingDays: selectedDays,
  //   });
  // };

  // const handleIsAllDaysChange = (event) => {
  //   const isChecked = event.target.checked;
  //   setIsAllDays(isChecked);

  //   if (isChecked) {
  //     setWorkingDays(names);
  //   } else {
  //     setWorkingDays([]); // Clear working days if "All Days" is unchecked
  //   }

  //   setFormData({
  //     ...formData,
  //     workingDays: isChecked ? names : [], // Set workingDays accordingly
  //   });
  // };

  const handleAmenitiesClick = (amenity) => {
    const updatedAmenities = selectedAmenities.includes(amenity)
      ? selectedAmenities.filter((item) => item !== amenity)
      : [...selectedAmenities, amenity];
    setSelectedAmenities(updatedAmenities);
    setFormData({
      ...formData,
      amenities: updatedAmenities,
    });
  };

  // const handleIsOpen24HoursChange = (event) => {
  //   setIsOpen24Hours(event.target.checked);
  //   if (event.target.checked) {
  //     setFormData({
  //       ...formData,
  //       openingTime: "00:00",
  //       closingTime: "23:59",
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       openingTime: openingTime.format("HH:mm"),
  //       closingTime: closingTime.format("HH:mm"),
  //     });
  //   }
  // };
  const handleIsOpen24HoursChange = (event) => {
    const isChecked = event.target.checked;
    setIsOpen24Hours(isChecked);

    if (isChecked) {
      // Set times to 24 hours mode
      const open24 = dayjs()
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0); // 12:00 AM
      const close24 = dayjs()
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 0)
        .set("millisecond", 0); // 11:59 PM

      setOpenTime(open24);
      setCloseTime(close24);

      setFormData({
        ...formData,
        openingTime: open24.format("HH:mm"),
        closingTime: close24.format("HH:mm"),
      });
    } else {
      // Use real-time values from the picker when unchecked
      const newOpeningTime = openingTime ? openingTime.format("HH:mm") : "";
      const newClosingTime = closingTime ? closingTime.format("HH:mm") : "";

      setFormData({
        ...formData,
        openingTime: newOpeningTime,
        closingTime: newClosingTime,
      });
    }
  };

  const handleOpenTimeChange = (newValue) => {
    console.log("New Value:", newValue);
    setOpenTime(newValue);
    if (!isOpen24Hours) {
      setFormData({
        ...formData,
        openingTime: newValue ? newValue.format("HH:mm") : "",
      });
    }
  };

  const handleCloseTimeChange = (newValue) => {
    setCloseTime(newValue);
    if (!isOpen24Hours) {
      setFormData({
        ...formData,
        closingTime: newValue ? newValue.format("HH:mm") : "",
      });
    }
  };

  const organizationMenuItems = orgs?.map((item) => ({
    value: item?._id,
    label: item?.name,
  }));

  const statusMenuItems = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "maintenance", label: "Maintenance" },
  ];

  const gridPhaseMenuItems = [
    { value: "single", label: "Single Phase" },
    { value: "three", label: "Three Phase" },
  ];

  const countryMenuItems = [
    { value: "india", label: "India" },
    { value: "australia", label: "Australia" },
    { value: "new-zealand", label: "New Zealand" },
    { value: "south-africa", label: "South Africa" },
    { value: "china", label: "China" },
  ];

  const imgTagsItem = [
    {
      value: "Entrance",
      label: "Entrance",
    },
    {
      value: "Parking",
      label: "Parking",
    },
    {
      value: "Landmark",
      label: "Landmark",
    },
    {
      value: "Charge point",
      label: "Charge point",
    },
    {
      value: "Amenities",
      label: "Amenities",
    },
  ];

  const validateFormData = (formData) => {
    const requiredFields = [
      "name",
      "org",
      "status",
      "powerCapacity",
      "gridPhase",
      "pinCode",
      "city",
      "state",
      "country",
      "latitude",
      "longitude",
      "fullAddress",
      "contactNumber",
      
    ];

    // Check for empty required fields
    for (const field of requiredFields) {
      if (!formData[field] || formData[field].toString().trim() === "") {
        toast.error(
          `Please fill in the ${field.replace(/([A-Z])/g, " $1").toLowerCase()}`
        );
        return false;
      }
    }

    // Validate powerCapacity to be a positive number
    const powerCapacity = parseFloat(formData.powerCapacity);
    if (isNaN(powerCapacity) || powerCapacity <= 0) {
      toast.error("Power capacity must be a positive number");
      return false;
    }

    // Validate contactNumber to be a positive number and within the digit limit
    const contactNumber = formData.contactNumber.toString().trim();
    if (!/^\d+$/.test(contactNumber)) {
      toast.error("Contact number must be a number");
      return false;
    }
    if (contactNumber.length > 15) {
      toast.error("Contact number must not exceed 15 digits");
      return false;
    }

    // Validate latitude and longitude to be numbers
    const latitude = parseFloat(formData.latitude);
    const longitude = parseFloat(formData.longitude);
    if (isNaN(latitude)) {
      toast.error("Latitude must be a number");
      return false;
    }
    if (isNaN(longitude)) {
      toast.error("Longitude must be a number");
      return false;
    }

    // Validate openingTime and closingTime if not 24 hours
    if (!formData.openingTime || !formData.closingTime) {
      toast.error("Please select both opening and closing times");
      return false;
    }

    // Validate workingDays if not all days selected
    if (!isAllDays && workingDays.length === 0) {
      toast.error("Please select at least one working day");
      return false;
    }

      // Check if images are uploaded
  if (images.length === 0) {
    toast.error("Please upload at least one image to the gallery");
    return false;
  }

    // If all checks pass, return true
    return true;
  };

  const handleSubmit = async () => {
    // Validate form data before submitting
    if (!validateFormData(formData)) {
      return; // Stop the submit process if validation fails
    }

    // Prepare the data to send
    const finalData = {
      ...formData,
      openingTime: openingTime.valueOf().toString(), // Convert openingTime to milliseconds
      closingTime: closingTime.valueOf().toString(), // Convert closingTime to milliseconds
      stationImgs: images,
    };

    setLoading(true); // Start loading

    try {
      if (stationData) {
        // If stationData exists, update the station
        const response = await dispatch(
          updateOneStation({
            stationId: stationData, // Use the ID from stationData
            stationData: finalData,
          })
        ).unwrap();

        // Show success message and navigate
        toast.success("Station Updated Successfully");
        navigate(-1);
      } else {
        // If no stationData, create a new station
        const response = await dispatch(createStation(finalData)).unwrap();

        // Show success message and navigate
        toast.success("Station Created Successfully");
        navigate(-1);
      }
    } catch (error) {
      // Handle any errors
      console.error("Error processing station:", error);
      toast.error("Failed to process station");
    } finally {
      setLoading(false); // End loading
    }
  };

  // const handleSubmit = async () => {
  //   // Prepare the data to send
  //   const finalData = {
  //     ...formData,
  //     openingTime: openingTime.valueOf().toString(), // Convert openingTime to milliseconds
  //     closingTime: closingTime.valueOf().toString(), // Convert closingTime to milliseconds
  //     stationImgs: images,
  //   };

  //   setLoading(true); // Start loading

  //   try {
  //     if (stationData) {
  //       // If stationData exists, update the station
  //       const response = await dispatch(
  //         updateOneStation({
  //           stationId: stationData, // Use the ID from stationData
  //           stationData: finalData,
  //         })
  //       ).unwrap();

  //       // Show success message and navigate
  //       toast.success("Station Updated Successfully");
  //       navigate(-1);
  //     } else {
  //       // If no stationData, create a new station
  //       const response = await dispatch(createStation(finalData)).unwrap();

  //       // Show success message and navigate
  //       toast.success("Station Created Successfully");
  //       navigate(-1);
  //     }
  //   } catch (error) {
  //     // Handle any errors
  //     console.error("Error processing station:", error);
  //     toast.error("Failed to process station");
  //   } finally {
  //     setLoading(false); // End loading
  //   }
  // };

  const acceptedFilesType = {
    "image/jpeg": [],
    "image/png": [],
    "image/jpg": [],
  };

  return (
    <Box
      sx={{
        padding: "24px 24px 78px 24px",
        background: "#fff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "48px",
      }}
    >
      {/* Display CircularProgress when loading */}
      {loading && (
        <Box
          sx={{
            position: "fixed", // Change to fixed
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: to give a slight overlay effect
            zIndex: 9999, // Ensure it's above other elements
            pointerEvents: "none", // Optional: prevents interactions with elements behind the loader
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* Basic Details */}
      <Section icon="ev_station" title="Basic details">
        <Grid container columnSpacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <InputField
              label="Charging station name"
              placeholder="Enter the name here"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item md={4}>
            <SelectField
              name="org"
              value={CsOrgData ? formData.org = CsOrgData?.id : formData.org}
              label="Organization"
              handleChange={handleSelectChange}
              menuItems={organizationMenuItems}
              disabled={CsOrgData ? true : false}
            
            />
          </Grid>
          <Grid item md={4}>
            <SelectField
              label="Status"
              name="status"
              value={formData.status}
              handleChange={handleSelectChange}
              menuItems={statusMenuItems}
            />
          </Grid>
        </Grid>
      </Section>

      {/* Specifications */}
      <Section icon="page_info" title="Specifications">
        <Grid container columnSpacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <InputField
              label="Power capacity(kW)"
              placeholder="Enter power capacity here"
              name="powerCapacity"
              value={formData.powerCapacity}
              onChange={handleInputChange}
              type="number"
            />
          </Grid>
          <Grid item md={4}>
            <SelectField
              label="Grid phase"
              name="gridPhase"
              value={formData.gridPhase}
              handleChange={handleSelectChange}
              menuItems={gridPhaseMenuItems}
            />
          </Grid>
        </Grid>
      </Section>

      {/* Location */}
      <Section icon="location_on" title="Location">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Grid container columnSpacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <InputField
                label="Pin code"
                placeholder="Enter the pin code here"
                name="pinCode"
                value={formData.pinCode}
                onChange={handleInputChange}
                type="number"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField
                label="City/Town"
                placeholder="Enter the city/town here"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                type="text"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField
                label="State"
                placeholder="Enter the state here"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                type="text"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <SelectField
                label="Country"
                name="country"
                value={formData.country}
                handleChange={handleSelectChange}
                // menuItems={countryMenuItems}
                menuItems={options}
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <InputField
                label="Latitude"
                placeholder="Enter the latitude here"
                name="latitude"
                type="number"
                value={formData.latitude}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField
                label="Longitude"
                placeholder="Enter the longitude here"
                name="longitude"
                type="number"
                value={Number(formData.longitude)}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item md={8} xs={12}>
              <TextareaField
                label="Full Address"
                placeholder="Enter the full address here"
                name="fullAddress"
                value={formData.fullAddress}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Section>

      {/* Other details */}
      <Section icon="ev_station" title="Other details">
        <Grid container columnSpacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography
                textAlign="start"
                fontSize="14px"
                color="#2f2f2f"
                fontFamily="myThirdFont"
                outline="none"
              >
                Timings
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  width: "100%",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      value={openingTime}
                      onChange={handleOpenTimeChange}
                      ampm={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select open"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      value={closingTime}
                      ampm={false}
                      onChange={handleCloseTimeChange}
                      minTime={openingTime ? openingTime : null} // Add conditional restriction
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select close"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={isOpen24Hours}
                  onChange={handleIsOpen24HoursChange}
                />
                <Typography
                  color="#2F2F2F"
                  fontFamily="myThirdFont"
                  fontSize="14px"
                >
                  Open 24 hrs
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography
                textAlign="start"
                fontSize="14px"
                color="2f2f2f"
                fontFamily="myThirdFont"
                outline="none"
              >
                Working Days
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  sx={{ fontSize: "14px", fontFamily: "mySecondFont" }}
                  id="demo-multiple-checkbox-label"
                >
                  Select days
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={workingDays}
                  name="workingDays"
                  onChange={handleWorkingDaysChange}
                  input={
                    <OutlinedInput
                      sx={{ fontSize: "14px" }}
                      label="Select days"
                    />
                  }
                  renderValue={(selected) => selected.join(", ")}
                  sx={{ height: "48px", fontFamily: "myThirdFont !important" }}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={workingDays.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={isAllDays}
                  onChange={handleIsAllDaysChange}
                  {...label}
                />
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"myThirdFont"}
                  fontSize={"14px"}
                >
                  All Days
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <InputField
              label="Station Contact Number"
              placeholder="Enter the number here"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleInputChange}
              type="number"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputField
              label="Incharge Name"
              placeholder="Enter the Incharge Name here"
              name="stationIncharge"
              value={formData.stationIncharge}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </Section>

      {/* Amenities */}
      <Section icon="local_convenience_store" title="Amenities">
        <Box
          sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}
        >
          <Typography
            fontSize={"14px"}
            fontFamily={"myThirdFont"}
            color={"#666"}
            textAlign={"center"}
          >
            Please choose all the available amenities at/near the charging
            station
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              mt: "12px",
              flexWrap: "wrap",
            }}
          >
            {amenitiesList.map((item, index) => {
              const isActive = selectedAmenities.includes(item);
              return (
                <Box
                  key={index}
                  onClick={() => handleAmenitiesClick(item)}
                  sx={{
                    padding: "12px",
                    background: isActive ? "#292D33" : "#fff",
                    borderRadius: "6px",
                    maxWidth: "120px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    border: isActive
                      ? "none"
                      : "1px solid rgba(41, 45, 51, 0.50)",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      color: isActive ? "#fff" : "#000",
                      fontSize: "24px",
                      fontWeight: "200",
                    }}
                    className="material-symbols-outlined"
                  >
                    {amenitiesIcons[index]}
                  </span>

                  <Typography
                    fontSize={"14px"}
                    color={isActive ? "#fff" : "#000"}
                    fontFamily={"mySecondFont"}
                  >
                    {item}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Section>

      {/* Gallery - Box*/}
      <GalleryBox
        files={files}
        images={images}
        setImages={setImages}
        setFiles={setFiles}
        imgTagsItem={imgTagsItem}
        stationId={stationData}
        acceptedFilesType={acceptedFilesType}
      />

      {/* Add Station Button */}
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          onClick={handleSubmit}
          sx={{
            padding: "12px 24px",
            background: "#E50000",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontSize: "16px",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "700",
            letterSpacing: "1.28px",
            ":hover": {
              background: "#E50000",
            },
          }}
        >
          {stationData ? "UPDATE" : "SAVE & ADD STATION"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddChargingStationForm;
