import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Define the initial state
const initialState = {
  stationImg: null,
  station: null,
  stations: [],
  stationDetail: null,
  stationChargingPoint: null,
  overViewData: null,
  stationSessions: [],
  stationReviews: [],
  stationQrCode: null,
  loading: false,
  error: null,
  rating: null, // Add this line
  liveStationPointStatus: [], // New state property
  nearbyStations: [],
};

// Async thunk for creating an station document
export const createStationImg = createAsyncThunk(
  "companyManagement/createStationImg",
  async (stationImgData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.createStationImg,
        stationImgData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Delete one station img

// Async thunk for deleting a station
export const deleteOneStationImg = createAsyncThunk(
  "stations/deleteOneStationImg",
  async ({ stationImgId, stationId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${endPoints.deleteOneStationImg}/${stationImgId}/${stationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return stationImgId;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk for updating a station img
export const updateOneStationImg = createAsyncThunk(
  "stations/updateOneStationImg",
  async ({ stationImgId, payload }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.patch(
        `${endPoints.updateOneStationImg}/${stationImgId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return stationImgId;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk for creating an station
export const createStation = createAsyncThunk(
  "companyManagement/createStation",
  async (stationData, { rejectWithValue }) => {
    try {
      console.log("ehheeheh", stationData);
      const token = localStorage.getItem("token");
      const response = await axios.post(endPoints.createStation, stationData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create async thunks for API calls
export const fetchAllStations = createAsyncThunk(
  "stations/fetchAllStations",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAllStations, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchOneStationDetail = createAsyncThunk(
  "stations/fetchOneStationDetail",
  async (stationId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneStationDetail}/${stationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchStationChargingPoint = createAsyncThunk(
  "stations/fetchStationChargingPoint",
  async (stationId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getStationChargingPoint}/${stationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Add the new async thunk for overViewData
export const fetchOverViewData = createAsyncThunk(
  "stations/fetchOverViewData",
  async (
    { stationId, durationType, startTime, endTime },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.overViewData}/${stationId}?durationType=${durationType}&startTime=${startTime}&endTime=${endTime}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// New async thunk for fetching station sessions
export const fetchStationSessions = createAsyncThunk(
  "stations/fetchStationSessions",
  async (stationId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getStationSessions}/${stationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk for deleting a station
export const deleteOneStation = createAsyncThunk(
  "stations/deleteOneStation",
  async (stationId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${endPoints.deleteOneStation}/${stationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return stationId;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk for updating a station
export const updateOneStation = createAsyncThunk(
  "stations/updateOneStation",
  async ({ stationId, stationData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${endPoints.updateOneStation}/${stationId}`,
        stationData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchStationReviews = createAsyncThunk(
  "stations/fetchStationReviews",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getOneReviews, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Assuming reviews are in `data`
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchQrByStation = createAsyncThunk(
  "stations/fetchQrByStation",
  async (stationId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getQrByStation}/${stationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Assuming QR code data is in `data`
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

//Live Status
// New async thunk for fetching live station point status
export const fetchLiveStationPointStatus = createAsyncThunk(
  "stations/fetchLiveStationPointStatus",
  async (stationId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getLiveStationPointStatus}/${stationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk for fetching nearby stations
export const fetchNearbyStations = createAsyncThunk(
  "stations/fetchNearbyStations",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getNearbyStations, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Assuming data is inside `data`
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchOneStationRating = createAsyncThunk(
  "stations/fetchOneStationRating",
  async (stationId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneStationOneRating}/${stationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Log the full response
      console.log("API Response:", response);

      // Log the data received
      console.log("Rating Data:", response.data.data);

      return response.data.data; // Adjust this based on your API response
    } catch (error) {
      // Log error details
      console.log(
        "API Error:",
        error.response ? error.response.data : error.message
      );

      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create the slice
const stationsSlice = createSlice({
  name: "stations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllStations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllStations.fulfilled, (state, action) => {
        state.loading = false;
        state.stations = action.payload;
      })
      .addCase(fetchAllStations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneStationDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneStationDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.stationDetail = action.payload;
      })
      .addCase(fetchOneStationDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchStationChargingPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStationChargingPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.stationChargingPoint = action.payload;
      })
      .addCase(fetchStationChargingPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createStationImg.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createStationImg.fulfilled, (state, action) => {
        state.loading = false;
        state.stationImg = action.payload;
      })
      .addCase(createStationImg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createStation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createStation.fulfilled, (state, action) => {
        state.loading = false;
        state.station = action.payload;
      })
      .addCase(createStation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOverViewData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOverViewData.fulfilled, (state, action) => {
        state.loading = false;
        state.overViewData = action.payload;
      })
      .addCase(fetchOverViewData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Add handlers for the new thunk
      .addCase(fetchStationSessions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStationSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.stationSessions = action.payload;
      })
      .addCase(fetchStationSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteOneStation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteOneStation.fulfilled, (state, action) => {
        state.loading = false;
        state.stations = state.stations.filter(
          (station) => station._id !== action.payload
        );
      })
      .addCase(deleteOneStation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateOneStation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOneStation.fulfilled, (state, action) => {
        state.loading = false;
        state.stations = state.stations.map((station) =>
          station.id === action.payload.id ? action.payload : station
        );
        state.stationDetail = action.payload; // Update detail if necessary
      })
      .addCase(updateOneStation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchStationReviews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStationReviews.fulfilled, (state, action) => {
        state.loading = false;
        state.stationReviews = action.payload;
      })
      .addCase(fetchStationReviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchQrByStation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchQrByStation.fulfilled, (state, action) => {
        state.loading = false;
        state.stationQrCode = action.payload; // Store QR code data
      })
      .addCase(fetchQrByStation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchLiveStationPointStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLiveStationPointStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.liveStationPointStatus = action.payload;
      })
      .addCase(fetchLiveStationPointStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchNearbyStations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNearbyStations.fulfilled, (state, action) => {
        state.loading = false;
        state.nearbyStations = action.payload;
      })
      .addCase(fetchNearbyStations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneStationRating.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneStationRating.fulfilled, (state, action) => {
        state.loading = false;
        state.rating = action.payload;
      })
      .addCase(fetchOneStationRating.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer
export default stationsSlice.reducer;
