import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";

const InfoBox = ({ label, value, icon, infoSpan }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: icon ? "space-between" : "start",
        alignItems: "center",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {value}
      </Typography>

      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
    <Typography
      fontSize={"12px"}
      fontFamily={"myThirdFont"}
      color={"#666"}
      textAlign={"start"}
    >
      {infoSpan}
    </Typography>
  </Box>
);

const OtherDetails = () => {
  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            info
          </span>
          <Heading text={"Other Details"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* specifications */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <InfoBox label="Admin" value="Rakesh Singh" />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox
              label="Created At"
              value="24/08/2023"
              infoSpan="(23:08 PM)"
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox
              label="Last modified"
              value="24/08/2023"
              infoSpan="(23:08 PM)"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OtherDetails;
