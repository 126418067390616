// import dayjs from "dayjs";
// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
//   Dot,
// } from "recharts";
// import {
//   Container,
//   Snackbar,
//   Typography,
//   Box,
// } from "@mui/material";
// import MuiAlert from "@mui/material/Alert";

// const colors = [
//   "#8C1F58",
//   "#2DB1C2",
//   "#ECAB51",
//   "#D7484A",
//   "#078C03",
//   "#042940",
//   "#802922",
//   "#4A4633",
//   "#90A19D",
//   "#400036",
// ];

// const CustomLineGraph = ({
//   data,
//   selectedTime,
//   selectedFields,
//   selectAll,
//   selectedOrgs,
//   openSnackbar,
//   snackbarMessage,
//   orgOptions,
//   startTime,
//   endTime,
// }) => {
//   const generateDates = (timeFrame) => {
//     let dates = [];
//     if (timeFrame === "week") {
//       for (let i = 0; i < 7; i++) {
//         dates.push(dayjs().subtract(i, "day").format("YYYY-MM-DD"));
//       }
//       dates.reverse(); // Ensure dates are in ascending order
//     } else if (timeFrame === "month") {
//       const startOfMonth = dayjs().startOf("month");
//       const endOfMonth = dayjs().endOf("month");
//       let currentDate = startOfMonth;
//       while (currentDate.isBefore(endOfMonth) || currentDate.isSame(endOfMonth)) {
//         dates.push(currentDate.format("YYYY-MM-DD"));
//         currentDate = currentDate.add(1, "day");
//       }
//     } else if (timeFrame === "year") {
//       const startOfYear = dayjs().startOf("year");
//       const endOfYear = dayjs().endOf("year");
//       let currentDate = startOfYear;
//       const monthNames = [
//         "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
//       ];
//       while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
//         dates.push({
//           date: currentDate.format("YYYY-MM"),
//           label: monthNames[currentDate.month()],
//         });
//         currentDate = currentDate.add(1, "month");
//       }
//     } else if (timeFrame === "customRange" && startTime && endTime) {
//       let currentDate = dayjs(startTime);
//       const end = dayjs(endTime);
//       while (currentDate.isBefore(end) || currentDate.isSame(end)) {
//         dates.push(currentDate.format("YYYY-MM-DD"));
//         currentDate = currentDate.add(1, "day");
//       }
//     } else if (timeFrame === "today") {
//       for (let i = 0; i < 24; i++) {
//         dates.push({
//           date: dayjs().startOf("day").add(i, "hour").format("YYYY-MM-DD HH:00"),
//           label: dayjs().startOf("day").add(i, "hour").format("HH:00"),
//         });
//       }
//     } else if (timeFrame === "yesterday") {
//       dates.push({
//         date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
//         label: "Yesterday",
//       });
//     }
//     return dates;
//   };

//   const getData = () => {
//     if (!selectedTime || !data) return [];

//     const dates = generateDates(selectedTime);

//     const combinedData = dates.map(({ date, label }) => {
//       let combinedDataPoint = { date: label };
//       selectedFields.forEach((field) => {
//         if (selectAll) {
//           combinedDataPoint[field] = orgOptions
//             .filter((org) => org.value !== "selectAll")
//             .reduce((sum, org) => {
//               const orgData = data[org.value]?.find((d) => d._id === date);
//               return sum + (orgData ? orgData[field] : 0);
//             }, 0);
//         } else {
//           selectedOrgs.forEach((org) => {
//             const orgData = data[org]?.find((d) => d._id === date);
//             combinedDataPoint[`${org}_${field}`] = orgData ? orgData[field] : 0;
//           });
//         }
//       });
//       return combinedDataPoint;
//     });

//     return combinedData;
//   };

//   const chartData = getData();

//   const formatTooltipValue = (value) => {
//     return value.toFixed(2);
//   };

//   const CustomTickForXAxis = ({ x, y, payload }) => (
//     <text
//       x={x}
//       y={y}
//       dy={16}
//       textAnchor="middle"
//       fill="#666"
//       fontSize="16px"
//       fontFamily="myThirdFont"
//     >
//       {payload.value}
//     </text>
//   );

//   const CustomTooltip = ({ active, payload, label }) => {
//     if (active && payload && payload.length) {
//       return (
//         <Box
//           sx={{
//             backgroundColor: "#FFFFFF",
//             border: "1px solid #ccc",
//             borderRadius: "8px",
//             padding: "10px",
//             boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
//             textAlign: "left",
//           }}
//         >
//           <Typography
//             sx={{ fontSize: "16px", fontFamily: "mySecondFont", mb: 1 }}
//           >
//             {label}
//           </Typography>
//           {payload.map((entry, index) => (
//             <Typography
//               key={index}
//               sx={{ color: entry.color, fontSize: "14px", marginBottom: "5px" }}
//             >
//               {entry.name}: {formatTooltipValue(entry.value)}
//             </Typography>
//           ))}
//         </Box>
//       );
//     }
//     return null;
//   };

//   return (
//     <Container
//       sx={{
//         backgroundColor: "#FFF",
//         padding: "36px 0",
//         margin: "24px 0",
//       }}
//     >
//       <ResponsiveContainer width="100%" height={400}>
//         <LineChart
//           data={chartData}
//           margin={{
//             top: 5,
//             right: 30,
//             left: 20,
//             bottom: 5,
//           }}
//         >
//           <CartesianGrid strokeDasharray="5 5" vertical={false} />
//           <XAxis dataKey="date" strokeWidth={2} tick={<CustomTickForXAxis />} />
//           {selectedFields.map((field, index) => (
//             <YAxis
//               key={index}
//               yAxisId={index}
//               stroke={colors[index % colors.length]} // Match Y-axis color with line color
//               strokeWidth={2} // Increase line thickness
//             />
//           ))}
//           <Tooltip formatter={formatTooltipValue} content={<CustomTooltip />} />
//           <Legend />
//           {selectAll
//             ? selectedFields.map((field, index) => (
//                 <Line
//                   key={field}
//                   yAxisId={index}
//                   type="monotone"
//                   dataKey={field}
//                   name={field}
//                   stroke={colors[index % colors.length]}
//                   strokeWidth={1} // Increase line thickness
//                   dot={<Dot r={1} fill={colors[index % colors.length]} />}
//                 />
//               ))
//             : selectedOrgs.map((org, orgIndex) =>
//                 selectedFields.map((field, fieldIndex) => (
//                   <Line
//                     key={`${org}_${field}`}
//                     yAxisId={fieldIndex}
//                     type="monotone"
//                     dataKey={`${org}_${field}`}
//                     name={`${org} - ${field}`}
//                     stroke={
//                       colors[
//                         (orgIndex * selectedFields.length + fieldIndex) %
//                           colors.length
//                       ]
//                     }
//                     strokeWidth={1} // Increase line thickness
//                     dot={
//                       <Dot r={1} fill={colors[fieldIndex % colors.length]} />
//                     }
//                   />
//                 ))
//               )}
//         </LineChart>
//       </ResponsiveContainer>

//       <Snackbar open={openSnackbar} autoHideDuration={3000}>
//         <MuiAlert severity="warning" sx={{ width: "100%" }}>
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </Container>
//   );
// };

// export default CustomLineGraph;

import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Dot,
} from "recharts";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
  Box,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const colors = [
  "#8C1F58",
  "#2DB1C2",
  "#ECAB51",
  "#D7484A",
  "#078C03",
  "#042940",
  "#802922",
  "#4A4633",
  "#90A19D",
  "#400036",
];

const CustomLineGraph = ({
  data,
  selectedTime,
  selectedFields,
  selectAll,
  selectedOrgs,
  openSnackbar,
  snackbarMessage,
  orgOptions,
  startTime,
  endTime,
}) => {

  const getData = () => {
    if (!selectedTime || !data) return [];
  
    let combinedData = [];
    const dates = generateDates(selectedTime);
  
    if (selectAll) {
      dates.forEach((date) => {
        const combinedDataPoint = { date };
        selectedFields.forEach((field) => {
          combinedDataPoint[field] = orgOptions
            .filter((org) => org.value !== "selectAll")
            .reduce((sum, org) => {
              const orgData = data[org.value]?.find((d) => d._id === date);
              return sum + (orgData ? orgData[field] : 0);
            }, 0);
        });
        combinedData.push(combinedDataPoint);
      });
    } else {
      selectedOrgs.forEach((org) => {
        dates.forEach((date) => {
          const orgData = data[org]?.find((d) => d._id === date);
          const combinedDataPoint = combinedData.find(
            (d) => d.date === date
          ) || { date };
          selectedFields.forEach((field) => {
            combinedDataPoint[`${org}_${field}`] = orgData ? orgData[field] : 0;
          });
          if (!combinedData.includes(combinedDataPoint)) {
            combinedData.push(combinedDataPoint);
          }
        });
      });
    }
  
    return combinedData;
  };

  const generateDates = (timeFrame) => {
    let dates = [];
    if (timeFrame === "week") {
      for (let i = 0; i < 7; i++) {
        dates.push(dayjs().subtract(i, "day").format("YYYY-MM-DD"));
      }
    } else if (timeFrame === "month") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;

      while (
        currentDate.isBefore(endOfMonth) ||
        currentDate.isSame(endOfMonth)
      ) {
        dates.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, "day");
      }
    }   if (timeFrame === "year") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;
  
      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push(currentDate.format("YYYY-MM")); // or use "M" for just month number
        currentDate = currentDate.add(1, "month");
      }
    } else if (timeFrame === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, "day");
      }
    }

    console.log("okkkkk123", dates, dayjs());
    return dates;
  };

  


  const chartData = getData();

  // const handleSnackbarClose = (event, reason) => {
  //     if (reason === 'clickaway') {
  //         return;
  //     }
  //     setOpenSnackbar(false);
  // };
  const formatTooltipValue = (value) => {
    return value.toFixed(2);
  };

  const CustomTickForXAxis = ({ x, y, payload }) => (
    <text
      x={x}
      y={y}
      dy={16}
      textAnchor="middle"
      fill="#666"
      fontSize="16px"
      fontFamily="myThirdFont"
    >
      {payload.value}
    </text>
  );
  // const CustomYAxisTick = ({ x, y, payload }) => (
  //   <text
  //     x={x}
  //     y={y}
  //     dy={0}
  //     dx={-24}
  //     textAnchor="middle"
  //     fill="#666"
  //     fontSize="16px"
  //     fontFamily="myThirdFont"
  //     // transform="rotate(-30, 0, 0)" // Optional rotation
  //   >
  //     {payload.value}
  //   </text>
  // );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "10px",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
            textAlign: "left",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", fontFamily: "mySecondFont", mb: 1 }}
          >
            {label}
          </Typography>
          {payload.map((entry, index) => (
            <Typography
              key={index}
              sx={{ color: entry.color, fontSize: "14px", marginBottom: "5px" }}
            >
              {entry.name}: {entry.value.toFixed(2)}
            </Typography>
          ))}
        </Box>
      );
    }

    return null;
  };

  return (
    <Container
      sx={{
        backgroundColor: "#FFF",
        padding: "36px 0",
        margin: "24px 0",
      }}
    >
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis dataKey="date" strokeWidth={2} tick={<CustomTickForXAxis />} />
          {selectedFields.map((field, index) => (
            <YAxis
              key={index}
              yAxisId={index}
              stroke={colors[index % colors.length]} // Match Y-axis color with line color
              strokeWidth={2} // Increase line thickness
              // tick={<CustomYAxisTick />}
            />
          ))}
          <Tooltip formatter={formatTooltipValue} content={<CustomTooltip />} />
          <Legend />
          {selectAll
            ? selectedFields.map((field, index) => (
                <Line
                  key={field}
                  yAxisId={index}
                  type="monotone"
                  dataKey={field}
                  name={field}
                  stroke={colors[index % colors.length]}
                  strokeWidth={1} // Increase line thickness
                  dot={<Dot r={1} fill={colors[index % colors.length]} />}
                />
              ))
            : selectedOrgs.map((org, orgIndex) =>
                selectedFields.map((field, fieldIndex) => (
                  <Line
                    key={`${org}_${field}`}
                    yAxisId={fieldIndex}
                    type="monotone"
                    dataKey={`${org}_${field}`}
                    name={`${org} - ${field}`}
                    stroke={
                      colors[
                        (orgIndex * selectedFields.length + fieldIndex) %
                          colors.length
                      ]
                    }
                    strokeWidth={1} // Increase line thickness
                    dot={
                      <Dot r={1} fill={colors[fieldIndex % colors.length]} />
                    }
                  />
                ))
              )}
        </LineChart>
      </ResponsiveContainer>

      <Snackbar open={openSnackbar} autoHideDuration={3000}>
        <MuiAlert severity="warning" sx={{ width: "100%" }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default CustomLineGraph;
