import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTabs from "../custom/CustomTabs";
import TimeFilter from "../Filters/TimeFilter";
import MainHeading from "../../utils/MainHeading";
import OverAllBox from "./OverAllBox";
import OrganizationBox from "./OrganizationBox";
import StationsBox from "./StationsBox";
import ChargersBox from "./ChargersBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrgStats } from "../../redux/action/statistics/statisticsSlice";

// for tabs icons
const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopHeader = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  // const dispatch = useDispatch();
  // const { orgStats, status, error } = useSelector((state) => state.statistics);
  // console.log("okkk", orgStats);

  // useEffect(() => {
  //   const durationType = "weekly"; // Adjust according to your requirements
  //   const startTime = new Date("2024-08-01").getTime(); // Convert date to milliseconds
  //   const endTime = new Date("2024-08-08").getTime(); // Convert date to milliseconds

  //   // Dispatch the fetchOrgStats action with necessary parameters
  //   dispatch(fetchOrgStats({ durationType, startTime, endTime }));

  //   // Log the fetched data
  //   console.log("yo bro", orgStats);
  // }, [dispatch]);

  const { profile } = useSelector((state) => state.profile);
  console.log("mmm", profile?.role)

  const tabsData = [
    {
      label: "Overview",
      content: (
        <Box>
          <OverAllBox />
        </Box>
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      label: "Stations",
      content: (
        <Box>
          <StationsBox />
        </Box>
      ),
      icon: <CustomIcon icon={"sync_alt"} />,
    },
    {
      label: "Chargers",
      content: (
        <Box>
          <ChargersBox />
        </Box>
      ),
      icon: <CustomIcon icon={"sync_alt"} />,
    },
    ...(profile?.role !== "CPO_Admin" ? [
      {
        label: "Organization",
        content: (
          <Box>
            <OrganizationBox />
          </Box>
        ),
        icon: <CustomIcon icon={"sync_alt"} />,
      },
    ] : []),
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <MainHeading text={"Statistics"} />
          </Box>
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopHeader;
