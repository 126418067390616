// import React, { useState } from "react";
// import { Box, Typography, Select, MenuItem } from "@mui/material";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

// const data1 = [
//   {
//     name: "Mon",
//     appSessions: 10,
//     directSessions: 24,
//     amt: 2400,
//   },
//   {
//     name: "Tue",
//     appSessions: 30,
//     directSessions: 33,
//     amt: 2210,
//   },
//   {
//     name: "Wed",
//     appSessions: 43,
//     directSessions: 37,
//     amt: 2290,
//   },
//   {
//     name: "Thu",
//     appSessions: 53,
//     directSessions: 49,
//     amt: 2000,
//   },
//   {
//     name: "Fri",
//     appSessions: 38,
//     directSessions: 28,
//     amt: 2181,
//   },
//   {
//     name: "Sat",
//     appSessions: 23,
//     directSessions: 18,
//     amt: 2500,
//   },
//   {
//     name: "Sun",
//     appSessions: 64,
//     directSessions: 53,
//     amt: 2100,
//   },
// ];

// const DoubleLineGraph = ({ graphTitle, gr_type, data, selectedTime, startTime, endTime  }) => {
//   //For dropdown of payments top graph
//   const [selectedValue, setSelectedValue] = useState("");

//   const handleChange = (event) => {
//     setSelectedValue(event.target.value);
//   };

//   console.log("grrr", data, selectedTime, startTime, endTime);

//   return (
//     <Box sx={{ width: "100%", height: gr_type==="paymentTopGraph"? 284: 312 }}>
//       <Box>
//         {gr_type !== "paymentTopGraph" ? (
//           <Typography
//             sx={{
//               textAlign: "center",
//               marginBottom: "8px",
//               fontFamily: "myThirdFont",
//               fontWeight: "600",
//               fontSize: "14px",
//               color: "#666",
//               lineHeight: "16px",
//             }}
//           >
//             {graphTitle}
//           </Typography>
//         ) : (
//           gr_type === "paymentTopGraph" && (
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 marginBottom:"24px"
//               }}
//             >
//               <Select
//                 value={selectedValue}
//                 onChange={handleChange}
//                 displayEmpty
//                 sx={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "cemter",
//                   //   padding: "8px 16px",
//                   width: "120px",
//                   height: "32px",
//                   color: "#1C1C1C",
//                   fontFamily: "myThirdFont",
//                   marginLeft:"40px"
//                 }}
//               >
//                 <MenuItem value="" disabled>
//                   Direct vs App
//                 </MenuItem>
//                 <MenuItem value="option1">Option 1</MenuItem>
//                 <MenuItem value="option2">Option 2</MenuItem>
//                 <MenuItem value="option3">Option 3</MenuItem>
//               </Select>

//               <Typography
//                 sx={{
//                   textAlign: "center",
//                   marginBottom: "8px",
//                   fontFamily: "myThirdFont",
//                   fontWeight: "600",
//                   fontSize: "14px",
//                   color: "#666",
//                   lineHeight: "16px",
//                 }}
//               >
//                 {graphTitle}
//               </Typography>
//             </Box>
//           )
//         )}
//       </Box>

//       <ResponsiveContainer width="100%" height="100%">
//         <LineChart
//           data={data1}
//           margin={{
//             top: 10,
//             right: 10,
//             left: 10,
//             bottom: 20,
//           }}
//         >
//           <CartesianGrid strokeDasharray="5 3" vertical={false} />

//           <YAxis
//             tick={{
//               fontSize: "12px",
//               fontFamily: "myThirdFont",
//               fill: "#666",
//               fontWeight: "500",
//               lineHeight: "16px",
//             }}
//             axisLine={false} // Hide X-axis line
//           />
//           <XAxis
//             dataKey="name"
//             tick={{
//               fontSize: "14px",
//               fontFamily: "mySecondFont",
//               fill: "#666",
//               fontWeight: "500",
//               lineHeight: "16px",
//             }}
//             axisLine={false} // Hide X-axis line
//           />
//           <Tooltip />
//           <Legend />
//           <Line
//             type="monotone"
//             dataKey="directSessions"
//             stroke="#F8610C"
//             activeDot={{ r: 3 }}
//           />
//           <Line type="monotone" dataKey="appSessions" stroke="#2271E8" />
//           <Legend verticalAlign="top" />
//         </LineChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default DoubleLineGraph;

import React, { useState, useEffect } from "react";
import { Box, Typography, Select, MenuItem } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

const DoubleLineGraph = ({
  graphTitle,
  gr_type,
  data,
  selectedTime,
  startTime,
  endTime,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];
    if (selectedTime === "week") {
      for (let i = 0; i < 7; i++) {
        const day = dayjs().subtract(i, "day").format("ddd");
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: day,
        });
      }
      dates.reverse();
    } else if (selectedTime === "month") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;

      while (
        currentDate.isBefore(endOfMonth) ||
        currentDate.isSame(endOfMonth)
      ) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "year") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    } else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.format("YYYY-MM-DD"),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "today") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs()
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    } else if (selectedTime === "yesterday") {
      // Generate hour-wise data for yesterday
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().subtract(1, "day").startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs()
            .subtract(1, "day")
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    }
    return dates;
  };

  // const getData = () => {
  //   if (!selectedTime || !data) return [];

  //   const dates = generateDates(selectedTime, startTime, endTime);

  //   const combinedData = dates.map(({ date, label }) => {
  //     let dailyData = {};
  //     let monthlyData = {};
  //     if (selectedTime === "year") {
  //       const month = date;
  //       monthlyData = data?.stats?.find((item) => item._id === month) || {};
  //     } else {
  //       dailyData = data.stats.find((item) => item._id === date) || {};
  //     }

  //     const appSessions = parseFloat(dailyData.sessionsCount_App) || 0;
  //     const directSessions = parseFloat(dailyData.sessionsCount_Upi) || 0;

  //     return {
  //       date: label,
  //       appSessions,
  //       directSessions,
  //     };
  //   });

  //   return combinedData;
  // };

  const getData = () => {
    if (!selectedTime || !data) return [];
  
    const dates = generateDates(selectedTime, startTime, endTime);
  
    const combinedData = dates.map(({ date, label }) => {
      let relevantData = {};
      
      // Check if selected time is year or other
      if (selectedTime === "year") {
        // Fetch monthly data for the year
        relevantData = data?.stats?.find((item) => item._id === date) || {};
      } else {
        // Fetch daily data for other selections
        relevantData = data.stats.find((item) => item._id === date) || {};
      }
  
      const appSessions = parseFloat(relevantData.sessionsCount_App) || 0;
      const directSessions = parseFloat(relevantData.sessionsCount_Upi) || 0;
  
      return {
        date: label,
        appSessions,
        directSessions,
      };
    });
  
    return combinedData;
  };
  
  

  const chartData = getData();

  return (
    <Box
      sx={{ width: "100%", height: gr_type === "paymentTopGraph" ? 284 : 312 }}
    >
      <Box>
        {gr_type !== "paymentTopGraph" ? (
          <Typography
            sx={{
              textAlign: "center",
              marginBottom: "8px",
              fontFamily: "myThirdFont",
              fontWeight: "600",
              fontSize: "14px",
              color: "#666",
              lineHeight: "16px",
            }}
          >
            {graphTitle}
          </Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <Select
              value={selectedValue}
              onChange={handleChange}
              displayEmpty
              sx={{
                width: "120px",
                height: "32px",
                color: "#1C1C1C",
                fontFamily: "myThirdFont",
                marginLeft: "40px",
              }}
            >
              <MenuItem value="" disabled>
                Direct vs App
              </MenuItem>
              <MenuItem value="option1">Option 1</MenuItem>
              <MenuItem value="option2">Option 2</MenuItem>
              <MenuItem value="option3">Option 3</MenuItem>
            </Select>

            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "8px",
                fontFamily: "myThirdFont",
                fontWeight: "600",
                fontSize: "14px",
                color: "#666",
                lineHeight: "16px",
              }}
            >
              {graphTitle}
            </Typography>
          </Box>
        )}
      </Box>

      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="5 3" vertical={false} />
          <YAxis
            tick={{
              fontSize: "12px",
              fontFamily: "myThirdFont",
              fill: "#666",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            axisLine={false}
          />
          <XAxis
            dataKey="date"
            tick={{
              fontSize: "14px",
              fontFamily: "mySecondFont",
              fill: "#666",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            axisLine={false}
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="directSessions"
            stroke="#F8610C"
            activeDot={{ r: 3 }}
          />
          <Line type="monotone" dataKey="appSessions" stroke="#2271E8" />
          <Legend verticalAlign="top" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DoubleLineGraph;
