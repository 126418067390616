import React from 'react'

const ManageBox = () => {
  return (
    <div>
      Manage Box it is
    </div>
  )
}

export default ManageBox
