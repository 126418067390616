import React from "react";
import AlertBox from "../../dashboard/AlertBox";
import { Box } from "@mui/material";

const AlertBoxCard = () => {
  return (
    <Box >
      <AlertBox />
    </Box>
  );
};

export default AlertBoxCard;
