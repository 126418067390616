import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Define the initial state
const initialState = {
  orgStats: {},
  stationStats: {},
  chargingPointStats: {},
  walletDetails: {},
  status: "idle",
  error: null,
};

// Async thunk for fetching organization statistics by duration, start time, and end time
export const fetchOrgStats = createAsyncThunk(
  "statistics/fetchOrgStats",
  async ({ durationType, startTime, endTime }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOrgStats}/${durationType}/${startTime}/${endTime}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // Adjust based on the actual response structure
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchStationStats = createAsyncThunk(
  "statistics/fetchStationStats",
  async ({ durationType, startTime, endTime }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getStationStats}/${durationType}/${startTime}/${endTime}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // Adjust based on the actual response structure
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching charging point statistics
export const fetchChargingPointStats = createAsyncThunk(
  "statistics/fetchChargingPointStats",
  async ({ deviceId, durationType, startTime, endTime }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getChargerStats}/${durationType}/${startTime}/${endTime}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // Adjust based on the actual response structure
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching wallet details
export const fetchWalletDetails = createAsyncThunk(
  "statistics/fetchWalletDetails",
  async ({ durationType, startTime, endTime }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${endPoints.getWalletDetails}/${durationType}/${startTime}/${endTime}`;

      console.log("Fetching wallet details from:", apiUrl);

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response?.data; // Adjust based on the actual response structure
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error status:", error.response.status);
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(
          "An error occurred while fetching wallet details."
        );
      }
    }
  }
);

// Create a slice
const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrgStats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrgStats.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orgStats = action.payload;
      })
      .addCase(fetchOrgStats.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to fetch data";
      })
      .addCase(fetchStationStats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStationStats.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stationStats = action.payload;
      })
      .addCase(fetchStationStats.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to fetch data";
      })
      .addCase(fetchChargingPointStats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChargingPointStats.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chargingPointStats = action.payload;
      })
      .addCase(fetchChargingPointStats.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to fetch data";
      })
      .addCase(fetchWalletDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWalletDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.walletDetails = action.payload;
      })
      .addCase(fetchWalletDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to fetch data";
      });
  },
});

// Export the async thunk

// Export the reducer
export default statisticsSlice.reducer;
