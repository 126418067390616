//  localhost redirection
// sitekey="6Lfr8B8qAAAAAEUCc0eJVdFTx_vJ-C9P93S--7ZL"

import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../../redux/action/authSlice";
import ReCAPTCHA from "react-google-recaptcha";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, user } = useSelector((state) => state.auth);
  console.log("userrrrrrrrr", user);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (user && user.userInfo?.jwtToken) {
      console.log("userrrrrrrrr2", user);
      localStorage.setItem("token", user.userInfo.jwtToken);
      navigate("/");
    } else {
      // Clear the token from localStorage if there's no user
      // localStorage.removeItem("token");
      // Navigate to the login page if there's no user
      // navigate("/login");
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(credentials));
  };

  //Recaptcha
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleForgotPasswordButton = () => {
    navigate("/reset_password");
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url("/images/auth-bg-final.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "#FFFFFF",
            padding: "32px",
            borderRadius: "8px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Box sx={{ width: "100%", paddingBottom: "16px" }}>
              <img src="/images/1C-login-logo.png" alt="logo" />
            </Box>
            <Typography
              sx={{
                color: "#0C0C0C",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "32px",
              }}
            >
              Welcome to 1C CMS!
            </Typography>

            <Typography
              sx={{
                color: "#0C0C0C",
                fontFamily: "myThirdFont",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              Please login with your 1C CMS credentials
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box>
              <Typography
                sx={{
                  textAlign: "start",
                  marginBottom: "8px",
                  fontSize: "16px",
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                }}
              >
                Email
              </Typography>
              <input
                name="email"
                value={credentials.email}
                onChange={handleChange}
                variant="outlined"
                style={{
                  width: "416px",
                  height: "48px",
                  borderRadius: "4px",
                  border: "1px solid #CBCBCB",
                  outline: "none",
                  padding: "0 16px",
                }}
              />
            </Box>

            <Box>
              <Typography
                sx={{
                  textAlign: "start",
                  marginBottom: "8px",
                  fontSize: "16px",
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                }}
              >
                Password
              </Typography>
              <input
                label="Password"
                type="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                variant="outlined"
                style={{
                  width: "416px",
                  height: "48px",
                  borderRadius: "4px",
                  border: "1px solid #CBCBCB",
                  outline: "none",
                  padding: "0 16px",
                }}
              />
              {error && (
                <Box sx={{ borderRadius: "5px" }}>
                  <Typography
                    color="error"
                    sx={{
                      padding: "4px 0px",
                      textTransform: "none",
                      fontFamily: "mySecondFont",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      textAlign: "left",
                      marginTop: "8px",
                    }}
                  >
                    {error.message === "Unauthorized"
                      ? "Please enter both Email and Password"
                      : error.message}
                  </Typography>
                </Box>
              )}
            </Box>

            {/* <Box>
              <ReCAPTCHA
                //Production link redirection
                sitekey="6Ld9fDIqAAAAALYr4HxLZDuQsq7Ee4pEmYLg0EsO"
                onChange={handleRecaptchaChange}
              />
            </Box> */}
            <Box>
              <Button
                type="submit"
                sx={{
                  width: "100%",
                  background: "#000000",
                  padding: "12px 24px",
                  color: "#FCFCFC",
                  fontFamily: "mySecondFont",
                  justifyContent: "center",
                  alignItems: "center",
                  letterSpacing: "1.28px",

                  "&:hover": {
                    background: "#2F2F2F",
                  },
                }}
                disabled={loading}
              >
                {loading ? "Logging in..." : "Proceed"}
              </Button>

              <Typography
                sx={{
                  padding: 0,
                  textTransform: "none",
                  background: "none",
                  color: "#E50000",
                  fontFamily: "mySecondFont",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  textAlign: "right",
                  cursor: "pointer",

                  marginTop: "12px",
                }}
                // onClick={() => alert("forgot password")}
                onClick={handleForgotPasswordButton}
              >
                Forgot Password?
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
