// import React from "react";
// import {
//   ComposedChart,
//   Line,
//   Area,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import dayjs from "dayjs";

// const ComposedGraph = ({ data, selectedTime, startTime, endTime }) => {
//   console.log("qqq", data, selectedTime, startTime, endTime);

//   const generateDates = (selectedTime, startTime, endTime) => {
//     let dates = [];
//     if (selectedTime === "week") {
//       for (let i = 0; i < 7; i++) {
//         const day = dayjs().subtract(i, "day").format("ddd"); // Get day name
//         dates.push({
//           date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
//           label: day,
//         });
//       }   dates.reverse();
//     }  else if (selectedTime === "month") {
//       const startOfMonth = dayjs().startOf("month");
//       const endOfMonth = dayjs().endOf("month");
//       let currentDate = startOfMonth;

//       while (
//         currentDate.isBefore(endOfMonth) ||
//         currentDate.isSame(endOfMonth)
//       ) {
//         dates.push({
//           date: currentDate.format("YYYY-MM-DD"),
//           label: currentDate.date().toString(), // Use day number as label
//         });
//         currentDate = currentDate.add(1, "day");
//       }
//     }  else if (selectedTime === "year") {
//       const startOfYear = dayjs().startOf("year");
//       const endOfYear = dayjs().endOf("year");
//       let currentDate = startOfYear;

//       const monthNames = [
//         "Jan", "Feb", "Mar", "Apr", "May", "Jun",
//         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
//       ];

//       while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
//         dates.push({
//           date: currentDate.format("YYYY-MM-DD"),
//           label: monthNames[currentDate.month()], // Use month name as label
//         });
//         currentDate = currentDate.add(1, "month");
//       }
//     } else if (selectedTime === "customRange" && startTime && endTime) {
//       let currentDate = dayjs(startTime);
//       const end = dayjs(endTime);
//       while (currentDate.isBefore(end) || currentDate.isSame(end)) {
//         dates.push({
//           date: currentDate.format("YYYY-MM-DD"),
//           label: currentDate.format("YYYY-MM-DD"),
//         });
//         currentDate = currentDate.add(1, "day");
//       }
//     } else if (selectedTime === "today") {
//       dates.push({
//         date: dayjs().format("YYYY-MM-DD"),
//         label: "Today",
//       });
//     } else if (selectedTime === "yesterday") {
//       dates.push({
//         date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
//         label: "Yesterday",
//       });
//     }
//     return dates;
//   };

//   const getData = () => {
//     if (!selectedTime || !data) return [];

//     const dates = generateDates(selectedTime, startTime, endTime);
//     console.log("Generated Dates:", dates);

//     const combinedData = dates.map(({ date, label }) => {
//       const dailyData = data.stats.find((item) => item._id === date) || {};
//       console.log("Date:", date, "Daily Data:", dailyData);
//       return {
//         date: label, // Use label for the XAxis (day name for week, or date for others)
//         totalRevenue: (dailyData.totalRevenue) || 0,
//         totalEnergy: (dailyData.energyConsumed) || 0,
//       };
//     });

//     console.log("Combined Data:", combinedData);
//     return combinedData;
//   };

//   const chartData = getData();

//   return (
//     <ResponsiveContainer width="100%" height={350}>
//       <ComposedChart
//         data={chartData}
//         margin={{
//           top: 20,
//           right: 20,
//           bottom: 20,
//           left: 10,
//         }}
//       >
//         <CartesianGrid
//           stroke="#DDDDDD"
//           strokeDasharray="5 3"
//           vertical={false}
//         />

//         <defs>
//           <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
//             <stop offset="0%" stopColor="#E50000" stopOpacity={1} />
//             <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0} />
//           </linearGradient>
//         </defs>

//         {/* X-axis */}
//         <XAxis
//           dataKey="date" // Use "date" to match the key in chartData
//           scale="auto"
//           padding={{ left: 0 }}
//           tick={{
//             fontSize: "14px",
//             fontFamily: "mySecondFont",
//             fill: "#666",
//             fontWeight: "500",
//             lineHeight: "16px",
//           }}
//           axisLine={false} // Hide X-axis line
//         />

//         {/* Left Y-axis for bar chart */}
//         <YAxis
//           yAxisId="left"
//           tick={{
//             fontSize: "12px",
//             fontFamily: "myThirdFont",
//             fill: "#666",
//             fontWeight: "500",
//             lineHeight: "16px",
//           }}
//           axisLine={false} // Hide Y-axis line
//         />
//         <Bar
//           dataKey="totalRevenue" // Use "totalRevenue" to match the key in chartData
//           barSize={60}
//           fill="#E0DCFA"
//           yAxisId="left"
//         />

//         {/* Right Y-axis for area chart */}
//         <YAxis
//           yAxisId="right"
//           orientation="right"
//           tick={{
//             fontSize: "12px",
//             fontFamily: "myThirdFont",
//             fill: "#666",
//             fontWeight: "500",
//             lineHeight: "16px",
//           }}
//           axisLine={false} // Hide Y-axis line
//         />
//         <Area
//           type="monotone"
//           dataKey="totalEnergy" // Use "totalEnergy" to match the key in chartData
//           stroke="black"
//           fillOpacity={0.25}
//           fill="url(#colorUv)"
//           yAxisId="right"
//           dot={{ stroke: "black", strokeWidth: 1, r: 2 }}
//         />

//         <Tooltip />
//         <Legend verticalAlign="top" />
//       </ComposedChart>
//     </ResponsiveContainer>
//   );
// };

// export default ComposedGraph;

import React from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";


const ComposedGraph = ({ data, selectedTime, startTime, endTime }) => {
  console.log(
    "Data:",
    data,
    "Selected Time:",
    selectedTime,
    "Start Time:",
    startTime,
    "End Time:",
    endTime
  );

  const formatLegendLabel = (value) => {
    switch (value) {
      case 'totalRevenue':
        return 'Total Revenue (₹)';
      case 'totalEnergy':
        return 'Total Energy (kWh)';
      default:
        return value;
    }
  };


  //with today as distributed data
  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];
    if (selectedTime === "week") {
      for (let i = 0; i < 7; i++) {
        const day = dayjs().subtract(i, "day").format("ddd");
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: day,
        });
      }
      dates.reverse();
    } else if (selectedTime === "month") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;

      while (currentDate.isBefore(endOfMonth) || currentDate.isSame(endOfMonth)) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "year") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    } else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.format("YYYY-MM-DD"),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "today") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs()
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    }  else if (selectedTime === "yesterday") {
      // Generate hour-wise data for yesterday
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().subtract(1, "day").startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs()
            .subtract(1, "day")
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    }
    return dates;
  };

  //With today as aggregated labels and data
  // const generateDates = (selectedTime, startTime, endTime) => {
  //   let dates = [];
  //   if (selectedTime === "week") {
  //     for (let i = 0; i < 7; i++) {
  //       const day = dayjs().subtract(i, "day").format("ddd");
  //       dates.push({
  //         date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
  //         label: day,
  //       });
  //     }
  //     dates.reverse();
  //   } else if (selectedTime === "month") {
  //     const startOfMonth = dayjs().startOf("month");
  //     const endOfMonth = dayjs().endOf("month");
  //     let currentDate = startOfMonth;

  //     while (
  //       currentDate.isBefore(endOfMonth) ||
  //       currentDate.isSame(endOfMonth)
  //     ) {
  //       dates.push({
  //         date: currentDate.format("YYYY-MM-DD"),
  //         label: currentDate.date().toString(),
  //       });
  //       currentDate = currentDate.add(1, "day");
  //     }
  //   } else if (selectedTime === "year") {
  //     const startOfYear = dayjs().startOf("year");
  //     const endOfYear = dayjs().endOf("year");
  //     let currentDate = startOfYear;

  //     const monthNames = [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ];

  //     while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
  //       dates.push({
  //         date: currentDate.month() + 1,
  //         label: monthNames[currentDate.month()],
  //       });
  //       currentDate = currentDate.add(1, "month");
  //     }
  //   } else if (selectedTime === "customRange" && startTime && endTime) {
  //     let currentDate = dayjs(startTime);
  //     const end = dayjs(endTime);
  //     while (currentDate.isBefore(end) || currentDate.isSame(end)) {
  //       dates.push({
  //         date: currentDate.format("YYYY-MM-DD"),
  //         label: currentDate.format("YYYY-MM-DD"),
  //       });
  //       currentDate = currentDate.add(1, "day");
  //     }
  //   } else if (selectedTime === "today") {
  //     dates.push({
  //       date: dayjs().format("YYYY-MM-DD"),
  //       label: "Today",
  //     });
  //   } else if (selectedTime === "yesterday") {
  //     dates.push({
  //       date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  //       label: "Yesterday",
  //     });
  //   }
  //   return dates;
  // };

  const getData = () => {
    if (!selectedTime || !data) return [];

    const dates = generateDates(selectedTime, startTime, endTime);
    console.log("Generated Dates:", dates);

    const combinedData = dates.map(({ date, label }) => {
      let monthlyData = {};

      if (selectedTime === "year") {
        const month = date;
        monthlyData = data?.stats?.find((item) => item._id === month) || {};
      } else {
        monthlyData = data?.stats?.find((item) => item._id === date) || {};
      }

      const totalRevenue = parseFloat(monthlyData.totalRevenue) || 0;
      const energyConsumed = parseFloat(monthlyData.energyConsumed) || 0;

      // Formatted values for kWh and revenue
      const formattedEnergyConsumed = (energyConsumed / 1000).toFixed(2);
      const formattedRevenue = totalRevenue.toFixed(2);

      console.log("Date:", date, "Monthly Data:", monthlyData);
      return {
        date: label,
        totalRevenue: parseFloat(formattedRevenue),
        totalEnergy: parseFloat(formattedEnergyConsumed),
      };
    });

    console.log("Combined Data:", combinedData);
    return combinedData;
  };


  const chartData = getData();
  console.log("Chart Data:", chartData);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <ComposedChart
        data={chartData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 10,
        }}
      >
        <CartesianGrid
          stroke="#DDDDDD"
          strokeDasharray="5 3"
          vertical={false}
        />

        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#E50000" stopOpacity={1} />
            <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="date"
          scale="auto"
          padding={{ left: 0 }}
          tick={{
            fontSize: "14px",
            fontFamily: "mySecondFont",
            fill: "#666",
            fontWeight: "500",
            lineHeight: "16px",
          }}
          axisLine={false}
        />

        <YAxis
          yAxisId="left"
          tick={{
            fontSize: "12px",
            fontFamily: "myThirdFont",
            fill: "#666",
            fontWeight: "500",
            lineHeight: "16px",
          }}
          axisLine={false}
        />
        <Bar
          dataKey="totalRevenue"
          barSize={60}
          fill="#9ABCEF"
          yAxisId="left"
        />

        <YAxis
          yAxisId="right"
          orientation="right"
          tick={{
            fontSize: "12px",
            fontFamily: "myThirdFont",
            fill: "#666",
            fontWeight: "500",
            lineHeight: "16px",
          }}
          axisLine={false}
        />
        <Area
          type="monotone"
          dataKey="totalEnergy"
          stroke="black"
          fillOpacity={0.5}
          fill="url(#colorUv)"
          yAxisId="right"
          dot={{ stroke: "black", strokeWidth: 1, r: 2 }}
        />

        <Tooltip />
        <Legend
              wrapperStyle={{
                padding: 10,
                fontSize: "14px",
                fontFamily: "mySecondFont",
                color: "red",
              }}
              verticalAlign="top"
              align="center"
              iconSize={20}
              formatter={formatLegendLabel}
          
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ComposedGraph;
