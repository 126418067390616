// import { Box, Typography } from "@mui/material";
// import React from "react";
// import ValueChip from "../Chips/ValueChip";

// const ValueCard = ({
//   data,
//   previousDashboardData,
//   customers,
//   topGraphBox,
//   title,
//   orgPaymentData,
//   selectedTime
// }) => {
//   console.log(" dddd123", data, previousDashboardData);
//   console.log("selected12345", selectedTime);

//   // Helper function to format value with + sign for positive values
//   const formatValue = (value) => {
//     if (typeof value === "string" && value.startsWith("-")) {
//       return value;
//     }
//     return `+${value}`;
//   };

//   const changeIcons = () => {
//     switch (title) {
//       case "Total Billed Amount":
//         return "currency_rupee_circle";
//       case "Total Energy Consumed (kWh)":
//         return "bolt";
//       case "Total uptime (hrs)":
//         return "pace";
//       case "New users":
//         return "groups";
//       case "Total Sessions":
//         return "bolt";
//       case "Wallet Recharge":
//         return "account_balance_wallet";
//       case "Cashbacks":
//         return "smartphone";
//       case "Refund Amount":
//         return "undo";
//       case "Direct amount (QR)":
//         return "qr_code_2";
//       case "Amount from app":
//         return "smartphone";
//       default:
//         return "info";
//     }
//   };

//   const changevalue = () => {
//     switch (title) {
//       case "Total Billed Amount":
//         return data?.totalRevenue || orgPaymentData;
//       case "Total Energy Consumed (kWh)":
//         return (data?.totalEnergy / 1000).toFixed(2);
//       case "Total uptime (hrs)":
//         return data?.totalDuration;
//       case "New users":
//         return customers?.customersCount;
//       case "Total Sessions":
//         return data?.totalSessionCount;
//       case "Wallet Recharge":
//         return data?.totalWalletRecharge;
//       case "Cashbacks":
//         return data?.totalWalletCashbackAmount;
//       case "Refund Amount":
//         return data?.refundAmount || orgPaymentData;
//       case "Total Amount":
//         return data?.paidAmount;
//       case "Direct Amount (QR)":
//         return orgPaymentData.toFixed(2);
//       case "Amount from App":
//         return orgPaymentData.toFixed(2);
//       default:
//         return data?.value || "N/A";
//     }
//   };

//   // Helper function to convert duration string to seconds
//   const convertToSeconds = (duration) => {
//     const [hours, minutes, seconds] = duration.split(":").map(Number);
//     return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
//   };

//   // Helper function to convert seconds back to duration string
//   const convertToDuration = (totalSeconds) => {
//     const hours = Math.floor(totalSeconds / 3600);
//     const minutes = Math.floor((totalSeconds % 3600) / 60);
//     const seconds = totalSeconds % 60;
//     return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
//       2,
//       "0"
//     )}:${String(seconds).padStart(2, "0")}`;
//   };

//   // Calculate the difference in durations
//   const calculateDurationDifference = (currentDuration, previousDuration) => {
//     const currentSeconds = convertToSeconds(currentDuration);
//     const previousSeconds = convertToSeconds(previousDuration);

//     let differenceInSeconds = previousSeconds - currentSeconds;

//     return convertToDuration(Math.abs(differenceInSeconds));
//   };

//   const compareResultTotalRevenue = (
//     data?.totalRevenue - previousDashboardData?.totalRevenue
//   ).toFixed(2);

//   const compareResultTotalEnergy =
//     data?.totalEnergy - previousDashboardData?.totalEnergy;

//   const compareResultTotalDuration =
//     data?.totalDuration && previousDashboardData?.totalDuration
//       ? data?.totalDuration.startsWith("-")
//         ? "-" +
//           calculateDurationDifference(
//             "0:0:0",
//             previousDashboardData?.totalDuration || "0:0:0"
//           )
//         : calculateDurationDifference(
//             data?.totalDuration || "0:0:0",
//             previousDashboardData?.totalDuration || "0:0:0"
//           )
//       : "0:0:0";

//   const compareResultTotalSession =
//     data?.totalSessionCount - previousDashboardData?.totalSessionCount;

//   const changeCompare = () => {
//     switch (title) {
//       case "Total Billed Amount":
//         return compareResultTotalRevenue || orgPaymentData;
//       case "Total Energy Consumed (kWh)":
//         return (compareResultTotalEnergy / 1000).toFixed(2);
//       case "Total uptime (hrs)":
//         return compareResultTotalDuration;
//       case "Total Sessions":
//         return compareResultTotalSession;
//       default:
//         return "No Data" || "N/A";
//     }
//   };

//   return (
//     <Box
//       sx={{
//         padding: topGraphBox === "true" ? "12px" : "24px",
//         borderRadius: "8px",
//         background: "#fff",
//         border: topGraphBox === "true" ? "1px solid #ddd" : "",
//       }}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             background: "rgba(41, 45, 51, 0.08)",
//             width: "32px",
//             height: "32px",
//             borderRadius: "32px",
//           }}
//         >
//           <span
//             style={{ color: "#292D33" }}
//             className="material-symbols-outlined"
//           >
//             {changeIcons()}
//           </span>
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             gap: "6px",
//             alignItems: "flex-end",
//           }}
//         >
//           <ValueChip
//             data={data}
//             previousDashboardData={previousDashboardData}
//             title={title}
//           />
//           <Typography fontFamily={"mySecondFont"} color={"#666"}>
//             <span style={{ color: "#000", fontFamily: "mySecondFont" }}>
//               {changeCompare()}
//             </span>{" "}
//             than yesterday
//           </Typography>
//         </Box>
//       </Box>
//       <Box sx={{ marginTop: "12px" }}>
//         <Typography
//           textAlign={"start"}
//           fontSize={"14px"}
//           fontFamily={"mySecondFont"}
//           color={"#666"}
//         >
//           {title}
//         </Typography>
//         <Typography
//           textAlign={"start"}
//           fontSize={"clamp(1.25rem, 1.1981rem + 0.2516vw, 1.5rem)"}
//           color={"#0C0C0C"}
//           fontFamily="'Montserrat', sans-serif"
//           fontWeight={600}
//         >
//           {changevalue()}
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

// export default ValueCard;

import { Box, Typography } from "@mui/material";
import React from "react";
import ValueChip from "../Chips/ValueChip";

const ValueCard = ({
  data,
  previousDashboardData,
  customers,
  previousCustomersData,
  topGraphBox,
  title,
  orgPaymentData,
  previousOrgPaymentData,
  previousPaymentWalletData,
  selectedTime,
  cardTypeProp,

  walletData,
  previousWalletData,
}) => {
  console.log("payment123", orgPaymentData, previousOrgPaymentData);
  console.log("selected12345", selectedTime);

  // Helper function to format value with + sign for positive values
  const formatValue = (value) => {
    if (typeof value === "string" && value.startsWith("-")) {
      return value;
    }
    return `+${value}`;
  };

  const changeIcons = () => {
    switch (title) {
      case "Total Billed Amount":
        return "currency_rupee_circle";
      case "Total Energy Consumed (kWh)":
        return "bolt";
      case "Total uptime (hrs)":
        return "pace";
      case "New customers":
        return "groups";
      case "Total Sessions":
        return "bolt";
      case "Wallet Recharge":
        return "account_balance_wallet";
      case "Wallet Recharge (INR)":
        return "account_balance_wallet";
      case "Wallet Balance":
        return "account_balance_wallet";
      case "Cashbacks":
        return "smartphone";
      case "Refund Amount":
        return "undo";
      case "Direct amount (QR)":
        return "qr_code_2";
      case "Amount from app":
        return "smartphone";
      default:
        return "info";
    }
  };

  const changevalue = () => {
    switch (title) {
      case "Total Billed Amount":
        return `₹ ${data?.totalRevenue || orgPaymentData}`;
      case "Total Billed Amount (INR)":
        return `₹ ${orgPaymentData}`;
      case "Total Energy Consumed (kWh)":
        return (data?.totalEnergy / 1000).toFixed(2);
      case "Total uptime (hrs)":
        return data?.totalDuration;
      case "New customers":
        return customers?.customersCount;
      case "Total Sessions":
        return data?.totalSessionCount;
      case "Wallet Recharge":
        return data?.totalWalletAmountAdded;
      case "Wallet Recharge (INR)":
        return data?.totalWalletAmountAdded;
      case "Cashbacks":
        return `₹ ${data?.totalWalletCashbackAmount}`;
      case "Refund Amount":
        return `₹ ${data?.refundAmount || orgPaymentData}`;
      case "Total Amount":
        return `₹ ${data?.paidAmount}`;
      case "Direct Amount (QR)":
        return `₹ ${orgPaymentData}`;
      case "Amount from App":
        return `₹ ${orgPaymentData}`;
      default:
        return data?.value || "N/A";
    }
  };

  // Helper function to convert duration string to seconds
  const convertToSeconds = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
  };

  // Helper function to convert seconds back to duration string
  const convertToDuration = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  // Calculate the difference in durations
  const calculateDurationDifference = (currentDuration, previousDuration) => {
    const currentSeconds = convertToSeconds(currentDuration);
    const previousSeconds = convertToSeconds(previousDuration);

    let differenceInSeconds = previousSeconds - currentSeconds;

    return convertToDuration(Math.abs(differenceInSeconds));
  };

  const compareResultTotalRevenue = (
    data?.totalRevenue - previousDashboardData?.totalRevenue
  ).toFixed(2);

  const compareResultTotalEnergy =
    data?.totalEnergy - previousDashboardData?.totalEnergy;

  const compareResultTotalDuration =
    data?.totalDuration && previousDashboardData?.totalDuration
      ? data?.totalDuration.startsWith("-")
        ? "-" +
          calculateDurationDifference(
            "0:0:0",
            previousDashboardData?.totalDuration || "0:0:0"
          )
        : calculateDurationDifference(
            data?.totalDuration || "0:0:0",
            previousDashboardData?.totalDuration || "0:0:0"
          )
      : "0:0:0";

  const compareResultTotalSession =
    data?.totalSessionCount - previousDashboardData?.totalSessionCount;

  const compareResultWalletRecharge = (
    data?.totalWalletAmountAdded -
    previousPaymentWalletData?.totalWalletAmountAdded
  ).toFixed(2);

  const compareResultCashback = (
    data?.totalWalletCashbackAmount -
    previousPaymentWalletData?.totalWalletCashbackAmount
  ).toFixed(2);

  const compareNewUsers =
    customers?.customersCount - previousCustomersData?.customersCount;

  const compareResultWalletRechargeINR =
    data?.totalWalletAmountAdded -
    previousDashboardData?.totalWalletAmountAdded;

  console.log(
    "234234",
    orgPaymentData,
    previousOrgPaymentData,
    orgPaymentData - previousOrgPaymentData
  );

  const changeCompare = () => {
    switch (title) {
      case "Total Billed Amount":
        return compareResultTotalRevenue;
      case "Total Energy Consumed (kWh)":
        return (compareResultTotalEnergy / 1000).toFixed(2);
      case "Total uptime (hrs)":
        return compareResultTotalDuration;
      case "Total Sessions":
        return compareResultTotalSession;
      case "Wallet Recharge":
        return compareResultWalletRecharge;
      case "Wallet Recharge (INR)":
        return compareResultWalletRechargeINR;
      case "Cashbacks":
        return compareResultCashback;
      case "New customers":
        return compareNewUsers;

      //payment
      case "Total Billed Amount (INR)":
        return (orgPaymentData - previousOrgPaymentData).toFixed(2);
      case "Direct Amount (QR)":
        return (orgPaymentData - previousOrgPaymentData).toFixed(2);
      case "Amount from App":
        return (orgPaymentData - previousOrgPaymentData).toFixed(2);
      case "Refund Amount":
        return (orgPaymentData - previousOrgPaymentData).toFixed(2);
      default:
        return "No Data" || "N/A";
    }
  };

  // Function to get the comparison text based on selectedTime
  const getComparisonText = () => {
    switch (selectedTime) {
      case "today":
        return "Yesterday";
      case "yesterday":
        return "Previous Day";
      case "week":
        return "Last Week";
      case "month":
        return "Last Month";
      case "year":
        return "Last Year";
      case "Custom Range":
        return "Previous Range";
      default:
        return "N/A";
    }
  };

  const getCompareColor = (compare) => {
    const compareStr = String(compare); // Ensure it's a string
    return compareStr.startsWith("-") ? "#790000" : "#027900";
  };

  return (
    <>
      {cardTypeProp === "dashboard" ? (
        <Box
          sx={{
            padding: topGraphBox === "true" ? "12px" : "24px",
            borderRadius: "8px",
            background: "#fff",
                 height:"100%",
            border: topGraphBox === "true" ? "1px solid #ddd" : "",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Box=1 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgba(41, 45, 51, 0.08)",
                  width: "32px",
                  height: "32px",
                  borderRadius: "32px",
                  marginRight: "8px",
                }}
              >
                <span
                  style={{ color: "#292D33" }}
                  className="material-symbols-outlined"
                >
                  {changeIcons()}
                </span>
              </Box>
              <Box>
                <Typography
                  textAlign={"start"}
                  fontSize={"14px"}
                  fontFamily={"mySecondFont"}
                  color={"#666"}
                >
                  {title}
                </Typography>
                <Typography
                  textAlign={"start"}
                  fontSize={"clamp(1.25rem, 1.1981rem + 0.2516vw, 1.5rem)"}
                  color={"#0C0C0C"}
                  fontFamily="'Montserrat', sans-serif"
                  fontWeight={600}
                >
                  {changevalue()}
                </Typography>
              </Box>
            </Box>
            {/* Box=2 */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                alignItems: "flex-end",
              }}
            >
              <ValueChip
                data={data}
                previousDashboardData={previousDashboardData}
                previousPaymentWalletData={previousPaymentWalletData}
                orgPaymentData={orgPaymentData}
                previousOrgPaymentData={previousOrgPaymentData}
                title={title}
                customers={customers}
                previousCustomersData={previousCustomersData}
              />
              <Typography
                fontFamily={"myThirdFont"}
                color={"#666"}
                sx={{
                  textAlign: "right",
                  fontSize: "12px",
                  lineHeight: "14px",
                }}
              >
                <span
                  style={{
                    color: getCompareColor(changeCompare()),
                    fontFamily: "mySecondFont",
                  }}
                >
                  {changeCompare()}
                </span>{" "}
                than {getComparisonText()}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            padding: topGraphBox === "true" ? "12px" : "24px",
            borderRadius: "8px",
            background: "#fff",
            border: topGraphBox === "true" ? "1px solid #ddd" : "",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "rgba(41, 45, 51, 0.08)",
                width: "32px",
                height: "32px",
                borderRadius: "32px",
              }}
            >
              <span
                style={{ color: "#292D33" }}
                className="material-symbols-outlined"
              >
                {changeIcons()}
              </span>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                alignItems: "flex-end",
              }}
            >
              <ValueChip
                data={data}
                previousDashboardData={previousDashboardData}
                previousPaymentWalletData={previousPaymentWalletData}
                orgPaymentData={orgPaymentData}
                previousOrgPaymentData={previousOrgPaymentData}
                title={title}
                customers={customers}
                previousCustomersData={previousCustomersData}
              />
              <Typography fontFamily={"mySecondFont"} color={"#666"}>
                <span
                  style={{
                    color: getCompareColor(changeCompare()),
                    fontFamily: "mySecondFont",
                  }}
                >
                  {changeCompare()}
                </span>{" "}
                than {getComparisonText()}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: "12px" }}>
            <Typography
              textAlign={"start"}
              fontSize={"14px"}
              fontFamily={"mySecondFont"}
              color={"#666"}
            >
              {title}
            </Typography>
            <Typography
              textAlign={"start"}
              fontSize={"clamp(1.25rem, 1.1981rem + 0.2516vw, 1.5rem)"}
              color={"#0C0C0C"}
              fontFamily="'Montserrat', sans-serif"
              fontWeight={600}
            >
              {changevalue()}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ValueCard;
