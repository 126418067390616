

// import React, { useState } from "react";
// import { Box, MenuItem, Select, Typography } from "@mui/material";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import dayjs from "dayjs";

// const DoubleLineGraphPayment = ({
//   graphTitle,
//   gr_type,
//   data,
//   selectedTime,
//   startTime,
//   endTime,
// }) => {
//   const [selectedValue, setSelectedValue] = useState("op1"); // Default to "Direct vs App"

//   const handleChange = (event) => {
//     setSelectedValue(event.target.value);
//   };

//   // Function to generate dates based on selected time range
//   const generateDates = (selectedTime, startTime, endTime) => {
//     let dates = [];
//     if (selectedTime === "week") {
//       for (let i = 0; i < 7; i++) {
//         dates.push({
//           date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
//           label: dayjs().subtract(i, "day").format("ddd"),
//         });
//       }
//       dates.reverse();
//     } else if (selectedTime === "month") {
//       const startOfMonth = dayjs().startOf("month");
//       const endOfMonth = dayjs().endOf("month");
//       let currentDate = startOfMonth;

//       while (
//         currentDate.isBefore(endOfMonth) ||
//         currentDate.isSame(endOfMonth)
//       ) {
//         dates.push({
//           date: currentDate.format("YYYY-MM-DD"),
//           label: currentDate.date().toString(),
//         });
//         currentDate = currentDate.add(1, "day");
//       }
//     } else if (selectedTime === "year") {
//       const startOfYear = dayjs().startOf("year");
//       const endOfYear = dayjs().endOf("year");
//       let currentDate = startOfYear;

//       const monthNames = [
//         "Jan",
//         "Feb",
//         "Mar",
//         "Apr",
//         "May",
//         "Jun",
//         "Jul",
//         "Aug",
//         "Sep",
//         "Oct",
//         "Nov",
//         "Dec",
//       ];

//       while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
//         dates.push({
//           date: currentDate.format("YYYY-MM"),
//           label: monthNames[currentDate.month()],
//         });
//         currentDate = currentDate.add(1, "month");
//       }
//     } else if (selectedTime === "customRange" && startTime && endTime) {
//       let currentDate = dayjs(startTime);
//       const end = dayjs(endTime);
//       while (currentDate.isBefore(end) || currentDate.isSame(end)) {
//         dates.push({
//           date: currentDate.format("YYYY-MM-DD"),
//           label: currentDate.format("YYYY-MM-DD"),
//         });
//         currentDate = currentDate.add(1, "day");
//       }
//     } else if (selectedTime === "today") {
//       dates.push({
//         date: dayjs().format("YYYY-MM-DD"),
//         label: "Today",
//       });
//     } else if (selectedTime === "yesterday") {
//       dates.push({
//         date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
//         label: "Yesterday",
//       });
//     }
//     return dates;
//   };

//   // Function to process data based on the selected graph option
//   const getData = () => {
//     if (!selectedTime || !Array.isArray(data)) return [];

//     const dates = generateDates(selectedTime, startTime, endTime);

//     return dates.map(({ date, label }) => {
//       const dailyData = data.find((item) => item._id === date) || {};

//       if (selectedValue === "op1") {
//         // Direct vs App
//         const appAmount = parseFloat(dailyData.chargingAmount_App) || 0;
//         const directAmount = parseFloat(dailyData.chargingAmount_Upi) || 0;

//         return {
//           date: label,
//           appAmount: appAmount.toFixed(2),
//           directAmount: directAmount.toFixed(2),
//         };
//       } else if (selectedValue === "op2") {
//         // Revenue vs Refund
//         const refundAmount = parseFloat(dailyData.refundAmount) || 0;
//         const revenue = parseFloat(dailyData.chargingAmount) || 0;

//         return {
//           date: label,
//           refundAmount: refundAmount.toFixed(2),
//           revenue: revenue.toFixed(2),
//         };
//       }

//       return {
//         date: label,
//         appAmount: 0,
//         directAmount: 0,
//         refundAmount: 0,
//         revenue: 0,
//       };
//     });
//   };

//   // Compute min and max values for Y-axis
//   const computeYAxisDomain = () => {
//     const chartData = getData();
//     let min = 0;
//     let max = 0;

//     if (selectedValue === "op1") {
//       const values = chartData.flatMap(({ directAmount, appAmount }) => [
//         parseFloat(directAmount),
//         parseFloat(appAmount),
//       ]);
//       min = Math.min(...values);
//       max = Math.max(...values);
//     } else if (selectedValue === "op2") {
//       const values = chartData.flatMap(({ refundAmount, revenue }) => [
//         parseFloat(refundAmount),
//         parseFloat(revenue),
//       ]);
//       min = Math.min(...values);
//       max = Math.max(...values);
//     }

//     return [Math.min(0, min), max * 1]; // Add a little padding to the max value
//   };
     
//   const yAxisDomain = computeYAxisDomain();
//   const chartData = getData();

//   return (
//     <Box>
//       <Box>
//         {gr_type === "paymentTopGraph" && (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "space-between",
//               alignItems: "center",
//               marginBottom: "24px",
//             }}
//           >
//             <Select
//               value={selectedValue}
//               onChange={handleChange}
//               displayEmpty
//               sx={{
//                 width: "220px",
//                 height: "32px",
//                 color: "#1C1C1C",
//                 fontFamily: "myThirdFont",
//                 marginLeft: "40px",
//               }}
//             >
//               <MenuItem value="op1">Direct vs App</MenuItem>
//               <MenuItem value="op2">Revenue vs Refund</MenuItem>
//             </Select>

//             <Typography
//               sx={{
//                 textAlign: "center",
//                 marginBottom: "8px",
//                 fontFamily: "myThirdFont",
//                 fontWeight: "600",
//                 fontSize: "14px",
//                 color: "#666",
//                 lineHeight: "16px",
//               }}
//             >
//               {selectedValue === "op2"
//                 ? "Revenue Amount vs Refund Amount"
//                 : "Direct Amount vs Amount From App"}
//             </Typography>
//           </Box>
//         )}
//       </Box>

//       <Box
//         sx={{
//           width: "100%",
//           height: gr_type === "paymentTopGraph" ? 284 : 312,
//         }}
//       >
//         <ResponsiveContainer width="100%" height="100%">
//           <LineChart
//             data={chartData}
//             margin={{
//               top: 20,
//               right: 20,
//               left: 20,
//               bottom: 20,
//             }}
//           >
//             <CartesianGrid strokeDasharray="5 3" vertical={false} />
//             <YAxis
//               tick={{
//                 fontSize: "12px",
//                 fontFamily: "myThirdFont",
//                 fill: "#666",
//                 fontWeight: "500",
//                 lineHeight: "16px",
//               }}
//               axisLine={false}
//               domain={yAxisDomain} // Set the domain here
//             />
//             <XAxis
//               dataKey="date"
//               tick={{
//                 fontSize: "14px",
//                 fontFamily: "mySecondFont",
//                 fill: "#666",
//                 fontWeight: "500",
//                 lineHeight: "16px",
//               }}
//               axisLine={false}
//             />
//             <Tooltip />

//             <Legend />
//             {/* Direct vs App */}
//             {selectedValue === "op1" && (
//               <>
//                 <Line
//                   type="monotone"
//                   dataKey="directAmount"
//                   stroke="#F8610C"
//                   activeDot={{ r: 3 }}
//                 />
//                 <Line type="monotone" dataKey="appAmount" stroke="#2271E8" />
//               </>
//             )}
//             {selectedValue === "op2" && (
//               <>
//                 <Line
//                   type="monotone"
//                   dataKey="revenue"
//                   stroke="#7F73C6"
//                   activeDot={{ r: 3 }}
//                 />
//                 <Line type="monotone" dataKey="refundAmount" stroke="#FF5454" />
//               </>
//             )}
//             <Legend verticalAlign="top" />
//           </LineChart>
//         </ResponsiveContainer>
//       </Box>
//     </Box>
//   );
// };

// export default DoubleLineGraphPayment;

import React, { useState } from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import dayjs from "dayjs";

const DoubleLineGraphPayment = ({
  graphTitle,
  gr_type,
  data,
  selectedTime,
  startTime,
  endTime,
}) => {
  const [selectedValue, setSelectedValue] = useState("op1");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];
    if (selectedTime === "week") {
      for (let i = 0; i < 7; i++) {
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: dayjs().subtract(i, "day").format("ddd"),
        });
      }
      dates.reverse();
    } else if (selectedTime === "month") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;

      while (currentDate.isBefore(endOfMonth) || currentDate.isSame(endOfMonth)) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "year") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;

      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    } else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.format("YYYY-MM-DD"),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "today") {
      dates.push({
        date: dayjs().format("YYYY-MM-DD"),
        label: "Today",
      });
    } else if (selectedTime === "yesterday") {
      dates.push({
        date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
        label: "Yesterday",
      });
    }
    return dates;
  };

  const getData = () => {
    if (!selectedTime || !Array.isArray(data)) return [];

    const dates = generateDates(selectedTime, startTime, endTime);

    return dates.map(({ date, label }) => {
      const dailyData = data.find((item) => item._id === date) || {};

      if (selectedValue === "op1") {
        const appAmount = parseFloat(dailyData.chargingAmount_App) || 0;
        const directAmount = parseFloat(dailyData.chargingAmount_Upi) || 0;

        return {
          date: label,
          appAmount: appAmount.toFixed(2),
          directAmount: directAmount.toFixed(2),
        };
      } else if (selectedValue === "op2") {
        const refundAmount = parseFloat(dailyData.refundAmount) || 0;
        const revenue = parseFloat(dailyData.chargingAmount) || 0;

        return {
          date: label,
          refundAmount: refundAmount.toFixed(2),
          revenue: revenue.toFixed(2),
        };
      }

      return {
        date: label,
        appAmount: 0,
        directAmount: 0,
        refundAmount: 0,
        revenue: 0,
      };
    });
  };

  const computeYAxisDomain = () => {
    const chartData = getData();
    let min = 0;
    let max = 0;

    if (selectedValue === "op1") {
      const values = chartData.flatMap(({ directAmount, appAmount }) => [
        parseFloat(directAmount),
        parseFloat(appAmount),
      ]);
      min = Math.min(...values);
      max = Math.max(...values);
    } else if (selectedValue === "op2") {
      const values = chartData.flatMap(({ refundAmount, revenue }) => [
        parseFloat(refundAmount),
        parseFloat(revenue),
      ]);
      min = Math.min(...values);
      max = Math.max(...values);
    }

    return [Math.min(0, min), max * 1];
  };

  const yAxisDomain = computeYAxisDomain();
  const chartData = getData();

  return (
    <Box>
      <Box>
        {gr_type === "paymentTopGraph" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <Select
              value={selectedValue}
              onChange={handleChange}
              displayEmpty
              sx={{
                width: "220px",
                height: "32px",
                color: "#1C1C1C",
                fontFamily: "myThirdFont",
                marginLeft: "40px",
              }}
            >
              <MenuItem value="op1">Direct vs App</MenuItem>
              <MenuItem value="op2">Revenue vs Refund</MenuItem>
            </Select>

            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "8px",
                fontFamily: "myThirdFont",
                fontWeight: "600",
                fontSize: "14px",
                color: "#666",
                lineHeight: "16px",
              }}
            >
              {selectedValue === "op2"
                ? "Revenue Amount vs Refund Amount"
                : "Direct Amount vs Amount From App"}
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          height: gr_type === "paymentTopGraph" ? 284 : 312,
        }}
      >
<ResponsiveContainer width="100%" height="100%">
  {selectedTime === "today" || selectedTime === "yesterday" ? (
    <BarChart
      data={chartData}
      margin={{
        top: 20,
        right: 20,
        left: 20,
        bottom: 20,
      }}
    >
      <CartesianGrid strokeDasharray="5 3" vertical={false} />
      <YAxis
        tick={{
          fontSize: "12px",
          fontFamily: "myThirdFont",
          fill: "#666",
          fontWeight: "500",
          lineHeight: "16px",
        }}
        axisLine={false}
        domain={yAxisDomain}
      />
      <XAxis
        dataKey="date"
        tick={{
          fontSize: "14px",
          fontFamily: "mySecondFont",
          fill: "#666",
          fontWeight: "500",
          lineHeight: "16px",
        }}
        axisLine={false}
      />
      <Tooltip />
      <Legend />
      {selectedValue === "op1" && (
        <>
          <Bar dataKey="directAmount" fill="#F8610C" barSize={40} />
          <Bar dataKey="appAmount" fill="#2271E8" barSize={40} />
        </>
      )}
      {selectedValue === "op2" && (
        <>
          <Bar dataKey="refundAmount" fill="#FF5454" barSize={40} />
          <Bar dataKey="revenue" fill="#7F73C6" barSize={40} />
        </>
      )}
    </BarChart>
  ) : (
    <LineChart
      data={chartData}
      margin={{
        top: 20,
        right: 20,
        left: 20,
        bottom: 20,
      }}
    >
      <CartesianGrid strokeDasharray="5 3" vertical={false} />
      <YAxis
        tick={{
          fontSize: "12px",
          fontFamily: "myThirdFont",
          fill: "#666",
          fontWeight: "500",
          lineHeight: "16px",
        }}
        axisLine={false}
        domain={yAxisDomain}
      />
      <XAxis
        dataKey="date"
        tick={{
          fontSize: "14px",
          fontFamily: "mySecondFont",
          fill: "#666",
          fontWeight: "500",
          lineHeight: "16px",
        }}
        axisLine={false}
      />
      <Tooltip />
      <Legend />
      {selectedValue === "op1" && (
        <>
          <Line
            type="monotone"
            dataKey="directAmount"
            stroke="#F8610C"
            activeDot={{ r: 3 }}
          />
          <Line type="monotone" dataKey="appAmount" stroke="#2271E8" />
        </>
      )}
      {selectedValue === "op2" && (
        <>
          <Line
            type="monotone"
            dataKey="revenue"
            stroke="#7F73C6"
            activeDot={{ r: 3 }}
          />
          <Line type="monotone" dataKey="refundAmount" stroke="#FF5454" />
        </>
      )}
      <Legend verticalAlign="top" />
    </LineChart>
  )}
</ResponsiveContainer>

      </Box>
    </Box>
  );
};

export default DoubleLineGraphPayment;

