import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import TableBox from "./TableBox";
import {
  chargingPointColumns,
  chargingPointData,
  ocpiChargerColumns,
  ocpiChargerRowData,
  ocpiNetworkColumns,
  ocpiNetworkRowColumns,
  ocpiTariffColumns,
  ocpiTariffRowData,
} from "../../utils/DummyData";
import OverviewBox from "./OverviewBox";
import TimeFilter from "../Filters/TimeFilter";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../redux/action/dashboard/dashboardSlice";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
    //added for previous time
    const [prevStartTime, setPrevStartTime] = useState(null);
    const [prevEndTime, setPrevEndTime] = useState(null);

  const dispatch = useDispatch();
  const { data, customers, status, error } = useSelector(
    (state) => state.dashboard
  );

  // UseEffect for Time Filter to get the data
  useEffect(() => {
    if (durationType && startTime && endTime) {
      dispatch(
        fetchDashboardData({
          durationType,
          startTime,
          endTime,
        })
      );
    }
  }, [durationType, startTime, endTime, dispatch]);


  const tabsData = [
    {
      label: "overview",
      content: (
        <Box>
          <OverviewBox />
        </Box>
      ),
    },
    {
      label: "networks",
      content: (
        <Box>
          <TableBox
            columns={ocpiNetworkColumns}
            rows={ocpiNetworkRowColumns}
            isRowClickable={true}
            showAddButton={true}
            customAction={"network"}
          />
        </Box>
      ),
    },
    {
      label: "OCPI chargers",
      content: (
        <Box>
          <TableBox
            columns={ocpiChargerColumns}
            rows={ocpiChargerRowData}
            isRowClickable={true}
            showAddButton={false}
            customAction={"ocpi"}
          />
        </Box>
      ),
    },
    {
      label: "Tariffs",
      content: (
        <Box>
          <TableBox
            columns={ocpiTariffColumns}
            rows={ocpiTariffRowData}
            isRowClickable={false}
            customAction={"tariff"}
          />

          {/* //ocpiTariffColumns
//ocpiTariffRowData */}
          {/* columns={chargingPointColumns}
     rows={chargingPointData} */}
        </Box>
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <MainHeading text={"OCPI Management"} />
        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}

              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
