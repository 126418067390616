import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopHeader from "../../components/dashboard/TopHeader";
import AlertBox from "../../components/dashboard/AlertBox";
import ValueCard from "../../components/dashboard/ValueCard";
import TopGraphBox from "../../components/dashboard/TopGraphBox";
import TopPerformStationBox from "../../components/dashboard/TopPerformStationBox";
import AcDcBox from "../../components/dashboard/AcDcBox";
import DeviceStatus from "../../components/dashboard/DeviceStatus";
import TotalSession from "../../components/dashboard/TotalSession";
import DoughnutChart from "../../components/Graphs/DoughnutChart";
import AppStats from "../../components/dashboard/AppStats";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCustomers,
  fetchDashboardData,
  fetchLiveDeviceStatus,
} from "../../redux/action/dashboard/dashboardSlice";
import ValueCardBox from "../../components/dashboard/ValueCardBox";
import {
  fetchChargingPointStats,
  fetchStationStats,
  fetchWalletDetails,
} from "../../redux/action/statistics/statisticsSlice";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../Hooks/useScrollToTop";
import TopPerformingSessionEnergy from "../../components/dashboard/TopPerformingSessionEnergy";
import TopPerformingSessionDuration from "../../components/dashboard/TopPerformingSessionDuration";

const Dashboard = () => {
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const navigate = useNavigate();
  useScrollToTop(navigate);

  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  const [currentDashboardData, setCurrentDashboardData] = useState(null);
  const [currentChargingPointStats, setCurrentChargingPointStats] =
    useState(null);
  const [currentCustomerData, setCurrentCustomerData] = useState(null);
  const [currentWalletDetails, setCurrentWalletDetails] = useState(null);

  console.log("zzzzzzstst", prevStartTime, prevEndTime, startTime, endTime);

  const dispatch = useDispatch();
  const { data, customers, liveDeviceStatus, status, error, loading } =
    useSelector((state) => state.dashboard);

  const { stationStats, chargingPointStats } = useSelector(
    (state) => state.statistics
  );

  const { walletDetails } = useSelector((state) => state.statistics);

  // UseEffect for Time Filter to get the data of Current Time
  useEffect(() => {
    const fetchData = async () => {
      if (durationType && startTime && endTime) {
        try {
          // Fetch and handle data
          const dummyData = await dispatch(
            fetchDashboardData({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();

          setCurrentDashboardData(dummyData);

          const customerData = await dispatch(
            fetchAllCustomers({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();

          setCurrentCustomerData(customerData);

          await dispatch(
            fetchStationStats({
              durationType,
              startTime,
              endTime,
            })
          );

          const chargingPointData = await dispatch(
            fetchChargingPointStats({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();

          setCurrentChargingPointStats(chargingPointData);

          const walletDummyData = await dispatch(
            fetchWalletDetails({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();
          setCurrentWalletDetails(walletDummyData);

          await dispatch(fetchLiveDeviceStatus());
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      }
    };

    fetchData();
  }, [durationType, startTime, endTime, dispatch]);

  // UseEffect for Time Filter to get the data of Previous Time
  useEffect(() => {
    if (durationType && prevStartTime && prevEndTime) {
      dispatch(fetchAllCustomers());

      dispatch(
        fetchStationStats({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );

      dispatch(
        fetchWalletDetails({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );

      dispatch(fetchLiveDeviceStatus());
    }
  }, [durationType, prevStartTime, prevEndTime, dispatch]);

  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentDashboardData !== null
    ) {
      console.log("Previous Time API Call:", {
        durationType,
        startTime: prevStartTime,
        endTime: prevEndTime,
      });

      dispatch(
        fetchDashboardData({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [
    durationType,
    prevStartTime,
    prevEndTime,
    dispatch,
    currentDashboardData,
  ]);

  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentChargingPointStats !== null
    ) {
      dispatch(
        fetchChargingPointStats({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [
    durationType,
    prevStartTime,
    prevEndTime,
    dispatch,
    currentChargingPointStats,
  ]);

  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentCustomerData !== null
    ) {
      console.log("Previous Time API Call:", {
        durationType,
        startTime: prevStartTime,
        endTime: prevEndTime,
      });

      dispatch(
        fetchAllCustomers({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [durationType, prevStartTime, prevEndTime, dispatch, currentCustomerData]);

  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentWalletDetails !== null
    ) {
      console.log("Previous Time API Call:", {
        durationType,
        startTime: prevStartTime,
        endTime: prevEndTime,
      });

      dispatch(
        fetchWalletDetails({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [
    durationType,
    prevStartTime,
    prevEndTime,
    dispatch,
    currentWalletDetails,
  ]);

  // useEffect(() => {}, [currentDashboardData]);

  console.log("Dashboard Data: yyy", data, currentDashboardData);
  console.log(
    "ChargingPoint Stats: yyy",
    chargingPointStats,
    currentChargingPointStats
  );
  console.log("customers123123", customers, currentCustomerData);
  console.log("walletDetails", walletDetails, currentWalletDetails);

  return (
    <>
      {/* Top header */}
      <TopHeader
        setDurationType={setDurationType}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        setPrevStartTime={setPrevStartTime}
        setPrevEndTime={setPrevEndTime}
      />

      {/* dashboard */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* Top Graph and alert box */}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <TopGraphBox
                //Current data
                data={currentDashboardData}
                //Previous data
                previousDashboardData={data}
                selectedTime={durationType}
                startTime={startTime}
                endTime={endTime}
              />
            </Box>
          </Grid>
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <AlertBox />
            </Box>
          </Grid>
        </Grid>

        {/* Device Status and Cards */}
        <Grid
          container
          columnSpacing={"16px"}
          rowSpacing={"16px"}
          sx={{ height: "100%" }}
        >
          <Grid item md={3.5} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <ValueCardBox
                //Current data
                data={currentDashboardData}
                //Previous data
                previousDashboardData={data}
                //Customers Data
                customerData={currentCustomerData}
                previousCustomerData={customers}
                selectedTime={durationType}
                customers={customers}
                walletData={currentWalletDetails}
                previousWalletData={walletDetails}
                cardTypeProp={"dashboard"}
              />
            </Box>
          </Grid>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <TotalSession //Current data
                data={currentDashboardData}
                //Previous data
                previousDashboardData={data}
                statsType="dashboardStats"
                selectedTime={durationType}
              />
            </Box>
          </Grid>
          <Grid item md={4.5} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <DeviceStatus data={liveDeviceStatus} />
            </Box>
          </Grid>
        </Grid>

        {/* Top Performing Stations and App stats*/}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <TopPerformStationBox
                stationStats={stationStats?.stationsReport || []}
              />
            </Box>
          </Grid>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <TopPerformingSessionEnergy
                topPerformData={currentDashboardData}
                loading={loading}
              />
            </Box>
          </Grid>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <TopPerformingSessionDuration
                topPerformData={currentDashboardData}
                loading={loading}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <AcDcBox
                chargingPoints={currentDashboardData?.fastSlowData || {}}
                previousChargingPoints={data?.fastSlowData || {}}
                selectedTime={durationType}
              />
            </Box>
          </Grid>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                {/* <DoughnutChart data={walletRechargeData} centerData="true" donut_type="dashboard" /> */}
                <AppStats
                  // data={walletDetails}
                  customers={customers}
                  // walletData={currentWalletDetails}
                  // previousWalletData={walletDetails}
                  selectedTime={durationType}
                  //Current data
                  data={currentDashboardData}
                  //Previous data
                  previousDashboardData={data}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;

// import { Box, Grid, CircularProgress, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import TopHeader from "../../components/dashboard/TopHeader";
// import AlertBox from "../../components/dashboard/AlertBox";
// import TopGraphBox from "../../components/dashboard/TopGraphBox";
// import TopPerformStationBox from "../../components/dashboard/TopPerformStationBox";
// import AcDcBox from "../../components/dashboard/AcDcBox";
// import DeviceStatus from "../../components/dashboard/DeviceStatus";
// import TotalSession from "../../components/dashboard/TotalSession";
// import AppStats from "../../components/dashboard/AppStats";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchAllCustomers,
//   fetchDashboardData,
//   fetchLiveDeviceStatus,
// } from "../../redux/action/dashboard/dashboardSlice";
// import ValueCardBox from "../../components/dashboard/ValueCardBox";
// import {
//   fetchChargingPointStats,
//   fetchStationStats,
//   fetchWalletDetails,
// } from "../../redux/action/statistics/statisticsSlice";

// const Dashboard = () => {
//   const [durationType, setDurationType] = useState("");
//   const [startTime, setStartTime] = useState(null);
//   const [endTime, setEndTime] = useState(null);
//   const [prevStartTime, setPrevStartTime] = useState(null);
//   const [prevEndTime, setPrevEndTime] = useState(null);
//   const [currentDashboardData, setCurrentDashboardData] = useState(null);

//   const dispatch = useDispatch();
//   const { data, customers, liveDeviceStatus, status, error } = useSelector(
//     (state) => state.dashboard
//   );
//   const { stationStats, chargingPointStats, walletDetails } = useSelector(
//     (state) => state.statistics
//   );

//   const [loading, setLoading] = useState(false);

//   // Fetch data based on current filters
//   useEffect(() => {
//     const fetchData = async () => {
//       if (durationType && startTime && endTime) {
//         setLoading(true);
//         try {
//           const dummyData = await dispatch(
//             fetchDashboardData({ durationType, startTime, endTime })
//           ).unwrap();
//           setCurrentDashboardData(dummyData);

//           await Promise.all([
//             dispatch(fetchAllCustomers()),
//             dispatch(fetchStationStats({ durationType, startTime, endTime })),
//             dispatch(
//               fetchChargingPointStats({ durationType, startTime, endTime })
//             ),
//             dispatch(fetchWalletDetails({ durationType, startTime, endTime })),
//             dispatch(fetchLiveDeviceStatus()),
//           ]);
//         } catch (error) {
//           console.error("Error fetching data: ", error);
//         } finally {
//           setLoading(false);
//         }
//       }
//     };

//     fetchData();
//   }, [durationType, startTime, endTime, dispatch]);

//   // Fetch previous data if applicable
//   useEffect(() => {
//     if (durationType && prevStartTime && prevEndTime) {
//       dispatch(
//         fetchDashboardData({
//           durationType,
//           startTime: prevStartTime,
//           endTime: prevEndTime,
//         })
//       );

//       dispatch(fetchAllCustomers());
//       dispatch(
//         fetchStationStats({
//           durationType,
//           startTime: prevStartTime,
//           endTime: prevEndTime,
//         })
//       );
//       dispatch(
//         fetchChargingPointStats({
//           durationType,
//           startTime: prevStartTime,
//           endTime: prevEndTime,
//         })
//       );
//       dispatch(
//         fetchWalletDetails({
//           durationType,
//           startTime: prevStartTime,
//           endTime: prevEndTime,
//         })
//       );
//       dispatch(fetchLiveDeviceStatus());
//     }
//   }, [durationType, prevStartTime, prevEndTime, dispatch]);

//   return (
//     <>
//       <TopHeader
//         setDurationType={setDurationType}
//         setStartTime={setStartTime}
//         setEndTime={setEndTime}
//         setPrevStartTime={setPrevStartTime}
//         setPrevEndTime={setPrevEndTime}
//       />

//       <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
//         <Grid container spacing={2} sx={{ height: "100%" }}>
//           <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
//             <Box sx={{ flexGrow: 1, display: "flex" }}>
//               <TopGraphBox
//                 data={currentDashboardData}
//                 previousDashboardData={data}
//                 selectedTime={durationType}
//                 startTime={startTime}
//                 endTime={endTime}
//               />
//             </Box>
//           </Grid>
//           <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
//             <Box sx={{ flexGrow: 1, display: "flex" }}>
//               <AlertBox />
//             </Box>
//           </Grid>
//         </Grid>

//         <Grid
//           container
//           columnSpacing={"16px"}
//           rowSpacing={"16px"}
//           sx={{ height: "100%" }}
//         >
//           <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
//             <Box
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "16px",
//               }}
//             >
//               <ValueCardBox data={data} customers={customers} />
//             </Box>
//           </Grid>
//           <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
//             <Box sx={{ flexGrow: 1, display: "flex" }}>
//               <TotalSession data={data} statsType="dashboardStats" />
//             </Box>
//           </Grid>
//           <Grid item md={5} sx={{ display: "flex", flexDirection: "column" }}>
//             <Box sx={{ flexGrow: 1, display: "flex" }}>
//               <DeviceStatus data={liveDeviceStatus} />
//             </Box>
//           </Grid>
//         </Grid>

//         <Grid container spacing={2} sx={{ height: "100%" }}>
//           <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
//             <Box sx={{ flexGrow: 1, display: "flex" }}>
//               <TopPerformStationBox
//                 stationStats={stationStats?.stationsReport || []}
//               />
//             </Box>
//           </Grid>
//           <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
//             <Box sx={{ flexGrow: 1, display: "flex" }}>
//               <AcDcBox
//                 chargingPoints={chargingPointStats?.chargingPointsReport || []}
//               />
//             </Box>
//           </Grid>
//           <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
//             <Box sx={{ flexGrow: 1, display: "flex" }}>
//               <AppStats data={walletDetails} customers={customers} />
//             </Box>
//           </Grid>
//         </Grid>
//       </Box>
//     </>
//   );
// };

// export default Dashboard;
