import { Box, Typography } from "@mui/material";
import React from "react";

const getBackgroundColor = (text) => {
  switch (text) {
    case "Available":
      return "rgba(2, 121, 0, 0.10)";
    case "Charging":
      return "rgba(2, 121, 0, 0.10)";
    case "Processing":
      return "#e6ecf2";
    case "Live":
      return "rgba(2, 121, 0, 0.10)";
    case "Online":
      return "rgba(2, 121, 0, 0.10)";
    case "Operative":
      return "rgba(2, 121, 0, 0.10)";
    case "Offline":
      return "rgba(138, 0, 0, 0.10)";
    case "Inoperative":
      return "rgba(138, 0, 0, 0.10)";
    case "Faulted":
      return "rgba(193, 116, 0, 0.10)";
    case "Great":
      return "rgba(2, 121, 0, 0.10)";
    case "Bad":
      return "rgba(138, 0, 0, 0.10)";
    case "Ok":
      return "rgba(41, 45, 51, 0.10)";

    //Active inactive and maintenence
    case "active":
      return "rgba(2, 121, 0, 0.10)";
    case "inactive":
      return "rgba(138, 0, 0, 0.10)";
    case "maintenance":
      return "#FEEFE7";

    default:
      return "inherit";
  }
};

const getColor = (text) => {
  switch (text) {
    case "Available":
      return "#027900";
    //Active inactive and maintenence
    case "active":
      return "#027900";
    case "inactive":
      return "#8A0000";
    case "maintenance":
      return "#F8610C";

    case "Charging":
      return "#027900";
    case "Processing":
      return "#003f79";
    case "Live":
      return "#027900";

    case "Online":
      return "#027900";
    case "Operative":
      return "#027900";
    case "Inoperative":
      return "#8A0000";
    case "Offline":
      return "#8A0000";
    case "Faulted":
      return "#C17400";
    case "Great":
      return "#027900";
    case "Bad":
      return "#8A0000";
    case "Ok":
      return "#292D33";
    default:
      return "inherit";
  }
};

const StatusChip = ({ text, chipStatus, connectorStatus }) => {
  return (
    <Box
      sx={{
        padding: "4px 12px",
        // width: "100%",
        borderRadius: "50px",
        background: getBackgroundColor(text),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        color={getColor(text)}
        fontFamily={"mySecondFont"}
        fontSize={"14px"}
        textTransform={"uppercase"}
      >
        {/* {text} */}
        {connectorStatus === true
          ? text
          : text === "Available"
          ? "Online"
          : text}
        {/* {text==="Available" ? "Online": text} */}
      </Typography>
      {chipStatus === "live" ? (
        <Box
          sx={{
            width: "8px",
            height: "8px",
            backgroundColor: "#027900",
            borderRadius: "50%",
            marginRight: "4px",
            marginLeft: "4px",
          }}
        ></Box>
      ) : null}
    </Box>
  );
};

export default StatusChip;
