import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import ValueCard from "../dashboard/ValueCard";
import ComposedGraph from "../Graphs/ComposedGraph";
import GraphZoomButton from "../../utils/GraphZoomButton";

const TopGraphBox = ({
  data,
  previousDashboardData,
  selectedTime,
  startTime,
  endTime,
}) => {
  console.log("cccccc", data, previousDashboardData);
  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        alignItems: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={8} sx={12}>
          <Box sx={{}}>
            <ComposedGraph
              data={data}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
          </Box>
          <Box sx={{}}>
            <GraphZoomButton
              GraphComponent={ComposedGraph}
              graphProps={{ data, selectedTime, startTime, endTime }}
            />
          </Box>
        </Grid>
        <Grid item md={4} sx={12}>
          {/* value cards */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <ValueCard
              selectedTime={selectedTime}
              title={"Total Billed Amount"}
              data={data}
              previousDashboardData={previousDashboardData}
              topGraphBox="true"
            />
            <ValueCard
              selectedTime={selectedTime}
              title={"Total Energy Consumed (kWh)"}
              data={data}
              previousDashboardData={previousDashboardData}
              topGraphBox="true"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopGraphBox;
