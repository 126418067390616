import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { logout } from "../../redux/action/authSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 424,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px 24px 32px 24px",
};

const ProfileModal = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  const handleLogout = () => {
    try {
      dispatch(logout());

      // Clear token from localStorage
      // localStorage.removeItem("token");
      // localStorage.clear();

      // Show success toast notification
      toast.success("Logout successful!");

      // Navigate to login page
      navigate("/login", { replace: true });
      window.location.reload();
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("Logout failed. Please try again.");
    }
  };

  console.log("xxxxxxx", profile);

  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box
                sx={{
                  width: "64px",
                  height: "64px",
                  borderRadius: "64px",
                  overflow: "hidden",
                  display: "flex",
                  background: "rgba(41, 45, 51, 0.08)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: "100%", objectFit: "cover" }}
                  src="/images/profileImg.png"
                  alt=""
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
              >
                <Typography
                  color={"#1C1C1C"}
                  fontSize={"18px"}
                  fontFamily={"mySecondFont"}
                >
                  {profile?.name}
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontSize={"16px"}
                  fontFamily={"myThirdFont"}
                >
                  {profile?.role === "Superadmin"
                    ? "Super Admin"
                    : profile?.role}
                </Typography>
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                cursor: "pointer",
              }}
            >
              <Typography
                fontSize={"14px"}
                color={"#e50000"}
                fontFamily={"myThirdFont"}
              >
                Edit
              </Typography>
              <span
                style={{ fontSize: "16px", color: "#e50000" }}
                className="material-symbols-outlined"
              >
                edit
              </span>
            </Box> */}
          </Box>

          {/* Email and Mobile Number Info */}
          <Box
            mt={"24px"}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "left",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "left",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  color: "#666",
                  fontFamily: "myFourthFont",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Email:
              </Typography>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {profile?.email}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "left",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  color: "#666",
                  fontFamily: "myFourthFont",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Mobile Number:
              </Typography>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {profile?.phoneNumber}
              </Typography>
            </Box>
          </Box>

          {/* <Box
            mt={"24px"}
            sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <Box
                sx={{
                  background: "rgba(41, 45, 51, 0.08)",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "24px 8px",
                  width: "120px",
                  height: "144px",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "32px", height: "32px" }}>
                    <span
                      class="material-symbols-outlined"
                      style={{ fontSize: "32px" }}
                    >
                      info
                    </span>
                  </Box>
                  <Typography
                    sx={{
                      color: "#2F2F2F",

                      textAlign: "center",
                      fontFamily: "mySecondFont",
                      fontSize: "16px",
                      lineHeight: "20px",
                    }}
                  >
                    Need Help
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  background: "rgba(41, 45, 51, 0.08)",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "24px 8px",
                  width: "120px",
                  height: "144px",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "32px", height: "32px" }}>
                    <WhatsAppIcon size={"large"} />
                  </Box>
                  <Typography
                    sx={{
                      color: "#2F2F2F",

                      textAlign: "center",
                      fontFamily: "mySecondFont",
                      fontSize: "16px",
                      lineHeight: "20px",
                    }}
                  >
                    Reach us through Whatsapp
                  </Typography>
                </Box>
              </Box>

              <a
                href="mailto:abc@gmail.com"
                style={{ textDecoration: "none", color:"#f2f2f2" }} // Remove underline from the link
              >
                <Box
                  sx={{
                    background: "rgba(41, 45, 51, 0.08)",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "24px 8px",
                    width: "120px",
                    height: "144px",
                    borderRadius: "8px",
                    cursor: "pointer", // Change cursor to pointer
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ width: "32px", height: "32px",  }}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: "32px", color: "#2F2F2F" }} // Ensure icon color
                      >
                        mail
                      </span>
                    </Box>
                    <Typography
                      sx={{
                        color: "#2F2F2F",
                        textAlign: "center",
                        fontFamily: "mySecondFont",
                        fontSize: "16px",
                        lineHeight: "20px",
                      }}
                    >
                      Reach us through Email
                    </Typography>
                  </Box>
                </Box>
              </a>
            </Box>
            <Box
              sx={{
                background: "rgba(41, 45, 51, 0.08)",
                alignItems: "center",
                justifyContent: "center",
                padding: "24px 8px",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Box sx={{ width: "32px", height: "32px" }}>
                  <span
                    class="material-symbols-outlined"
                    style={{ fontSize: "32px" }}
                  >
                    contact_support
                  </span>
                </Box>
                <Typography
                  sx={{
                    color: "#2F2F2F",

                    textAlign: "center",
                    fontFamily: "mySecondFont",
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                >
                  Frequently asked questions
                </Typography>
              </Box>
            </Box>
          </Box> */}

          {/* Logout Button */}
          <Box mt={"40px"}>
            <Button
              onClick={handleLogout}
              sx={{
                padding: "12px 16px",
                borderRadius: "8px",
                alignItems: "center",
                display: "flex",
                border: "1px solid #cbcbcb",
                width: "100%",
                fontFamily: "mySecondFont",
                color: "#790000",
              }}
            >
              <span
                style={{ paddingRight: "4px" }}
                className="material-symbols-outlined"
              >
                logout
              </span>
              Logout
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProfileModal;
