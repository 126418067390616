import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import TableBox from "./TableBox";
import { chargingPointColumns, chargingPointData } from "../../utils/DummyData";
import { useDispatch, useSelector } from "react-redux";
import { fetchChargingPoints } from "../../redux/action/chargingPointSlice/chargingPointSlice";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const dispatch = useDispatch();
  const { chargingPoints, loading, error } = useSelector(
    (state) => state.chargingPoints
  );

  const { profile } = useSelector((state) => state.profile);
  console.log("mmm", profile?.role)

  useEffect(() => {
    dispatch(fetchChargingPoints());
  }, [dispatch]);

  const tabsData = [
    {
      label: "1C Chargers",
      content: (
        <Box>
          <TableBox
            columns={chargingPointColumns}
            rows={chargingPoints}
            isRowClickable={true}
            loading={loading}
          />
        </Box>
      ),
    },
    ...(profile?.role !== "CPO_Admin" ? [
      {
        label: "OCPI Chargers",
        content: (
          <Box>
            <TableBox
              columns={chargingPointColumns}
              rows={chargingPointData}
              isRowClickable={true}
              loading={loading}
            />
          </Box>
        ),
      },
    ] : []),
 
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <MainHeading text={"Charging Points"} />
        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
