import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";

const DocumentTable = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Box>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Heading text={"Documents uploaded"} />
        </Box>
        <Divider sx={{ paddingTop: "8px" }} />
      </Box>

      {/* table */}
      <Grid container>
        <Grid item md={7}>
          <Box
            sx={{
              display: "flex",
              padding: "8px 16px",
              borderRadius: "4px",
              background: "rgba(41, 45, 51, 0.10)",
            }}
          >
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"10%"}
            >
              S.No
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"20%"}
            >
              Date
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"40%"}
            >
              Document Name
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"center"}
              width={"30%"}
            >
              Action
            </Typography>
          </Box>

          <React.Fragment>
            <Box
              sx={{
                padding: "12px 16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                color={"#2F2F2F"}
                fontFamily={"mySecondFont"}
                fontSize={"14px"}
                textAlign={"start"}
                width={"10%"}
              >
                1
              </Typography>
              <Typography
                color={"#2F2F2F"}
                fontFamily={"mySecondFont"}
                fontSize={"14px"}
                textAlign={"start"}
                width={"20%"}
              >
                24/08/2023
              </Typography>
              <Typography
                color={"#2F2F2F"}
                fontFamily={"mySecondFont"}
                fontSize={"14px"}
                textAlign={"start"}
                width={"40%"}
              >
                Document name
              </Typography>
              <Box
                sx={{
                  width: "30%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "4px",
                    border: "1px solid #C0C0C0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "4px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{ color: "#790000", fontWeight: "200" }}
                    className="material-symbols-outlined"
                  >
                    visibility
                  </span>
                </Box>
                <Box
                  sx={{
                    borderRadius: "4px",
                    border: "1px solid #C0C0C0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "4px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{ color: "#292D33", fontWeight: "200" }}
                    className="material-symbols-outlined"
                  >
                    download
                  </span>
                </Box>
              </Box>
            </Box>
            <Divider />
          </React.Fragment>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentTable;
