// src/components/GraphViewModal.jsx
import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height: '60%',
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px 24px",
};

const GraphViewModal = ({ open, onClose, GraphComponent, graphProps }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: 10, right: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <GraphComponent {...graphProps} />
      </Box>
    </Modal>
  );
};

export default GraphViewModal;
