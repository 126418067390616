import * as React from "react";
import CustomTransition from "../custom/CustomTransition";
import { format } from "date-fns";
import {
  Backdrop,
  Grid,
  Box,
  Modal,
  Typography,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";
import Heading from "../../utils/Heading";
import AddNewUserModal from "./AddNewUserModal";
import { TextCopy } from "../../utils/TextCopy/TextCopy";
import { Data } from "@react-google-maps/api";
import { fetchOneUser } from "../../redux/action/adminManagement/adminSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px 24px",
};

const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = React.useState(false);
  const handleCopy = () => {
    TextCopy(value, setCopied); // Call the TextCopy function
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: icon ? "8px" : "",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={value === "Acitve" ? "#027900" : "#2F2F2F"}
          textAlign={"start"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              onClick={handleCopy}
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>

      {label === "Initial Password" ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#2F2F2F",
            fontSize: 12,
            marginTop: "8px",
          }}
        >
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "16px", marginRight: "4px" }}
          >
            info
          </span>
          <Typography
            fontFamily={"myThirdFont"}
            fontSize={"14px"}
            color={"#2F2F2F"}
            textAlign={"start"}
          >
            This password is only for first-time account creation
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

const ViewModal = ({ open, handleClose, heading, data }) => {
  const dispatch = useDispatch();
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [hidePassword, setHidePassword] = React.useState(false);
  const { singleUser, loading, error } = useSelector((state) => state.admin);

  console.log("kkkkk", data);

  const openEditButton = (event) => {
    event.stopPropagation(); // Prevent the event from bubbling up
    handleClose(event); // Pass the event to handleClose
    setIsEditModalOpen(true); // Open the modal
    setHidePassword(true);
  };

  const formattedDate = data?.createdAt
    ? format(new Date(data.createdAt), "dd/MM/yyyy")
    : "N/A";
  const formattedTime = data?.createdAt
    ? format(new Date(data.createdAt), "hh:mm a")
    : "N/A";

  // Fetch users on component mount
  React.useEffect(() => {
    dispatch(fetchOneUser(data?._id));
  }, [dispatch, data]);

  console.log("jjj", singleUser);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        TransitionComponent={CustomTransition}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        {/* Close Button */}
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              className="material-symbols-outlined"
            >
              cancel
            </span>
          </Box>

          {/* Heading - Modal */}
          <Box sx={{}}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img src="/images/add-new-user.png" alt="" />
              <Heading text={"User Details"} />
            </Box>
            <Divider sx={{ background: "#ddd", marginTop: "8px" }} />
          </Box>

          <Box sx={{ mt: 0 }}>
            <Grid container spacing={2}>
              {/* First Row: 6-6 */}
              <Grid item xs={12} sm={6} md={5}>
                <Box
                  sx={{
                    padding: "24px 0px",
                    background: "#fff",
                    borderRadius: "8px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <InfoBox
                      label="User ID"
                      value={data?._id || "N/A"}
                      icon={"content_copy"}
                    />
                    <InfoBox label="User Name" value={data?.name || "N/A"} />
                    <InfoBox
                      label="Organization"
                      value={data?.org?.name || "N/A"}
                    />
                    <InfoBox
                      label="Email"
                      value={data?.email || "N/A"}
                      icon={"content_copy"}
                    />
                    <InfoBox
                      label="Contact No."
                      value={data?.phoneNumber || "N/A"}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={7}>
                <Box
                  sx={{
                    padding: "24px 16px",
                    background: "#fff",
                    borderRadius: "8px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <InfoBox
                      label="Admin profile"
                      value={
                        data?.role === "Superadmin"
                          ? "Super Admin"
                          : data?.role === "1C_Admin"
                          ? "1C Admin"
                          : data?.role === "CPO_Admin"
                          ? "CPO Admin"
                          : data?.role || "N/A"
                      }
                    />
                    <InfoBox
                      label="Status"
                      value={
                        data?.active === false ? "Inactive" : "Active" || "N/A"
                      }
                    />
                    {/* <InfoBox
                      label="Initial Password"
                      value={data?.initialPassword || "N/A"}
                      icon={"content_copy"}
                    /> */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <InfoBox label="Active from" value={formattedDate} />
                      <Typography
                        fontSize={"12px"}
                        color={"#666"}
                        fontFamily={"myThirdFont"}
                      >
                        {formattedTime}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Edit Button */}
          <Box
            sx={{ mt: "40px", display: "flex", gap: "8px", maxWidth: "416px" }}
          >
            <Button
              onClick={openEditButton}
              sx={{
                border: "1px solid #E50000",
                borderRadius: "4px",
                padding: "8px 16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "mySecondFont",
                fontSize: "14px",
                color: "#e50000",
              }}
            >
              <span
                style={{ paddingRight: "8px" }}
                className="material-symbols-outlined"
              >
                edit
              </span>
              Edit User
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* AddNewUserModal */}
      {isEditModalOpen ? (
        <AddNewUserModal
          open={isEditModalOpen}
          handleClose={() => setIsEditModalOpen(false)}
          data={data}
          hidePassword={hidePassword}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ViewModal;
