// import { Box, Grid, MenuItem, Select } from "@mui/material";
// import React, { useState, useEffect } from "react";
// import DoubleLineGraphPayment from "../../Graphs/DoubleLineGraphPayment";
// import ValueCard from "../../dashboard/ValueCard";

// const TopBox = ({ orgData, selectedTime, startTime, endTime }) => {
//   const [selectedOrg, setSelectedOrg] = useState("");
//   const [filteredData, setFilteredData] = useState(null);

//   useEffect(() => {
//     if (selectedOrg && orgData?.statsReport[selectedOrg]) {
//       const selectedOrgStats = orgData?.statsReport[selectedOrg] || {};
//       setFilteredData(selectedOrgStats);
//     } else {
//       setFilteredData(null); // Reset filtered data if selectedOrg is not found in statsReport
//     }
//   }, [selectedOrg, orgData]);

//   useEffect(() => {
//     if (!orgData?.orgsReport?.length) {
//       // If orgsReport is empty, reset the selectedOrg to default
//       setSelectedOrg("");
//     }
//   }, [orgData?.orgsReport]);

//   const handleChange = (event) => {
//     setSelectedOrg(event.target.value);
//   };

//   const renderValueCards = (org = {}) => (
//     console.log("hehehehehe", org),
//     (
//       <Grid container spacing={2} key={org.name || "default"}>
//         <Grid item xs={6}>
//           <ValueCard
//             title="Total Billed Amount"
//             orgPaymentData={org?.chargingAmount || 0}
//             topGraphBox="true"
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <ValueCard
//             title="Refund Amount"
//             orgPaymentData={org.refundAmount || 0}
//             topGraphBox="true"
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <ValueCard
//             title="Direct Amount (QR)"
//             orgPaymentData={org.chargingAmount_Upi || 0}
//             topGraphBox="true"
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <ValueCard
//             title="Amount from App"
//             orgPaymentData={org.chargingAmount_App || 0}
//             topGraphBox="true"
//           />
//         </Grid>
//       </Grid>
//     )
//   );

//   return (
//     <Box
//       sx={{
//         padding: "24px",
//         background: "#fff",
//         borderRadius: "8px",
//         width: "100%",
//       }}
//     >
//       <Grid container columnSpacing={2}>
//         <Grid item md={12} xs={12}>
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "left",
//               alignItems: "center",
//               marginBottom: "24px",
//             }}
//           >
//             <Select
//               value={selectedOrg}
//               onChange={handleChange}
//               displayEmpty
//               sx={{
//                 width: "232px",
//                 height: "40px",
//                 color: "#1C1C1C",
//                 fontFamily: "myThirdFont",
//               }}
//             >
//               <MenuItem value="" disabled>
//                 Organization
//               </MenuItem>
//               {orgData?.orgsReport?.map((org) => (
//                 <MenuItem key={org.name} value={org.name}>
//                   {org.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </Box>
//         </Grid>

//         <Grid item md={6} xs={12}>
//           {selectedOrg &&
//             orgData?.orgsReport
//               .filter((org) => org.name === selectedOrg)
//               .map((org) => renderValueCards(org))}

//           {/* Fallback to render value cards with zero values if no organization is selected */}
//           {!selectedOrg && renderValueCards()}
//           {/* {selectedOrg && orgData?.orgsReport
//             .filter(org => org.name === selectedOrg)
//             .map((org) => (
//               <Grid container spacing={2} key={org.name}>
//                 <Grid item xs={6}>
//                   <ValueCard title="Total Billed Amount" orgPaymentData={org.chargingAmount || 0} topGraphBox="true"/>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <ValueCard title="Refund Amount" orgPaymentData={org.refundAmount || 0} topGraphBox="true"/>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <ValueCard title="Direct Amount (QR)" orgPaymentData={org.chargingAmount_Upi || 0} topGraphBox="true"/>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <ValueCard title="Amount from App" orgPaymentData={org.chargingAmount_App || 0} topGraphBox="true"/>
//                 </Grid>
//               </Grid>
//             ))} */}
//         </Grid>

//         <Grid item md={6} sx={12}>
//           <Box>
//             {filteredData ? (
//               <DoubleLineGraphPayment
//                 gr_type="paymentTopGraph"
//                 graphTitle="Direct Amount vs Amount From App"
//                 data={filteredData}
//                 selectedTime={selectedTime}
//                 startTime={startTime}
//                 endTime={endTime}
//               />
//             ) : (
//               <DoubleLineGraphPayment
//                 gr_type="paymentTopGraph"
//                 graphTitle="Direct Amount vs Amount From App"
//                 data={{}} // Pass an empty object or handle default empty data in the graph component
//                 selectedTime={selectedTime}
//                 startTime={startTime}
//                 endTime={endTime}
//               />
//             )}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default TopBox;

import { Box, Grid, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import DoubleLineGraphPayment from "../../Graphs/DoubleLineGraphPayment";
import ValueCard from "../../dashboard/ValueCard";

const TopBox = ({
  orgData,
  selectedTime,
  startTime,
  endTime,
  previousPaymentOrgData,
}) => {
  const [selectedOrg, setSelectedOrg] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    // Set the default selectedOrg to the first organization if no org is selected and orgData is available
    if (!selectedOrg && orgData?.orgsReport?.length) {
      setSelectedOrg(orgData.orgsReport[0].name);
    }
  }, [orgData, selectedOrg]);

  useEffect(() => {
    // Set the default selectedOrg to the first organization if no org is selected and orgData is available
    if (!selectedOrg && previousPaymentOrgData?.orgsReport?.length) {
      setSelectedOrg(previousPaymentOrgData.orgsReport[0].name);
    }
  }, [previousPaymentOrgData, selectedOrg]);

  useEffect(() => {
    if (selectedOrg && orgData?.statsReport[selectedOrg]) {
      const selectedOrgStats = orgData.statsReport[selectedOrg] || {};
      setFilteredData(selectedOrgStats);
    } else {
      setFilteredData(null); // Reset filtered data if selectedOrg is not found in statsReport
    }
  }, [selectedOrg, orgData]);

  const handleChange = (event) => {
    setSelectedOrg(event.target.value);
  };

  const renderValueCards = (org = {}, prevOrg = {}) => (
    <Grid container spacing={2} key={org.name || "default"}>
      <Grid item xs={6}>
        <ValueCard
          title="Total Billed Amount (INR)"
          orgPaymentData={org?.chargingAmount || 0}
          previousOrgPaymentData={prevOrg?.chargingAmount || 0}
          topGraphBox="true"
          selectedTime={selectedTime}
        />
      </Grid>
      <Grid item xs={6}>
        <ValueCard
          title="Refund Amount"
          orgPaymentData={org.refundAmount || 0}
          previousOrgPaymentData={prevOrg.refundAmount || 0}
          topGraphBox="true"
          selectedTime={selectedTime}
        />
      </Grid>
      <Grid item xs={6}>
        <ValueCard
          title="Direct Amount (QR)"
          orgPaymentData={org.chargingAmount_Upi || 0}
          previousOrgPaymentData={prevOrg.chargingAmount_Upi || 0}
          topGraphBox="true"
          selectedTime={selectedTime}
        />
      </Grid>
      <Grid item xs={6}>
        <ValueCard
          title="Amount from App"
          orgPaymentData={org.chargingAmount_App || 0}
          previousOrgPaymentData={prevOrg.chargingAmount_App || 0}
          topGraphBox="true"
          selectedTime={selectedTime}
        />
      </Grid>
    </Grid>
  );

  console.log(
    "ccccccccccc13",
    orgData?.orgsReport?.chargingAmount,
    previousPaymentOrgData?.chargingAmount
  );

  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <Select
              value={selectedOrg}
              onChange={handleChange}
              displayEmpty
              sx={{
                width: "232px",
                height: "40px",
                color: "#1C1C1C",
                fontFamily: "myThirdFont",
              }}
            >
              <MenuItem value="" disabled>
                Organization
              </MenuItem>
              {orgData?.orgsReport?.map((org) => (
                <MenuItem key={org.name} value={org.name}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>

        {/* <Grid item md={6} xs={12}>
          {selectedOrg &&
            orgData?.orgsReport
              .filter((org) => org.name === selectedOrg)
              .map((org) => renderValueCards(org))}

          {!selectedOrg && renderValueCards()}
        </Grid> */}
        <Grid item md={6} xs={12}>
          {selectedOrg
            ? orgData?.orgsReport
                .filter((org) => org.name === selectedOrg)
                .map((org) => {
                  // Ensure previousPaymentOrgData has the correct structure and data
                  const prevOrgData =
                    previousPaymentOrgData?.statsReport?.[selectedOrg]?.[0] ||
                    {};
                  return renderValueCards(org, prevOrgData);
                })
            : renderValueCards({}, {})}
        </Grid>

        <Grid item md={6} xs={12}>
          <Box>
            <DoubleLineGraphPayment
              gr_type="paymentTopGraph"
              graphTitle="Direct Amount vs Amount From App"
              data={filteredData || {}} // Pass an empty object or handle default empty data in the graph component
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopBox;
