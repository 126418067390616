// import { Box, Typography } from "@mui/material";
// import React from "react";

// const ValueChip = ({ data, previousDashboardData, title }) => {
//   console.log("Data:", data);
//   console.log("Previous Dashboard Data:", previousDashboardData);

//   // Helper function to convert duration string to seconds
//   const convertToSeconds = (duration) => {
//     const [hours, minutes, seconds] = duration.split(":").map(Number);
//     return hours * 3600 + minutes * 60 + seconds;
//   };

//   // Helper function to calculate percentage change
//   const calculatePercentageChange = (current, previous, fallbackValue = 0) => {
//     console.log("broooooo",current, previous, fallbackValue);
//     if (previous == null || previous === 0) {
//       return current == null ? fallbackValue : ((current - fallbackValue) / (fallbackValue || 1)) * 100;
//     }
//     return ((current - previous) / previous) * 100;
//   };

//   // Calculate results using the helper function
//   const resultTotalRevenue = calculatePercentageChange(
//     data?.totalRevenue,
//     previousDashboardData?.totalRevenue
//   );

//   const resultTotalEnergy = calculatePercentageChange(
//     data?.totalEnergy,
//     previousDashboardData?.totalEnergy
//   );

//   const resultDirectSession = calculatePercentageChange(
//     data?.totalSessionCount_Upi,
//     previousDashboardData?.totalSessionCount_Upi,
//     10 // Fallback value for Direct Session
//   );

//   const resultTotalAppSession = calculatePercentageChange(
//     data?.totalSessionCount_App,
//     previousDashboardData?.totalSessionCount_App
//   );

//   const resultTotalDuration = calculatePercentageChange(
//     convertToSeconds(data?.totalDuration || "0:0:0"),
//     convertToSeconds(previousDashboardData?.totalDuration || "0:0:0")
//   );

//   const resultTotalSession = calculatePercentageChange( data?.totalSessionCount, previousDashboardData?.totalSessionCount);

//   console.log(
//     "Calculated Values:",
//     {
//       resultTotalRevenue,
//       resultTotalEnergy,
//       resultTotalDuration,
//       resultDirectSession,
//       resultTotalAppSession
//     },
//     "Data:",
//     {
//       totalRevenue: data?.totalRevenue,
//       previousTotalRevenue: previousDashboardData?.totalRevenue,
//       totalEnergy: data?.totalEnergy,
//       previousTotalEnergy: previousDashboardData?.totalEnergy,
//       totalDuration: data?.totalDuration,
//       previousTotalDuration: previousDashboardData?.totalDuration,
//       totalSessionCount_Upi: data?.totalSessionCount_Upi,
//       previousTotalSessionCount_Upi: previousDashboardData?.totalSessionCount_Upi,
//       totalSessionCount_App: data?.totalSessionCount_App,
//       previousTotalSessionCount_App: previousDashboardData?.totalSessionCount_App,
//     }
//   );

//   // Ensure data exists and has a profit field
//   const getValueByTitle = (title) => {
//     switch (title) {
//       case "Total Billed Amount":
//         return resultTotalRevenue.toFixed(2);
//       case "Total Energy Consumed (kWh)":
//         return resultTotalEnergy.toFixed(2);
//       case "Direct Session":
//         return resultDirectSession.toFixed(2);
//       case "App Session":
//         return resultTotalAppSession.toFixed(2);
//       case "Total uptime (hrs)":
//         return resultTotalDuration.toFixed(2);
//         case "Total Sessions":
//         return resultTotalSession.toFixed(2);
//       case "Wallet Recharge":
//         return data?.totalWalletRecharge || "N/A";
//       case "Cashbacks":
//         return data?.totalWalletCashbackAmount || "N/A";
//       default:
//         return data?.value || "N/A";
//     }
//   };

//   // Get the profit value based on the title
//   const profitValue = getValueByTitle(title);

//   // Helper function to determine the color based on the profit value
//   const getProfitColor = (profit) => {
//     if (typeof profit === "string") {
//       return profit.startsWith("-") ? "#790000" : "#027900";
//     }
//     return "#027900"; // Default color if profit is not a string
//   };

//   // Determine the color based on the profit value
//   const profitColor = getProfitColor(profitValue);

//   // Handle zero percentage differently
//   const displayValue = profitValue === "0" ? "No Change" : profitValue;

//   return (
//     <Box
//       sx={{
//         padding: "2px 8px",
//         background: `rgba(${
//           profitColor === "#790000" ? "121, 0, 0, 0.10" : "2, 121, 0, 0.10"
//         })`,
//         borderRadius: "50px",
//         display: "flex",
//         alignItems: "center",
//       }}
//     >
//       <Typography
//         fontFamily={"mySecondFont"}
//         color={profitColor}
//         sx={{ display: "flex", alignItems: "center" }}
//       >
//         {displayValue}%
//         <span
//           style={{
//             fontSize: "16px",
//             marginLeft: "4px",
//             fontWeight: "600",
//           }}
//           className="material-symbols-outlined"
//         >
//           {typeof profitValue === "string" && profitValue.startsWith("-")
//             ? "trending_down"
//             : "trending_up"}
//         </span>
//       </Typography>
//     </Box>
//   );
// };

// export default ValueChip;

import { Box, Typography } from "@mui/material";
import React from "react";

const ValueChip = ({
  data,
  previousDashboardData,
  title,
  previousPaymentWalletData,
  orgPaymentData,
  previousOrgPaymentData,
  customers,
  previousCustomersData,
}) => {
  console.log("Data:", data);
  console.log("Previous Dashboard Data:", previousDashboardData);

  console.log("QWERTYU", customers, previousCustomersData);

  // Helper function to convert duration string to seconds
  const convertToSeconds = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Helper function to calculate percentage change
  const calculatePercentageChange = (current, previous, fallbackValue = 0) => {
    // Convert values to numbers to avoid issues with type coercion
    const currentValue = Number(current);
    const previousValue = Number(previous);

    // Check if previous data is missing or zero
    if (isNaN(previousValue) || previousValue === 0) {
      if (isNaN(currentValue) || currentValue === 0) {
        return "0.00%"; // Both are zero
      }
      return "N/A"; // Previous data is missing or zero but current data is available
    }

    // Calculate percentage change
    const percentageChange =
      ((currentValue - previousValue) / previousValue) * 100;

    // Return formatted result
    return percentageChange.toFixed(2) + "%";
  };

  // Calculate results using the helper function
  const resultTotalRevenue = calculatePercentageChange(
    data?.totalRevenue,
    previousDashboardData?.totalRevenue
  );

  const resultTotalEnergy = calculatePercentageChange(
    data?.totalEnergy,
    previousDashboardData?.totalEnergy
  );

  const resultDirectSession = calculatePercentageChange(
    data?.totalSessionCount_Upi,
    previousDashboardData?.totalSessionCount_Upi
    // Fallback value for Direct Session
  );

  const resultTotalAppSession = calculatePercentageChange(
    data?.totalSessionCount_App,
    previousDashboardData?.totalSessionCount_App
  );

  const resultTotalDuration = calculatePercentageChange(
    convertToSeconds(data?.totalDuration || "0:0:0"),
    convertToSeconds(previousDashboardData?.totalDuration || "0:0:0")
  );

  const resultTotalSession = calculatePercentageChange(
    data?.totalSessionCount,
    previousDashboardData?.totalSessionCount
  );
  const resultTotalWalletRecharge = calculatePercentageChange(
    data?.totalWalletRecharge,
    previousPaymentWalletData?.totalWalletRecharge
  );

  const resultTotalWalletRechargeDashboard = calculatePercentageChange(
    data?.totalWalletRecharge,
    previousDashboardData?.totalWalletRecharge
  );

  const resultTotalWalletRechargePayment = calculatePercentageChange(
    data?.totalWalletAmountAdded,
    previousPaymentWalletData?.totalWalletAmountAdded
  );

  const resultTotalCashback = calculatePercentageChange(
    data?.totalWalletCashbackAmount,
    previousPaymentWalletData?.totalWalletCashbackAmount
  );

  const resultTotalRevenuePayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );
  const resultTotalRfundPayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );

  const resultTotalAmountFromAppPayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );

  const resultNewUsers = calculatePercentageChange(
    customers?.customersCount,
    previousCustomersData?.customersCount
  );

  console.log("resultNewUsers", resultNewUsers);

  console.log(
    "Calculated Values:",
    {
      resultTotalRevenue,
      resultTotalEnergy,
      resultTotalDuration,
      resultDirectSession,
      resultTotalAppSession,
    },
    "Data:",
    {
      totalRevenue: data?.totalRevenue,
      previousTotalRevenue: previousDashboardData?.totalRevenue,
      totalEnergy: data?.totalEnergy,
      previousTotalEnergy: previousDashboardData?.totalEnergy,
      totalDuration: data?.totalDuration,
      previousTotalDuration: previousDashboardData?.totalDuration,
      totalSessionCount_Upi: data?.totalSessionCount_Upi,
      previousTotalSessionCount_Upi:
        previousDashboardData?.totalSessionCount_Upi,
      totalSessionCount_App: data?.totalSessionCount_App,
      previousTotalSessionCount_App:
        previousDashboardData?.totalSessionCount_App,
    }
  );

  // Ensure data exists and has a profit field
  const getValueByTitle = (title) => {
    switch (title) {
      case "Total Billed Amount":
        return resultTotalRevenue;
      case "Total Energy Consumed (kWh)":
        return resultTotalEnergy;
      case "Direct Session":
        return resultDirectSession;
      case "App Session":
        return resultTotalAppSession;
      case "Total uptime (hrs)":
        return resultTotalDuration;
      case "Total Sessions":
        return resultTotalSession;
      case "Wallet Recharge":
        return resultTotalWalletRecharge;
      case "Wallet Recharge (INR)":
        return resultTotalWalletRechargeDashboard;
      case "Cashbacks":
        return resultTotalCashback;
      case "New customers":
        return resultNewUsers;
      //payment
      case "Refund Amount":
        return resultTotalRfundPayment;
      case "Total Billed Amount (INR)":
        return resultTotalRevenuePayment;
      case "Direct Amount (QR)":
        return resultTotalRfundPayment;
      case "Amount from App":
        return resultTotalAmountFromAppPayment;

      default:
        return data?.value || "N/A";
    }
  };

  // Get the profit value based on the title
  const profitValue = getValueByTitle(title);

  // Helper function to determine the color based on the profit value
  const getProfitColor = (profit) => {
    if (typeof profit === "string") {
      return profit.startsWith("-") ? "#790000" : "#027900";
    }
    return "#027900"; // Default color if profit is not a string
  };

  // Determine the color based on the profit value
  const profitColor = getProfitColor(profitValue);

  // Handle zero percentage differently
  const displayValue = profitValue === "0.00%" ? "Nil" : profitValue;

  return (
    <Box
      sx={{
        padding: "2px 8px",
        background: `rgba(${
          profitColor === "#790000" ? "121, 0, 0, 0.10" : "2, 121, 0, 0.10"
        })`,
        borderRadius: "50px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        fontFamily={"mySecondFont"}
        color={profitColor}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {displayValue}
        <span
          style={{
            fontSize: "16px",
            marginLeft: "4px",
            fontWeight: "600",
          }}
          className="material-symbols-outlined"
        >
          {typeof profitValue === "string" && profitValue.startsWith("-")
            ? "trending_down"
            : "trending_up"}
        </span>
      </Typography>
    </Box>
  );
};

export default ValueChip;
