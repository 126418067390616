import React from "react";
import { Box, Typography } from "@mui/material";
import SemiDoughnutChart from "../Graphs/SemiDoughnutChart";
import ValueChip from "../Chips/ValueChip";

const TotalSession = ({ data, previousDashboardData, selectedTime }) => {
 // Calculate comparison values
 const compareDirectSession = data?.totalSessionCount_Upi - previousDashboardData?.totalSessionCount_Upi;
 const compareAppSession = data?.totalSessionCount_App - previousDashboardData?.totalSessionCount_App;

 // Calculate total sessions for SemiDoughnut chart
 const compareTotalSessionForSemiGraph = data?.totalSessionCount - previousDashboardData?.totalSessionCount;

 const totalSessions = data?.totalSessionCount;

 const segments = [
   {
     name: "Direct",
     color: "#FF9F69",
     value: (data?.totalSessionCount_Upi / totalSessions) * 100,
   },
   {
     name: "App",
     color: "#69A5FF",
     value: (data?.totalSessionCount_App / totalSessions) * 100,
   },
 ];

 const segmentData = segments.map((segment) => ({
   name: segment.name,
   value: segment.value || 0,
   color: segment.color,
 }));

 const formatValue = (value) => {
   if (value === null || value === undefined) {
     return "N/A";
   }
   const valueStr = String(value); // Convert value to string
   if (valueStr.startsWith("-")) {
     return valueStr;
   }
   return `+${valueStr}`;
 };

 const overallCompareColor = compareTotalSessionForSemiGraph < 0
   ? "#790000"
   : "#027900";

 const getCompareColor = (compare) => {
   const compareStr = String(compare); // Ensure it's a string
   return compareStr.startsWith("-") ? "#790000" : "#027900";
 };

   // Function to get the comparison text based on selectedTime
   const getComparisonText = () => {
    switch (selectedTime) {
      case "today":
        return "Yesterday";
      case "yesterday":
        return "Previous Day";
      case "week":
        return "Last Week";
      case "month":
        return "Last Month";
      case "year":
        return "Last Year";
      case "Custom Range":
        return "Previous Range";
      default:
        return "N/A";
    }
  };


  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
        height: "100%", // Ensure parent container adapts to content
        position: "relative",
      }}
    >
      <SemiDoughnutChart data={segmentData} />

 {/* INSIDE DONUT GRAPH CONTENT */}
 <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: "50%",
          top: "35%",
          transform: "translate(-50%, -50%)",
          gap: "8px",
        }}
      >
        <ValueChip title={"Total Sessions"} data={data} previousDashboardData={previousDashboardData} />
        <Box>
          <Typography
            sx={{
              color: "#666",
              textAlign: "center",
              fontFamily: "myThirdFont",
              fontSize: { xs: "12px", sm: "14px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "16px",
            }}
          >
            Total Sessions
          </Typography>
          <Typography
            sx={{
              color: "#2F2F2F",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: { xs: "20px", sm: "24px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "32px",
            }}
          >
            {totalSessions}
          </Typography>
        </Box>

        <Typography
          fontFamily={"myThirdFont"}
          color={"#666"}
          fontWeight={"400"}
          fontSize={"12px"}
        >
          <span
            style={{ color: overallCompareColor, fontFamily: "mySecondFont" }}
          >
            {formatValue(compareTotalSessionForSemiGraph)}
          </span>{" "}
        than {getComparisonText()}
        </Typography>
      </Box>

      {/* BELOW DATA */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                width: "16px",
                height: "16px",
                backgroundColor: "#FF974B", // Direct session color
                borderRadius: "50%",
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  color: "#666",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                }}
              >
                Direct Session
              </Typography>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "Montserrat",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "left",
                }}
              >
                {data?.totalSessionCount_Upi}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "#666",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "myThirdFont",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              <ValueChip
                title={"Direct Session"}
                data={data} // Passing the whole data object
                previousDashboardData={previousDashboardData} // Pass previous data
              />
            </Typography>
            <Typography
              fontFamily={"myThirdFont"}
              color={"#666"}
              fontWeight={"400"}
              fontSize={"12px"}
            >
              <span
                style={{ color: getCompareColor(compareDirectSession), fontFamily: "mySecondFont" }}
              >
                {formatValue(compareDirectSession)}
              </span>{" "}
              than {getComparisonText()}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                width: "16px",
                height: "16px",
                backgroundColor: "#69A5FF", // App session color
                borderRadius: "50%",
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  color: "#666",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                }}
              >
                App Session
              </Typography>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "Montserrat",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "left",
                }}
              >
                {data?.totalSessionCount_App}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "#666",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "myThirdFont",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              <ValueChip
                title={"App Session"}
                data={data} // Passing the whole data object
                previousDashboardData={previousDashboardData} // Pass previous data
              />
            </Typography>
            <Typography
              fontFamily={"myThirdFont"}
              color={"#666"}
              fontWeight={"400"}
              fontSize={"12px"}
            >
              <span
                style={{ color: getCompareColor(compareAppSession), fontFamily: "mySecondFont" }}
              >
                {formatValue(compareAppSession)}
              </span>{" "}
              than {getComparisonText()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TotalSession;


// import React from "react";
// import { Box, Grid, Typography } from "@mui/material";
// // import { totalSessionData } from "../../utils/DummyData";
// import SemiDoughnutChart from "../Graphs/SemiDoughnutChart";
// import ValueChip from "../Chips/ValueChip";

// export const overallTotalSessionData = {
//   id: 1,
//   profit: "5.5",
//   compare: "56",
// };
// const overallCompareColor = overallTotalSessionData.compare.startsWith("-")
//   ? "#790000"
//   : "#027900";

// const TotalSession = ({ data, statsType, previousDashboardData }) => {
//   console.log(data);
//   const compareDirectSession =
//     data?.totalSessionCount_Upi - previousDashboardData?.totalSessionCount_Upi;
//   const compareAppSession =
//     data?.totalSessionCount_App - previousDashboardData?.totalSessionCount_App;

//   const totalSessionData = [
//     {
//       id: 1,
//       sessionType: "Direct",
//       sessionName: "Direct Session",
//       sessionCount: data?.totalSessionCount_Upi,
//       profit: "5.5",
//       compare: compareDirectSession,
//     },
//     {
//       id: 2,
//       sessionType: "App",
//       sessionName: "App Session",
//       sessionCount: data?.totalSessionCount_App,
//       profit: "-20",
//       compare: compareAppSession,
//     },
//   ];

//   const formatValue = (value) => {
//     const valueStr = String(value); // Convert value to string
//     if (valueStr.startsWith("-")) {
//       return valueStr;
//     }
//     return `+${valueStr}`;
//   };

//   const totalSessions = data?.totalSessionCount;

//   const segments = [
//     {
//       name: "Direct",
//       color: "#FF9F69",
//       value: (data?.totalSessionCount_Upi / totalSessions) * 100,
//     },
//     {
//       name: "App",
//       color: "#69A5FF",
//       value: (data?.totalSessionCount_App / totalSessions) * 100,
//     },
//   ];

//   const segmentData = segments.map((segment) => ({
//     name: segment.name,
//     value: segment.value || 0,
//     color: segment.color,
//   }));

//   // Convert item.compare to a string to safely call startsWith
//   const getCompareColor = (compare) => {
//     const compareStr = String(compare); // Ensure it's a string
//     return compareStr.startsWith("-") ? "#790000" : "#027900";
//   };

//   return (
//     <Box
//       sx={{
//         flexGrow: 1,
//         display: "flex",
//         flexDirection: "column",
//         padding: "24px",
//         background: "#fff",
//         borderRadius: "8px",
//         width: "100%",
//         height: "100%", // Ensure parent container adapts to content
//         position: "relative",
//       }}
//     >
//       <SemiDoughnutChart data={segmentData} />

//       {/* INSIDE DONUT GRAPH CONTENT */}
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           alignItems: "center",
//           position: "absolute",
//           left: "50%",
//           top: "35%",
//           transform: "translate(-50%, -50%)",
//           gap: "8px",
//         }}
//       >
//         <ValueChip data={overallTotalSessionData} />
//         <Box>
//           <Typography
//             sx={{
//               color: "#666",
//               textAlign: "center",
//               fontFamily: "myThirdFont",
//               fontSize: { xs: "12px", sm: "14px" },
//               fontStyle: "normal",
//               fontWeight: 400,
//               lineHeight: "16px",
//             }}
//           >
//             Total Sessions
//           </Typography>
//           <Typography
//             sx={{
//               color: "#2F2F2F",
//               textAlign: "center",
//               fontFamily: "Montserrat",
//               fontSize: { xs: "20px", sm: "24px" },
//               fontStyle: "normal",
//               fontWeight: 600,
//               lineHeight: "32px",
//             }}
//           >
//             {totalSessions}
//           </Typography>
//         </Box>

//         <Typography
//           fontFamily={"myThirdFont"}
//           color={"#666"}
//           fontWeight={"400"}
//           fontSize={"12px"}
//         >
//           <span
//             style={{ color: overallCompareColor, fontFamily: "mySecondFont" }}
//           >
//             {formatValue(overallTotalSessionData?.compare)}
//           </span>{" "}
//           than yesterday
//         </Typography>
//       </Box>

//       {/* BELOW DATA */}
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           gap: "20px",
//         }}
//       >
//         {totalSessionData.map((item) => {
//           const compareColor = getCompareColor(item.compare); // Use updated method
//           return (
//             <Box
//               key={item.id}
//               sx={{
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 gap: "20px",
//               }}
//             >
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   gap: "12px",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     width: "16px",
//                     height: "16px",
//                     backgroundColor:
//                       item.sessionType === "App"
//                         ? "#69A5FF"
//                         : item.sessionType === "Direct"
//                         ? "#FF974B"
//                         : "#D95A5A",
//                     borderRadius: "50%",
//                   }}
//                 ></Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     gap: "4px",
//                   }}
//                 >
//                   <Typography
//                     sx={{
//                       color: "#666",
//                       fontFeatureSettings: "'clig' off, 'liga' off",
//                       fontFamily: "myThirdFont",
//                       fontSize: "14px",
//                       fontStyle: "normal",
//                       fontWeight: 400,
//                       lineHeight: "16px",
//                     }}
//                   >
//                     {item.sessionName}
//                   </Typography>
//                   <Typography
//                     sx={{
//                       color: "#2F2F2F",
//                       fontFeatureSettings: "'clig' off, 'liga' off",
//                       fontFamily: "Montserrat",
//                       fontSize: "18px",
//                       fontStyle: "normal",
//                       fontWeight: 600,
//                       lineHeight: "24px",
//                       textAlign: "left",
//                     }}
//                   >
//                     {item.sessionCount}
//                   </Typography>
//                 </Box>
//               </Box>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "flex-end",
//                   gap: "4px",
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     color: "#666",
//                     fontFeatureSettings: "'clig' off, 'liga' off",
//                     fontFamily: "myThirdFont",
//                     fontSize: "12px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     lineHeight: "16px",
//                   }}
//                 >
//                   <ValueChip
//                     title={"Direct"}
//                     data={item}
//                     previousData={previousDashboardData}
//                   />
//                 </Typography>
//                 <Typography
//                   fontFamily={"myThirdFont"}
//                   color={"#666"}
//                   fontWeight={"400"}
//                   fontSize={"12px"}
//                 >
//                   <span
//                     style={{ color: compareColor, fontFamily: "mySecondFont" }}
//                   >
//                     {formatValue(item?.compare)}
//                   </span>{" "}
//                   than yesterday
//                 </Typography>
//               </Box>
//             </Box>
//           );
//         })}
//       </Box>
//     </Box>
//   );
// };

// export default TotalSession;
