import {
  Backdrop,
  Box,
  Button,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Heading from "../../utils/Heading";
import CustomTransition from "../custom/CustomTransition";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px",
};

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: icon ? "8px" : "",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={value === "Acitve" ? "#027900" : "#2F2F2F"}
        textAlign={"start"}
      >
        {value}
      </Typography>

      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

const OrgDocModal = ({ open, handleClose, data }) => {
  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      handleClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      TransitionComponent={CustomTransition}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "4px" }}>
            <Heading text={data?.name} />
          </Box>
          <span
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            className="material-symbols-outlined"
          >
            cancel
          </span>
        </Box>
        <Divider sx={{ mt: "8px", mb: "48px" }} />
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            justifyContent: "center",
          }}
        >
          {/* <Box
            component="img"
            src={data?.document?.url}
            alt=""
            sx={{ width: "50%", height: "50%" }} // Adjust width and height as needed
          /> */}

          <Box sx={{ width: "500px", margin: "0 auto" }}>
            {(() => {
              const fileUrl = data?.document?.url;
              const fileType = fileUrl?.split(".").pop().toLowerCase();

              switch (fileType) {
                case "jpg":
                case "jpeg":
                case "png":
                case "doc":
                case "docx":
                  return (
                    <iframe
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                        fileUrl
                      )}`}
                      width="100%"
                      height="500px"
                      style={{ border: "2px solid black" }}
                      title="Word file"
                    />
                  );
                // case "xls":
                // case "xlsx":
                // case "csv":
                //   return (
                //     <iframe
                //       src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                //         fileUrl
                //       )}`}
                //       width="100%"
                //       height="500px"
                //       style={{ border: "2px solid black" }}
                //       title="Excel file"
                //     />
                //   );
                default:
                  return (
                    <p>
                      Preview not available - <Link to={fileUrl}>Download</Link>{" "}
                    </p>
                  );
              }
            })()}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrgDocModal;
